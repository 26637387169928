import { ComponentProps, ReactNode, useMemo, useRef, useState } from 'react';

import {
  Flex,
  forwardRef,
  useDisclosure,
  useFormControlContext,
  VisuallyHiddenInput,
} from '@chakra-ui/react';
import { mergeRefs } from '@chakra-ui/react-utils';
import {
  useFieldArray,
  UseFieldArrayReturn,
  useFormContext,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ns } from '@proptly/locale';

import { checkFilesEqual, getFileSrc } from '../../utils';
import { FileFieldButton } from './file-field-button';
import { FilesFieldFilesList } from './files-field-files-list';
import { FilesPreviewModal } from './files-preview-modal';
import { FormWithFilesData } from './utils';

export interface FilesInputFieldProps {
  name: string;
  label: ReactNode;
  maxSize?: number;
}

export const FilesInputField = ({
  maxSize,
  label,
  name,
}: FilesInputFieldProps) => {
  const [t] = useTranslation(ns.Errors);
  const formControl = useFormControlContext();
  const isRequired = !!formControl?.isRequired;
  const { formState, getFieldState } = useFormContext<FormWithFilesData>();
  const { error } = getFieldState(name, formState);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const galleryDisclosure = useDisclosure();
  const [fileIndex, setFileIndex] = useState(0);

  const fieldArray = useFieldArray<FormWithFilesData>({
    name,
    rules: {
      ...(isRequired && { required: t('required') }),
    },
  });

  const handleInputFocus = () => {
    setImmediate(() => buttonRef.current?.focus());
  };

  const filesToPreview = useMemo(
    () =>
      fieldArray.fields.map((field) => ({
        id: field.id,
        fileName: field.file.name,
        src: getFileSrc(field.file),
        file: field.file,
      })),
    [fieldArray.fields],
  );

  const onFileClick = (rhfId: string) => {
    const index = filesToPreview.findIndex((f) => f.id === rhfId);
    setFileIndex(index);
    galleryDisclosure.onOpen();
  };

  return (
    <>
      <Flex flexDir="column" gap="3" mb={filesToPreview.length ? '4' : '0'}>
        <FilesFieldFilesList
          name={name}
          maxSize={maxSize}
          onFocus={handleInputFocus}
          fieldArray={fieldArray}
          onFileClick={onFileClick}
        />
      </Flex>
      <FilesFieldInput
        ref={inputRef}
        onFocus={handleInputFocus}
        name={name}
        fieldArray={fieldArray}
      />
      <FileFieldButton
        ref={buttonRef}
        isInvalid={!!error}
        onClick={() => inputRef.current?.click()}
      >
        {label}
      </FileFieldButton>
      <FilesPreviewModal
        files={filesToPreview}
        isOpen={galleryDisclosure.isOpen}
        onClose={galleryDisclosure.onClose}
        fileIndex={fileIndex}
        onFileIndexChange={setFileIndex}
      />
    </>
  );
};

export interface FilesFieldInputProps extends ComponentProps<'input'> {
  name: string;
  fieldArray: UseFieldArrayReturn<FormWithFilesData>;
  onFilesAdded?: () => void;
}

export const FilesFieldInput = forwardRef(
  ({ name, fieldArray, onFilesAdded, ...props }: FilesFieldInputProps, ref) => {
    const { register } = useFormContext<FormWithFilesData>();

    const { ref: registerRef } = register(name);

    return (
      <VisuallyHiddenInput
        {...props}
        tabIndex={-1}
        multiple
        type="file"
        ref={mergeRefs(registerRef, ref)}
        onChange={(e) => {
          const fileList = e.target.files;
          if (!fileList) {
            return;
          }

          const files = Array.from(fileList).filter(
            (newFile) =>
              !fieldArray.fields.some((existingFile) =>
                checkFilesEqual(existingFile.file, newFile),
              ),
          );
          fieldArray.append(files.map((file) => ({ file })));
          onFilesAdded?.();
          e.target.value = '';
        }}
      />
    );
  },
);
