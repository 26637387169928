import { ChangeEventHandler, PropsWithChildren, useEffect } from 'react';

import { VisuallyHiddenInput } from '@chakra-ui/react';
import { useLiveQuery } from 'dexie-react-hooks';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';

import { ns } from '@proptly/locale';
import { FileTypes } from '@proptly/shared';

import { useSubmitErrorHandler } from '../../hooks';
import {
  chatDb,
  checkFilesEqual,
  createStoreContext,
  getFileId,
} from '../../utils';

export interface ChatStore {
  files: File[];
  addFiles: (files: File[]) => void;
  removeFile: (fileId: string) => void;
  clearFiles: () => void;
  onOpen: () => void;
  inputElement: HTMLInputElement | null;
  setInputElement: (inputElement: HTMLInputElement | null) => void;
}

const [StoreProvider, useStoreContext] = createStoreContext<ChatStore>(
  (set, get) => ({
    files: [],
    removeFile: (fileId) => {
      set((store) => {
        return {
          files: store.files.filter((file) => getFileId(file) !== fileId),
        };
      });
    },
    addFiles: (newFiles) => {
      set((store) => {
        const prevFiles = store.files;

        const uniqueNewFiles = newFiles.filter(
          (newFile) =>
            !prevFiles.some((existingFile) =>
              checkFilesEqual(existingFile, newFile),
            ),
        );

        return {
          files: [...prevFiles, ...uniqueNewFiles],
        };
      });
    },
    clearFiles: () => set({ files: [] }),
    inputElement: null,
    setInputElement: (inputElement) => set({ inputElement }),
    onOpen: () => get().inputElement?.click(),
  }),
);

export const useChatFilesStore = useStoreContext;

interface ChatFilesProps {
  chatId: string;
  userId: string;
  uploadFileFn?: (file: File) => void;
  uploadFilesFn?: (files: File[]) => void;
  addFilesToStore?: boolean;
  /** list of extensions without dot prefix ex. ['mp3','mp4'] */
  allowedExtensions?: string[];
}

const DEBOUNCE_TIME = 300;

const ChatFiles = ({
  uploadFileFn,
  uploadFilesFn,
  addFilesToStore = true,
  allowedExtensions = FileTypes.allExtensions,
  chatId,
  userId,
}: ChatFilesProps) => {
  const [errt] = useTranslation(ns.Errors);

  const addLocalFiles = useStoreContext((store) => store.addFiles);
  const setInputElement = useStoreContext((store) => store.setInputElement);
  const submitErrorHandler = useSubmitErrorHandler();
  const store = useStoreContext.useContext();
  const chatDbId = `${chatId}-${userId}`;

  useLiveQuery(() =>
    chatDb.files
      .where('id')
      .equals(chatDbId)
      .first()
      .then((message) => {
        if (message) {
          addLocalFiles(message.files);
        }
      }),
  );
  useEffect(() => {
    const debouncedHandler = debounce((store) => {
      try {
        if (store.files.length) {
          chatDb.files.put({
            id: chatDbId,
            chatId,
            files: store.files,
          });
        } else {
          chatDb.files.delete(chatDbId);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        throw new Error(errt('failedToSaveFiles'));
      }
    }, DEBOUNCE_TIME);

    const unsubscribe = store.subscribe(debouncedHandler);

    return () => {
      unsubscribe();
    };
  }, [chatDbId, chatId, errt, store]);

  const handleFileAdded: ChangeEventHandler<HTMLInputElement> = (e) => {
    const fileList = e.target.files;
    if (!fileList) {
      return;
    }

    const newFiles = Array.from(fileList);

    if (addFilesToStore) {
      addLocalFiles(newFiles);
    }
    e.target.value = '';

    if (uploadFileFn) {
      newFiles.forEach((file) => {
        try {
          uploadFileFn(file);
        } catch (error) {
          submitErrorHandler(error);
        }
      });
    }

    if (uploadFilesFn) {
      try {
        uploadFilesFn(newFiles);
      } catch (error) {
        submitErrorHandler(error);
      }
    }
  };

  const extensionsWithDot = allowedExtensions.map((ext) => '.' + ext);
  const accept = extensionsWithDot.join(',');

  return (
    <VisuallyHiddenInput
      ref={setInputElement}
      multiple
      type="file"
      accept={accept}
      onChange={handleFileAdded}
    />
  );
};

export const ChatFilesStoreProvider = ({
  children,
  ...props
}: PropsWithChildren<ChatFilesProps>) => (
  <StoreProvider key={props.chatId}>
    {children}
    <ChatFiles {...props} />
  </StoreProvider>
);
