import isEqual from 'lodash/isEqual';

import { isEmptyOrWhitespace, nullIfEmpty } from '@proptly/shared';

import { TimelineEntryDataKey } from './timeline-entry-data-key.enum';

enum CustomDataTypes {
  Diff = 'DIFF',
}

export interface Difference<T> {
  __type: CustomDataTypes.Diff;
  prev: T;
  next: T;
}

export type TimelineEntryDataFieldType = Difference<string | null> | string;

export interface AttachmentFileType {
  fileName: string;
  fileId: string;
}

interface TimelineEntryDataKeyValuesMap {
  [TimelineEntryDataKey.AttachmentFiles]: AttachmentFileType[];
  [TimelineEntryDataKey.NewAttachmentFiles]: AttachmentFileType[];
  [TimelineEntryDataKey.DeletedAttachmentFiles]: AttachmentFileType[];
}

type TimelineEntryDataValuesType<
  Key extends keyof TimelineEntryDataKeyValuesMap,
> = TimelineEntryDataKeyValuesMap[Key];

export type AllowedTimelineEntryDataKeyForValues =
  keyof TimelineEntryDataKeyValuesMap;

export type AllowedTimelineEntryDataKeyForFields = Exclude<
  TimelineEntryDataKey,
  AllowedTimelineEntryDataKeyForValues
>;

export type TimelineEntryData = Partial<
  TimelineEntryDataKeyValuesMap &
    Record<AllowedTimelineEntryDataKeyForFields, TimelineEntryDataFieldType>
> & {
  _deferredFields?: DeferrableTimelineEntryDataKey[];
};

export type DeferrableTimelineEntryDataKey =
  TimelineEntryDataKey.ContractorName;

export class TimelineEntryDataBuilder {
  #data: TimelineEntryData;
  static CustomDataTypes = CustomDataTypes;
  #deferredFields: Set<DeferrableTimelineEntryDataKey>;

  constructor(data?: TimelineEntryData) {
    const { _deferredFields = [], ...clonedData } = data
      ? structuredClone(data)
      : {};
    this.#data = clonedData;
    this.#deferredFields = new Set(_deferredFields);
  }

  addDeferredField(key: DeferrableTimelineEntryDataKey) {
    this.#deferredFields.add(key);

    return this;
  }

  /**
   * Adds simple value to data.
   *
   * If value is empty it will not be added.
   */

  addField(
    key: AllowedTimelineEntryDataKeyForFields,
    value: string | null | undefined,
  ) {
    if (!isEmptyOrWhitespace(value)) {
      this.#data[key] = value;
    }

    return this;
  }

  /**
   * Adds an array of values to data.
   *
   * If array provided with is empty it will not be added.
   */
  addFieldForValues<T extends AllowedTimelineEntryDataKeyForValues>(
    key: T,
    values: TimelineEntryDataValuesType<T>,
  ) {
    if (key && values.length) {
      this.#data[key] = values;
    }

    return this;
  }

  /**
   * Adds difference object to data.
   *
   * If both values are the same they will not be added
   */
  addDiff<T extends string | undefined | null>(
    key: AllowedTimelineEntryDataKeyForFields,
    prevValue: T,
    newValue: T,
  ) {
    const prev = nullIfEmpty(prevValue);
    const next = nullIfEmpty(newValue);
    if (!isEqual(prev, next)) {
      this.#data[key] = {
        __type: CustomDataTypes.Diff,
        prev,
        next,
      };
    }

    return this;
  }

  /**
   * Adds difference object or simple value to data.
   *
   * If both values are the same it will be added as simple field.
   * If both values are empty it will not be added.
   */
  addDiffOrField<T extends string | undefined | null>(
    key: AllowedTimelineEntryDataKeyForFields,
    prevValue: T,
    newValue: T,
  ) {
    const prev = nullIfEmpty(prevValue);
    const next = nullIfEmpty(newValue);
    if (isEqual(prev, next)) {
      if (next) {
        this.#data[key] = next;
      }
    } else {
      this.#data[key] = {
        __type: CustomDataTypes.Diff,
        prev,
        next,
      };
    }

    return this;
  }

  build() {
    const clonedData = structuredClone(this.#data);
    const deferredFields = Array.from(this.#deferredFields);

    const _deferredFields =
      deferredFields.length > 0 ? deferredFields : undefined;

    return {
      ...clonedData,
      ...(_deferredFields && { _deferredFields }),
    };
  }
}

export const isDiff = <T>(value: unknown): value is Difference<T> =>
  !!value &&
  typeof value === 'object' &&
  '__type' in value &&
  value.__type === CustomDataTypes.Diff;

export const getCurrentValue = <T>(value: Difference<T> | T): T =>
  isDiff(value) ? value.next : value;

export const getPrevValue = <T>(value: Difference<T> | T): T =>
  isDiff(value) ? value.prev : value;
