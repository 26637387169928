import { Flex, Text } from '@chakra-ui/layout';
import { ChakraProps } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { ProptlyLogo } from '@proptly/ui';

import { PublicContractorLogo } from '../public-contractor-logo';
import { PublicTenantLogo } from '../public-tenant-logo';

const logoSizeStyle: ChakraProps = {
  maxW: {
    mobile: '140px',
    desktop: '200px',
  },
  maxH: {
    mobile: '40px',
    desktop: '60px',
  },
};

interface OrderLogosProps {
  tenantId: string;
  contractorId?: string;
}

export const OrderLogos = ({ tenantId, contractorId }: OrderLogosProps) => {
  const [t] = useTranslation();

  return (
    <Flex gap="4" align="center" wrap="wrap">
      {contractorId && (
        <PublicContractorLogo
          contractorId={contractorId}
          notFoundFallback={<ProptlyLogo />}
          {...logoSizeStyle}
        />
      )}

      <Flex gap="4" align="center">
        <PublicTenantLogo
          tenantId={tenantId}
          contentBeforeLogo={
            contractorId && (
              <Text
                textStyle={{
                  mobile: 'bodyMedium',
                  desktop: 'labelMedium',
                }}
              >
                {t('for')}
              </Text>
            )
          }
          {...logoSizeStyle}
        />
      </Flex>
    </Flex>
  );
};
