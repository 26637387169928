import { useCallback, useRef } from 'react';

import { useSearchParams } from 'react-router-dom';

export const getSearchParam = (name: string) =>
  new URLSearchParams(window.location.search).get(name) || '';

export const useSearchParamState = (param: string) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const value = searchParams.get(param);

  const searchParamsRef = useRef(searchParams);
  searchParamsRef.current = searchParams;

  const setValue = useCallback(
    (value: string) => {
      searchParamsRef.current.set(param, value);
      setSearchParams(searchParamsRef.current, { replace: true });
    },
    [param, setSearchParams],
  );

  return [value, setValue] as const;
};
