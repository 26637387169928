import { ReactElement, useMemo } from 'react';

import { Box, Button, Divider, Flex, Grid, Text } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FEEDBACK_COMMENT_MAX_LENGTH } from '@proptly/contractor-contract-enums';
import {
  commonForm,
  CommonFormProps,
  Details,
  FilesListWithPreview,
  FormFieldControl,
  TextareaField,
} from '@proptly/ui';

import { getParamsFiles, useProjectFeedbackParams } from '../hooks';

export interface DecisionFormData {
  answer: string;
}

const defaultValue: DecisionFormData = {
  answer: '',
};

export interface DecisionFormProps extends CommonFormProps<DecisionFormData> {
  question: ReactElement;
}

export const DecisionFormFields = ({
  onSubmit,
  question,
}: DecisionFormProps) => {
  const form = useFormContext<DecisionFormData>();
  const [t] = useTranslation();
  const params = useProjectFeedbackParams();
  const files = useMemo(() => getParamsFiles(params), [params]);

  const { handleSubmit, formState } = form;
  const { isSubmitting } = formState;

  const handleBeforeSubmit = handleSubmit(async (values) => {
    await onSubmit?.(values);
  });

  return (
    <>
      <Grid gap="6" px="6" mb="6">
        <Details.Group>
          <Details.Label>{t('decision')}</Details.Label>
          <Details.Value mb="0">{question}</Details.Value>
        </Details.Group>

        <FormFieldControl isRequired name="answer" label={t('yourAnswer')}>
          <TextareaField
            maxLength={FEEDBACK_COMMENT_MAX_LENGTH}
            minRows={4}
            name="answer"
          />
        </FormFieldControl>

        <Box mt="-6">
          <Text textStyle="titleLarge" mb="6">
            {t('attachments')}
          </Text>
          <FilesListWithPreview files={files} />
        </Box>
      </Grid>

      <Divider mt="auto" />

      <Flex px="6" py="3">
        <Button
          mt="auto"
          w="full"
          isLoading={isSubmitting}
          onClick={handleBeforeSubmit}
          colorScheme="success"
        >
          {t('sendAnswer')}
        </Button>
      </Flex>
    </>
  );
};

export const DecisionForm = commonForm(DecisionFormFields, { defaultValue });
