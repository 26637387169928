import { Button, ButtonProps, forwardRef } from '@chakra-ui/react';
import { Paperclip } from '@phosphor-icons/react';
import { useFormContext } from 'react-hook-form';

export interface FileFieldButtonProps extends ButtonProps {
  isInvalid?: boolean;
}

export const FileFieldButton = forwardRef(
  ({ isInvalid, ...props }: FileFieldButtonProps, ref) => {
    const { formState } = useFormContext() || {};
    const { isSubmitting } = formState || {};

    return (
      <Button
        ref={ref}
        {...(isInvalid && {
          borderColor: 'red.default',
          color: 'red.default',
        })}
        mb="4"
        w="full"
        leftIcon={<Paperclip />}
        variant="outline"
        colorScheme="primary"
        isDisabled={isSubmitting}
        {...props}
      />
    );
  },
);
