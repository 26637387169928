import { Trans, useTranslation } from 'react-i18next';

import { ns } from '@proptly/locale';
import { MessageReason } from '@proptly/shared';

import { useProjectFeedbackMessageAuthor } from '../../hooks';

export interface FeedbackEventTextProps {
  reason: MessageReason;
  createdBy: string;
  contractorName: string;
  feedbackName: string;
}

export const ProjectFeedbackEventText = ({
  reason,
  createdBy,
  contractorName,
  feedbackName,
}: FeedbackEventTextProps) => {
  const [et] = useTranslation(ns.Enums);
  const { author } = useProjectFeedbackMessageAuthor(createdBy, contractorName);

  return (
    <Trans
      t={et}
      i18nKey={`feedbackMessageReasonText.${reason}`}
      defaults={reason}
      tOptions={{ author, feedbackName }}
    />
  );
};
