export abstract class AppError extends Error {
  abstract status: string;
  abstract statusText: string;
}

export interface ApiError {
  error?: string;
  message: string;
  statusCode: number;
}

export class ForbiddenError extends AppError {
  status = '403';
  statusText = 'Forbidden';
}

export class NotFoundError extends AppError {
  status = '404';
  statusText = 'Not Found';
}

export class NoInternetConnectionError extends AppError {
  status = 'No Internet';
  statusText = '';
}

export class FileTooLargeError extends Error {
  override name = 'FileTooLargeError';

  constructor(public readonly maxSize: number, public readonly file: File) {
    super();
  }
}

export class UnsupportedFileTypeError extends Error {
  override name = 'UnsupportedFileTypeError';

  constructor(
    public readonly allowedTypes: string[],
    public readonly file: File,
  ) {
    super();
  }
}

enum AssignFormContractorErrorType {
  Declined = 'DECLINED',
  Closed = 'CLOSED',
  NewInstanceOpened = 'NEW_INSTANCE_OPENED',
}
export class AssignFormContractorError extends Error {
  static type = AssignFormContractorErrorType;
  override name = 'AssignFormContractorError';
  override message: AssignFormContractorErrorType;

  constructor(message: AssignFormContractorErrorType) {
    super();
    this.message = message;
  }
}
