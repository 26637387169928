import { useSuspenseInfiniteQuery } from '@tanstack/react-query';

import { PagedValuesDto } from '@proptly/shared';
import {
  TimelineEntryDto,
  TimelineFilterParamsPayload,
} from '@proptly/timeline-shared';
import {
  ApiResponse,
  timelineClient,
  getNextPageParam,
  getEmptyPage,
} from '@proptly/ui';

export const getTimeline = (
  token: string,
  params: TimelineFilterParamsPayload,
) => {
  return timelineClient
    .get<ApiResponse<PagedValuesDto<TimelineEntryDto>>>(
      `/timeline/public/${token}`,
      { params },
    )
    .then((resp) => resp.data.data);
};

export function useGetTimelineInfiniteQuery(
  token: string,
  params: Omit<TimelineFilterParamsPayload, 'page'>,
) {
  return useSuspenseInfiniteQuery({
    initialPageParam: 0,
    getNextPageParam,
    queryKey: ['timeline', token, params],
    queryFn: (context) => {
      const page = context.pageParam;

      return getTimeline(token, {
        ...params,
        page,
      }).catch(() => getEmptyPage<TimelineEntryDto>());
    },
  });
}
