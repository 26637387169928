import { DependencyList, RefObject, useEffect, useState } from 'react';

import debounce from 'lodash/debounce';

const ALLOWABLE_DIFF = 7;

export const useElementIsClamped = <T extends HTMLElement>(
  ref: RefObject<T>,
  deps: DependencyList = [],
) => {
  const [isClamped, setIsClamped] = useState(false);

  useEffect(() => {
    const checkIsClamped = () => {
      if (!ref.current) {
        return;
      }

      const diff = Math.abs(
        ref.current.clientHeight - ref.current.scrollHeight,
      );
      // The `diff` could be more than zero even if the text is not clamped because additional space is added to the bottom of the text
      setIsClamped(diff > ALLOWABLE_DIFF);
    };

    const debouncedCheck = debounce(checkIsClamped, 50);

    checkIsClamped();

    window.addEventListener('resize', debouncedCheck);

    return () => window.removeEventListener('resize', debouncedCheck);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, ...deps]);

  return isClamped;
};
