import { useEffect, useRef } from 'react';

import { useLocation } from 'react-router-dom';

export const useScrollToLink = (to: string) => {
  const ref = useRef<HTMLDivElement>(null);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === to) {
      ref.current?.scrollIntoView({
        behavior: 'smooth',
        inline: 'center',
        block: 'nearest',
      });
    }
  }, [location, to]);

  return ref;
};
