import {
  getCurrentValue,
  getPrevValue,
  TimelineEventType,
} from '@proptly/timeline-shared';
import {
  checkIsTimelineEntryAfterRerouting,
  SystemMessagesParserBuilder,
} from '@proptly/ui';

interface SystemMessagesParserOptions {
  tenantName?: string;
  isRoutedToBoundContractor?: boolean;
}

export const systemMessagesParserCLIENT_RO =
  new SystemMessagesParserBuilder<SystemMessagesParserOptions>()
    .add(TimelineEventType.OrderCreated, (t, entry) => {
      return t(`chatSystemMessages.${entry.eventType}`);
    })
    .add(TimelineEventType.OrderAssigneeChanged, (t, entry) => {
      const prevRoutingOperatorName = getPrevValue(entry.data?.assigneeName);
      const currentRoutingOperatorName = getCurrentValue(
        entry.data?.assigneeName,
      );

      return t(`chatSystemMessages.${entry.eventType}`, {
        prevRoutingOperatorName,
        currentRoutingOperatorName,
      });
    })
    .add(
      TimelineEventType.ProjectCancelledByContractor,
      (t, entry, { tenantName }) => {
        const contractorName = getCurrentValue(entry.data?.contractorName);

        return t(`chatSystemMessages.${entry.eventType}`, {
          contractorName,
          tenantName,
        });
      },
    )
    .build();

export const systemMessagesParserCLIENT_CE =
  new SystemMessagesParserBuilder<SystemMessagesParserOptions>()
    .add(TimelineEventType.OrderCreated, (t, entry) => {
      return t(`chatSystemMessages.${entry.eventType}`);
    })
    .add(TimelineEventType.ProjectCreated, (t, entry) => {
      return t(`chatSystemMessages.CLIENT_CE.${entry.eventType}`);
    })
    .add(TimelineEventType.ProjectAccepted, (t, entry) => {
      const contractorEmployeeName = entry.actorName;
      const contractorName = getCurrentValue(entry.data?.contractorName);

      return t(`chatSystemMessages.CLIENT_CE.${entry.eventType}`, {
        contractorEmployeeName,
        contractorName,
      });
    })
    .add(TimelineEventType.ProjectAssigneeChanged, (t, entry) => {
      const prevContractorEmployeeName =
        getPrevValue(entry.data?.assigneeName) ?? t('unassigned');
      const currentContractorEmployeeName =
        getCurrentValue(entry.data?.assigneeName) ?? t('unassigned');

      return t(`chatSystemMessages.CLIENT_CE.${entry.eventType}`, {
        prevContractorEmployeeName,
        currentContractorEmployeeName,
      });
    })
    .add(
      TimelineEventType.RoutingRequestAccepted,
      (t, entry, { currentIndex, timelineEntries }) => {
        const isRerouted = checkIsTimelineEntryAfterRerouting(
          entry,
          currentIndex,
          timelineEntries,
        );

        if (isRerouted) {
          const contractorEmployeeName = entry.actorName;
          const contractorName = getCurrentValue(entry.data?.contractorName);

          return t(`chatSystemMessages.CLIENT_CE.REROUTED_${entry.eventType}`, {
            contractorEmployeeName,
            contractorName,
          });
        }

        return t(`chatSystemMessages.CLIENT_CE.${entry.eventType}`);
      },
    )
    .add(
      TimelineEventType.ProjectCancelledByContractor,
      (t, entry, { tenantName }) => {
        const contractorName = getCurrentValue(entry.data?.contractorName);

        if (!tenantName) {
          return t(
            `chatSystemMessages.CLIENT_CE.NOT_ROUTED_${entry.eventType}`,
            {
              contractorName,
            },
          );
        }

        return t(`chatSystemMessages.${entry.eventType}`, {
          contractorName,
          tenantName,
        });
      },
    )
    .add(
      TimelineEventType.OrderRerouted,
      (t, entry, { tenantName, isRoutedToBoundContractor }) => {
        if (!isRoutedToBoundContractor) {
          return t(
            `chatSystemMessages.CLIENT_CE.${entry.eventType}_TO_FORM_CONTRACTOR`,
          );
        }

        const prevContractorName = getPrevValue(entry.data?.contractorName);

        return t(
          `chatSystemMessages.CLIENT_CE.${entry.eventType}_TO_APP_CONTRACTOR`,
          {
            prevContractorName,
            tenantName,
          },
        );
      },
    )
    .build();
