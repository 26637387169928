import { ReactNode } from 'react';

import { createContext } from '@chakra-ui/react-utils';

import { Confirm, ConfirmOptions } from './confirm';

export type ConfirmContextValue = (
  render: () => ReactNode,
  options?: ConfirmOptions,
) => Confirm;

export const [ConfirmContextProvider, useConfirmContext] =
  createContext<ConfirmContextValue>({
    name: 'ConfirmModalContext',
  });
