import { RECAPTCHA_SITE_KEY } from '../config/constants';

export class RecaptchaChallengeError extends Error {
  override name = 'RecaptchaChallengeError';
}

export class RecaptchaChallengeClosedError extends RecaptchaChallengeError {
  override name = 'RecaptchaChallengeClosedError';
}

export class RecaptchaChallengeExpiredError extends RecaptchaChallengeError {
  override name = 'RecaptchaChallengeExpiredError';
}

const getRecaptchaTokenV2 = () =>
  new Promise<string>((res, rej) => {
    const grecaptcha = window.grecaptcha;
    if (!grecaptcha) {
      rej(
        new Error(
          'Google reCAPTCHA is not initialised. Seems you forgot to import recatpcha script.',
        ),
      );
    }

    let challengeIframe: Element | undefined;
    const container = document.createElement('div');
    document.body.append(container);

    const withCleanup =
      <T extends unknown[]>(fn: (...args: T) => void) =>
      (...args: T) => {
        clearInterval(interval);
        container.remove();
        challengeIframe?.parentElement?.parentElement?.remove();

        return fn(...args);
      };

    const interval = window.setInterval(() => {
      challengeIframe ??= getChallengeIframe();
      if (!challengeIframe) {
        return;
      }
      if (!isChallengeVisible(challengeIframe)) {
        withCleanup(rej)(new RecaptchaChallengeClosedError());
      }
    }, 500);

    const challengeId = grecaptcha.render(container, {
      size: 'invisible',
      sitekey: RECAPTCHA_SITE_KEY,
      callback: withCleanup(res),
      'error-callback': withCleanup(() => rej(new RecaptchaChallengeError())),
      'expired-callback': withCleanup(() =>
        rej(new RecaptchaChallengeExpiredError()),
      ),
    });

    grecaptcha.execute(challengeId);
  });

const getChallengeIframe = () => {
  return Array.from(
    document.querySelectorAll(
      'iframe[src*="google.com/recaptcha/api2/bframe"]',
    ),
  ).find(isChallengeVisible);
};

const isChallengeVisible = (challenge: Element) => {
  return window.getComputedStyle(challenge).visibility !== 'hidden';
};

export const withRecaptcha = async <T>(
  fn: (recaptcha: string) => Promise<T>,
) => {
  return getRecaptchaTokenV2().then(fn);
};
