/* eslint-disable @typescript-eslint/ban-ts-comment */
import { ChangeEvent, FocusEvent, useRef, useEffect } from 'react';

import { InputProps, useFormControlContext } from '@chakra-ui/react';
import { mergeRefs } from '@chakra-ui/react-utils';
import { isDefined, isInt, isNumber, maxLength } from 'class-validator';
import { RegisterOptions, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ns } from '@proptly/locale';
import { isEmptyOrWhitespace } from '@proptly/shared';

export interface CommonInputProps {
  name: string;
  validate?: RegisterOptions['validate'];
  valueAsNumber?: boolean;
  valueAsDate?: boolean;
  maxLength?: number;
  setValueAs?: RegisterOptions['setValueAs'];
  type?: InputProps['type'];
  moreThan?: number;
  moreThanOrEqual?: number;
  lessThan?: number;
  lessThanOrEqual?: number;
  isInteger?: boolean;
}

export const useCommonInputProps = (
  {
    validate,
    valueAsDate,
    valueAsNumber,
    setValueAs,
    maxLength: maxLengthProp,
    moreThan,
    moreThanOrEqual,
    lessThan,
    lessThanOrEqual,
    isInteger,
    ...props
  }: CommonInputProps,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref?: any,
) => {
  const { register } = useFormContext();

  const formControl = useFormControlContext();
  const [t] = useTranslation(ns.Errors);
  const inputRef = useRef<HTMLInputElement>(null);

  const isRequired = !!formControl?.isRequired;

  useEffect(() => {
    const input = inputRef.current;

    if (!input || props.type !== 'number') {
      return;
    }

    const blurInput = () => {
      input?.blur();
    };

    input.addEventListener('wheel', blurInput);

    return () => {
      input.removeEventListener('wheel', blurInput);
    };
  }, [props.type]);

  const { ref: registerRef, ...registerProps } = register(props.name, {
    validate: {
      ...(props.type === 'time' && {
        validTime: () =>
          inputRef.current?.validity.badInput ? t('invalidTime') : undefined,
      }),
      ...(props.type === 'date' && {
        validDate: () =>
          inputRef.current?.validity.badInput ? t('invalidDate') : undefined,
      }),
      ...(props.type === 'number' && {
        validNumber: () =>
          inputRef.current?.validity.badInput ? t('invalidNumber') : undefined,
      }),
      ...(isRequired && {
        required: (value) => {
          if (typeof value === 'string') {
            return isEmptyOrWhitespace(value) ? t('required') : undefined;
          }

          if (typeof value === 'number') {
            return isNaN(value) ? t('required') : undefined;
          }

          if (value instanceof FileList) {
            return value.length > 0 ? undefined : t('required');
          }

          return isDefined(value) ? undefined : t('required');
        },
      }),
      ...(!!maxLengthProp && {
        maxLength: (value) =>
          typeof value !== 'string'
            ? undefined
            : maxLength(value, maxLengthProp)
            ? undefined
            : t('tooLong', { maxLength: maxLengthProp }),
      }),
      ...(isNumber(moreThan) && {
        moreThan: (value) =>
          !isNumber(value) || value > moreThan
            ? undefined
            : t('moreThan', { moreThan }),
      }),
      ...(isNumber(lessThan) && {
        lessThan: (value) =>
          !isNumber(value) || value < lessThan
            ? undefined
            : t('lessThan', { lessThan }),
      }),
      ...(isNumber(moreThanOrEqual) && {
        moreThan: (value) =>
          !isNumber(value) || value >= moreThanOrEqual
            ? undefined
            : t('moreThanOrEqual', { moreThanOrEqual }),
      }),
      ...(isNumber(lessThanOrEqual) && {
        lessThan: (value) =>
          !isNumber(value) || value <= lessThanOrEqual
            ? undefined
            : t('lessThanOrEqual', { lessThanOrEqual }),
      }),
      ...(isInteger && {
        isInteger: (value) =>
          !isNumber(value) || isInt(value) ? undefined : t('isInteger'),
      }),
      ...(typeof validate === 'function' ? { valid: validate } : validate),
    },

    valueAsNumber,
    valueAsDate,
    setValueAs,
  });

  return {
    ...props,
    ...registerProps,
    'data-testid': props.name,
    onBlur: (e: FocusEvent) => {
      registerProps.onBlur(e);
      //@ts-ignore
      props.onBlur?.(e);
    },
    onChange: (e: ChangeEvent) => {
      registerProps.onChange(e);
      //@ts-ignore
      props.onChange?.(e);
    },
    ref: mergeRefs(registerRef, inputRef, ref),
  };
};

export const useCommonControllerProps = (name: string) => {
  const [t] = useTranslation(ns.Errors);
  const { isRequired } = useFormControlContext();

  return {
    name,
    rules: {
      required: isRequired && t('required'),
    },
  };
};
