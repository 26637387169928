import { useMemo } from 'react';

import { Avatar } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import {
  ChatUser,
  ChatUserRole,
  clientAvatar,
  getChatUserRole,
} from '@proptly/ui';

import { useGetPublicChatUsersQuery } from '../../api/chat';
import { useGetPublicProjectAssigneeQuery } from '../../api/project';
import { useOrderChatId } from './use-order-chat-id';
import { useOrderToken } from './use-order-token';
import { usePublicOrder } from './use-public-order';

export const useOrderChatParticipants = (participantIds: string[]) => {
  const [t] = useTranslation();

  const token = useOrderToken();
  const chatId = useOrderChatId();
  const order = usePublicOrder();

  const { data: projectAssignee } = useGetPublicProjectAssigneeQuery(
    order.token,
  );

  const { data: chatParticipants } = useGetPublicChatUsersQuery(
    token,
    chatId,
    {
      chatUserIds: participantIds,
    },
    {
      enabled: !!chatId && participantIds.length > 0,
    },
  );

  return useMemo(
    () =>
      chatParticipants?.map((chatUser) => {
        if (!chatUser) {
          throw new Error('[useChatParticipants] Cannot find chat user');
        }

        const role = getChatUserRole({
          chatUser,
          projectContractorId: order.contractorApiId,
          projectAssigneeSupertokensUserId: projectAssignee?.supertokensUserId,
          orderRoutingOperatorSupertokensUserId:
            order.routingOperator.supertokensUserId,
        });
        const isMe = role === ChatUserRole.Client;

        return {
          id: chatUser.id,
          fullName: isMe ? t('you') : chatUser.fullName,
          avatar: isMe ? (
            <Avatar variant="user" src={clientAvatar} h="10" w="10" />
          ) : (
            // TODO: get participant avatar from backend in future
            <Avatar variant="user" h="10" w="10" />
          ),
          role,
          isRoleVisible: !isMe,
        } as ChatUser;
      }),
    [chatParticipants, order, projectAssignee, t],
  );
};
