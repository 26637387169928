import { useMemo } from 'react';

import { lighten, parseToHsla } from 'color2k';

import { usePublicContractor } from '../hooks';

export interface ContractorBrandingThemeProps {
  contractorId: string;
}

export const ContractorBrandingTheme = ({
  contractorId,
}: ContractorBrandingThemeProps) => {
  const brandColor = usePublicContractor(contractorId)?.brandColor;

  const styleOverride = useMemo(() => {
    if (!brandColor) {
      return;
    }

    const [, , lightness] = parseToHsla(brandColor);

    return `body{
          --chakra-colors-primary-default: ${brandColor};
          --chakra-colors-primary-shade: ${lighten(
            brandColor,
            0.95 - lightness,
          )};
        }`;
  }, [brandColor]);

  if (!styleOverride) {
    return null;
  }

  return <style>{styleOverride}</style>;
};
