import type { ComponentStyleConfig } from '@chakra-ui/theme';

const Accordion: ComponentStyleConfig = {
  baseStyle: {
    container: {
      border: 'unset',
    },
    button: {
      color: 'text.grey',
      py: 3,
      _expanded: {
        color: 'primary.default',
      },
      '[data-whatinput="touch"] &': {
        _hover: {
          bg: 'white',
        },
      },
    },
    panel: {
      pt: 1,
      pb: 7,
    },
  },
};

export default Accordion;
