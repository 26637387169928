import borders from './borders';
import breakpoints from './breakpoints';
import colors from './colors';
import radii from './radii';
import shadows from './shadows';
import sizes from './sizes';
import space from './space';
import typography from './typography';

const foundations = {
  breakpoints,
  colors,
  shadows,
  space,
  sizes,
  radii,
  borders,
  ...typography,
};

export default foundations;
