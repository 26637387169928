import {
  ChatPermissions,
  GetChatsPermissionsResponse,
} from '@proptly/chat-shared';
import { GetOrderSummaryByTokenResponse } from '@proptly/routing-contract-classes';

import { useGetPublicChatPermissionsQueries } from '../../api/chat';
import { usePublicOrder } from './use-public-order';

export const useOrderChatPermissions = () => {
  const orderSummary = usePublicOrder();

  const [{ data: chatWithClient }, { data: chatWithContractor }] =
    useGetPublicChatPermissionsQueries(orderSummary.token, [
      orderSummary?.routingClientChatId,
      orderSummary?.clientContractorChatId,
    ] as string[]);

  const isChatWithOperatorAvailable = checkIsChatWithOperatorAvailable(
    orderSummary,
    chatWithClient?.at(0)?.permissions ?? [],
  );
  const canChatWithOperator = checkCanChatWithOperator(
    orderSummary,
    chatWithClient?.at(0)?.permissions ?? [],
  );
  const isChatWithContractorAvailable = checkIsChatWithContractorAvailable(
    orderSummary,
    chatWithContractor?.at(0)?.permissions ?? [],
  );
  const canChatWithContractor = checkCanChatWithContractor(
    orderSummary,
    chatWithContractor?.at(0)?.permissions ?? [],
  );
  const hasChatsAvailable =
    isChatWithOperatorAvailable || isChatWithContractorAvailable;

  return {
    isChatWithOperatorAvailable,
    isChatWithContractorAvailable,
    canChatWithOperator,
    canChatWithContractor,
    hasChatsAvailable,
  };
};

const checkIsChatWithOperatorAvailable = (
  order: GetOrderSummaryByTokenResponse,
  permissions: GetChatsPermissionsResponse['permissions'],
): boolean => {
  return (
    !!order.routingClientChatId && permissions.includes(ChatPermissions.Read)
  );
};

const checkCanChatWithOperator = (
  order: GetOrderSummaryByTokenResponse,
  permissions: GetChatsPermissionsResponse['permissions'],
): boolean => {
  const hasChatAvailable = checkIsChatWithOperatorAvailable(order, permissions);
  const hasWritePermission = permissions.includes(ChatPermissions.Write);

  return hasChatAvailable && hasWritePermission;
};

const checkIsChatWithContractorAvailable = (
  order: GetOrderSummaryByTokenResponse,
  permissions: GetChatsPermissionsResponse['permissions'],
): boolean => {
  return (
    !!order.clientContractorChatId && permissions.includes(ChatPermissions.Read)
  );
};

const checkCanChatWithContractor = (
  order: GetOrderSummaryByTokenResponse,
  permissions: GetChatsPermissionsResponse['permissions'],
): boolean => {
  const hasChatAvailable = checkIsChatWithContractorAvailable(
    order,
    permissions,
  );
  const hasWritePermission = permissions.includes(ChatPermissions.Write);

  return hasChatAvailable && hasWritePermission;
};
