const sizes = {
  4.5: '1.125rem',
  11: '2.75rem',
  15: '3.75rem',
  18: '4.5rem',
  mobileNavbar: '5rem',
  desktopNavbar: '72px',
  container: {
    sm: '632px',
    '2xl': '1440px',
    '3xl': '1920px',
  },
};

export default sizes;
