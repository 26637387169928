import { KeyboardEventHandler, useRef } from 'react';

import {
  Flex,
  FlexProps,
  IconButton,
  useControllableState,
} from '@chakra-ui/react';
import { Paperclip, PaperPlane } from '@phosphor-icons/react';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useSubmitErrorHandler } from '../../../hooks';
import { AutoresizeableTextarea, InputLikeDiv } from '../../atoms';
import { PendingFile } from '../../files';

export interface MessagesInputProps extends FlexProps {
  onMessageAdded: (text: string) => Promise<void>;
  files?: { id: string; src: string; fileName: string }[];
  onFileRemove?: (fileId: string) => void;
  onAddFileClick?: () => void;
  text?: string;
  onTextChange?: (text: string) => void;
  defaultText?: string;
}

export const MessagesInput = ({
  onMessageAdded,
  files,
  onFileRemove,
  onAddFileClick,
  text: textProp,
  onTextChange,
  defaultText,
  ...props
}: MessagesInputProps) => {
  const [t] = useTranslation();

  const [text, setText] = useControllableState({
    value: textProp,
    onChange: onTextChange,
    defaultValue: defaultText ?? '',
  });

  const inputRef = useRef<HTMLTextAreaElement>(null);

  const { mutateAsync: addMessage, isPending: isLoading } = useMutation({
    mutationFn: onMessageAdded,
  });
  const handleSubmitError = useSubmitErrorHandler();

  const submit = async () => {
    try {
      await addMessage(text);
    } catch (error) {
      handleSubmitError(error);

      return;
    }

    setText('');
    inputRef.current?.focus();
  };

  const handleKeyDown: KeyboardEventHandler<HTMLTextAreaElement> = (e) => {
    if (e.key === 'Enter' && (e.ctrlKey || e.metaKey)) {
      e.preventDefault();
      submit();
    }
  };

  return (
    <Flex
      gap="3"
      position="sticky"
      bottom={{ mobile: 'mobileNavbar', desktop: '0' }}
      bg="white"
      py="3"
      px="6"
      borderTop="1px solid"
      mt="-1px"
      borderColor="grey.shade"
      zIndex={1}
      {...props}
    >
      <InputLikeDiv
        w="full"
        gap="6"
        px="0"
        flexDir="column"
        wrap="nowrap"
        maxH="300px"
        overflow="auto"
      >
        <Flex w="full" align="flex-start">
          <IconButton
            aria-label={t('addAttachments')}
            variant="link"
            color="primary.default"
            onClick={onAddFileClick}
            icon={<Paperclip />}
            isDisabled={isLoading}
          />
          <AutoresizeableTextarea
            ref={inputRef}
            h="full"
            flex="1"
            py="0"
            variant="unstyled"
            value={text}
            onChange={(e) => setText(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder={t('addAMessage')}
            isReadOnly={isLoading}
          />
          <IconButton
            onClick={submit}
            variant="link"
            size="md"
            color="primary.default"
            aria-label={t('send')}
            rotate="90deg"
            transform="auto"
            icon={<PaperPlane />}
            isLoading={isLoading}
          />
        </Flex>

        {files && files.length > 0 && (
          <Flex px="4" gap="4" wrap="wrap">
            {files.map((file) => (
              <PendingFile
                key={file.id}
                isLoading={isLoading}
                onRemove={() => onFileRemove?.(file.id)}
                file={file}
              />
            ))}
          </Flex>
        )}
      </InputLikeDiv>
    </Flex>
  );
};
