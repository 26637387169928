export enum OrderStatus {
  New = 0,
  Pending = 1,
  NotResponding = 2,
  Accepted = 3,
  Rejected = 4,
  Cancelled = 5,
  Done = 6,
  OnHold = 7,
}

export const activeOrderStatuses = [
  OrderStatus.New,
  OrderStatus.Pending,
  OrderStatus.NotResponding,
  OrderStatus.Accepted,
  OrderStatus.Rejected,
  OrderStatus.OnHold,
];
