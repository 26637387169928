import { PropsWithChildren } from 'react';

import { Container, Flex } from '@chakra-ui/layout';

import { useRealTimeMessaging } from '../hooks/use-real-time-messaging';

export const CustomerPortalLayout = ({ children }: PropsWithChildren) => {
  useRealTimeMessaging();

  return (
    <Container
      maxW="1920px"
      px="0"
      minH="var(--chakra-vh)"
      display="flex"
      flexDir="column"
    >
      <Flex flexDir={{ mobile: 'column', desktop: 'row' }} h="100dvh">
        {children}
      </Flex>
    </Container>
  );
};
