import {
  InfiniteData,
  useQueryClient,
  useSuspenseInfiniteQuery,
} from '@tanstack/react-query';
import { addMilliseconds } from 'date-fns';
import isEmpty from 'lodash/isEmpty';

import { GetMessagesResponse, GetMessagesRequest } from '@proptly/chat-shared';
import { ApiResponse, chatClient } from '@proptly/ui';

export const getPublicChatMessages = (
  token: string,
  chatId: string,
  params?: GetMessagesRequest,
) => {
  return chatClient
    .get<ApiResponse<GetMessagesResponse[]>>(
      `/public/chat/${chatId}/message/${token}`,
      {
        params,
      },
    )
    .then((resp) => resp.data.data);
};

export const useGetPublicChatMessagesInfiniteQuery = (
  token: string,
  chatId: string,
  params?: GetMessagesRequest,
) => {
  const queryClient = useQueryClient();
  const queryKey = ['chat', chatId, 'messages', params];

  const query = useSuspenseInfiniteQuery({
    initialPageParam: {},
    queryKey,
    queryFn: async (context) => {
      if (!isEmpty(context.pageParam)) {
        const pageParams = { ...params, ...context.pageParam };

        return queryClient.fetchQuery({
          queryFn: () => getPublicChatMessages(token, chatId, pageParams),
          queryKey: ['chat', chatId, 'read-messages', pageParams],
          staleTime: Infinity,
        });
      }

      const queryData =
        queryClient.getQueryData<InfiniteData<GetMessagesResponse[]>>(queryKey);

      const firstPage = queryData?.pages.at(0);
      const newestMessage = firstPage?.at(0);

      // If first page is empty or does not exist do initial fetch
      if (!firstPage || !newestMessage) {
        return getPublicChatMessages(token, chatId, params);
      }

      // If there is something in first page
      // fetch newer messages than newest message in first page
      // and merge it into first page
      const newerThan = addMilliseconds(new Date(newestMessage.createdAt), 1);
      const _newMessages = await getPublicChatMessages(token, chatId, {
        newerThan,
      });
      const newMessages = Array.from(_newMessages).reverse();

      return [newMessages, firstPage].flat();
    },
    getNextPageParam: (lastPage) => {
      const oldestMessage = lastPage.at(-1);

      if (!oldestMessage) {
        return undefined;
      }

      return {
        olderThan: oldestMessage.createdAt,
      };
    },
  });

  return query;
};
