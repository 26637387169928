import { Fragment, ReactElement, ReactNode, Suspense } from 'react';

import { Box, Flex, Text } from '@chakra-ui/layout';
import { useTranslation } from 'react-i18next';

import { AboveDesktopView, createResponsiveContainer } from '@proptly/ui';

import { CustomerPortalChatSkeleton } from './customer-portal-chat-skeleton';

const Container = createResponsiveContainer({
  // eslint-disable-next-line react/jsx-no-useless-fragment
  mobile: (props) => <Fragment {...props} />,
  desktop: (props) => <Flex flexDir="column" flex="1" minW="0" {...props} />,
});

interface CustomerPortalChatMessagesLayoutProps {
  chatTabs: ReactElement;
  children: ReactNode;
}

export const CustomerPortalChatMessagesLayout = ({
  chatTabs,
  children,
}: CustomerPortalChatMessagesLayoutProps) => {
  const [t] = useTranslation();

  return (
    <Container>
      <AboveDesktopView>
        <Box bg="background" p="10">
          <Text textStyle="h2">{t('messages')}</Text>
        </Box>
      </AboveDesktopView>
      {chatTabs}
      <Suspense fallback={<CustomerPortalChatSkeleton />}>{children}</Suspense>
    </Container>
  );
};
