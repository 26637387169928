import { Avatar } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { getFullName } from '@proptly/shared';
import { ChatEmpty } from '@proptly/ui';

import { useGetPublicContractorOptionalQuery } from '../../api/contractor';
import { useGetPublicProjectAssigneeOptionalQuery } from '../../api/project';
import { useGetPublicTenantQuery } from '../../api/tenant';
import { useOrderChatId, usePublicOrder } from '../../hooks';

export const OrderChatEmpty = () => {
  const order = usePublicOrder();
  const chatId = useOrderChatId();

  const chatEmptyDetails = useChatEmptyDetails({
    isRoutingClientChat: chatId === order.routingClientChatId,
    isClientContractorChat: chatId === order.clientContractorChatId,
  });

  return <ChatEmpty {...chatEmptyDetails} />;
};

const useChatEmptyDetails = ({
  isRoutingClientChat,
  isClientContractorChat,
}: {
  isRoutingClientChat: boolean;
  isClientContractorChat: boolean;
}) => {
  const [t] = useTranslation();

  const order = usePublicOrder();
  const tenantName = useGetPublicTenantQuery(order.tenantId).data?.name;
  const { data: contractor } = useGetPublicContractorOptionalQuery(
    order.contractorApiId,
  );

  const { data: projectAssignee } = useGetPublicProjectAssigneeOptionalQuery(
    order.isRoutedToBoundContractor ? order.token : undefined,
  );

  const commonAvatarProps = {
    variant: 'user',
    w: '154px',
    h: '154px',
  };

  if (isClientContractorChat && projectAssignee && contractor?.name) {
    return {
      avatar: <Avatar {...commonAvatarProps} />,
      title: t('chatContractorWelcome', {
        userName: getFullName(projectAssignee),
        companyName: contractor?.name,
      }),
      subtitle: t('feelFreeToChat'),
    };
  }

  if (isRoutingClientChat && order.routingOperator && tenantName) {
    return {
      avatar: <Avatar {...commonAvatarProps} />,
      title: t('chatOperatorWelcome', {
        userName: getFullName(order.routingOperator),
        companyName: tenantName,
      }),
      subtitle: t('feelFreeToChat'),
    };
  }

  return {
    avatar: <Avatar {...commonAvatarProps} />,
    title: t('chatEmpty'),
  };
};
