import { Flex, IconButton, Text } from '@chakra-ui/react';
import { CaretLeft, CaretRight } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';

interface PreviewPdfPaginationProps {
  totalPages: number | null;
  currentPage: number;
  changePage: (offset: number) => void;
}

export const PreviewPdfPagination = ({
  totalPages,
  currentPage,
  changePage,
}: PreviewPdfPaginationProps) => {
  const [t] = useTranslation();

  const previousPage = () => {
    changePage(-1);
  };

  const nextPage = () => {
    changePage(1);
  };

  return (
    <Flex alignItems="center" gap="2">
      <IconButton
        size="sm"
        isDisabled={currentPage <= 1}
        onClick={previousPage}
        aria-label={t('previous')}
        icon={<CaretLeft />}
        variant="unstyled"
        color="white"
      />

      <Text color="white" minW="120px" align="center">
        {t('pageCurrentOfTotal', {
          currentPage,
          totalPages: totalPages ?? '--',
        })}
      </Text>

      <IconButton
        size="sm"
        isDisabled={totalPages ? currentPage >= totalPages : false}
        onClick={nextPage}
        aria-label={t('next')}
        icon={<CaretRight />}
        variant="unstyled"
        color="white"
      />
    </Flex>
  );
};
