import { PropsWithChildren, useCallback, useState } from 'react';

import { Confirm, ConfirmOptions } from './confirm';
import { ConfirmContextProvider, ConfirmContextValue } from './confirm-context';
import { ConfirmModal } from './confirm-modal';

export const ConfirmProvider = ({ children }: PropsWithChildren) => {
  const [confirms, setConfirms] = useState<Confirm[]>([]);

  const register: ConfirmContextValue = useCallback(
    (render, options?: ConfirmOptions) => {
      const unregister = () =>
        setConfirms((prev) => prev.filter((c) => c !== newConfirm));

      const update = () => setConfirms((prev) => [...prev]);

      const newConfirm = new Confirm(render, update, unregister, options);

      setConfirms((prev) => [...prev, newConfirm]);

      return newConfirm;
    },
    [],
  );

  return (
    <ConfirmContextProvider value={register}>
      {children}
      {confirms.map((confirm) => {
        return (
          <ConfirmModal
            isOpen={confirm.isOpen}
            onClose={confirm.onClose}
            onConfirm={confirm.onConfirm}
            key={confirm.id}
            {...confirm.options}
          >
            {confirm.render()}
          </ConfirmModal>
        );
      })}
    </ConfirmContextProvider>
  );
};
