import { FieldPath, FieldValues, UseFormSetError } from 'react-hook-form';

export class FormValidationError<
  TFieldValues extends FieldValues = FieldValues,
> {
  constructor(
    readonly name: FieldPath<TFieldValues>,
    readonly message: string,
  ) {}
}

export const isFormValidationError = <
  TFieldValues extends FieldValues = FieldValues,
>(
  error: unknown,
): error is FormValidationError<TFieldValues> =>
  error instanceof FormValidationError;

export const handleFormValidationError = <
  TFieldValues extends FieldValues = FieldValues,
>(
  setError: UseFormSetError<TFieldValues>,
  error: FormValidationError<TFieldValues>,
) => {
  setError(error.name, { message: error.message }, { shouldFocus: true });
};
