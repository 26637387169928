import { Button, forwardRef, useModalContext } from '@chakra-ui/react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';

import { useConfirmModalContext } from './confirm-modal-context';

export const ConfirmModalCancelButton: typeof Button = forwardRef(
  ({ children, ...props }, ref) => {
    const { footerElement, isLoading } = useConfirmModalContext();
    const { onClose } = useModalContext();
    const [t] = useTranslation();

    const button = (
      <Button
        isDisabled={isLoading}
        onClick={onClose}
        w="full"
        variant="solid"
        size="lg"
        colorScheme="grey"
        fontWeight="medium"
        fontSize="sm"
        color="grey.dark"
        ref={ref}
        {...props}
      >
        {children || t('no')}
      </Button>
    );

    if (!footerElement) {
      return button;
    }

    return createPortal(button, footerElement);
  },
);
