import { useCallback, useMemo } from 'react';

import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

import { ns } from '@proptly/locale';
import { BookingType, FeedbackSource, SaleType } from '@proptly/shared';

export const getFeedbackName = (
  et: TFunction<'enums', undefined, 'enums'>,
  feedbackSource: FeedbackSource,
  type?: BookingType | SaleType,
) => {
  const feedbackName = et(`feedbackSource.${feedbackSource}`, feedbackSource);

  if (!type) {
    return feedbackName;
  }

  switch (feedbackSource) {
    case FeedbackSource.Booking:
      return et(`bookingType.${type}`, feedbackName);
    case FeedbackSource.Sale:
      return et(`saleType.${type}`, feedbackName);
    default:
      return feedbackName;
  }
};

export const useFeedbackName = (
  feedbackSource: FeedbackSource,
  type?: BookingType | SaleType,
) => {
  const [et] = useTranslation(ns.Enums);

  return useMemo(
    () => getFeedbackName(et, feedbackSource, type),
    [et, feedbackSource, type],
  );
};

export const useGetFeedbackName = () => {
  const [et] = useTranslation(ns.Enums);

  return useCallback(
    (feedbackSource: FeedbackSource, type?: BookingType | SaleType) => {
      return getFeedbackName(et, feedbackSource, type);
    },
    [et],
  );
};
