import { getFileId } from '../../utils';
import {
  UploadFileFnAxiosConfig,
  UploadedFile,
  pendingFilesStore,
} from './pending-files-store';

export type UploadedFileV2 = UploadedFile;

export function createUploadFileFnV2<T extends { file: File }>(
  fn: (
    { file, ...variables }: T,
    axiosConfig: UploadFileFnAxiosConfig,
  ) => Promise<UploadedFileV2>,
) {
  type SingleFileToMultipleFiles<T extends { file: File }> = Omit<T, 'file'> & {
    files: File[];
  };

  const uploadFiles = createUploadFilesFnV2<SingleFileToMultipleFiles<T>>(
    ({ files, ...variables }, axiosConfig) =>
      Promise.all([
        fn({ file: files[0], ...variables } as unknown as T, axiosConfig),
      ]),
  );

  return ({ file, ...variables }: T) =>
    uploadFiles({
      files: [file],
      ...variables,
    } as SingleFileToMultipleFiles<T>).then((results) => results[0]);
}

export function createUploadFilesFnV2<T extends { files: File[] }>(
  fn: (
    variables: T,
    axiosConfig: UploadFileFnAxiosConfig,
  ) => Promise<UploadedFileV2[]>,
) {
  return function uploadFiles({ files, ...variables }: T) {
    const fileIds = files.map(getFileId);

    const pendingFiles = pendingFilesStore.getState().files;
    const foundPendingFiles = fileIds
      .map((fileId) => pendingFiles[fileId])
      .filter((file): file is NonNullable<typeof file> => !!file);

    const foundPendingFilesResult = Promise.all(
      foundPendingFiles.map((file) => file.uploadResult),
    );

    if (foundPendingFiles.length === fileIds.length) {
      return foundPendingFilesResult;
    }

    const foundFiles = foundPendingFiles.map((file) => file.file);
    const filesToUpload = files.filter((file) => !foundFiles.includes(file));
    const filesToUploadIds = filesToUpload.map(getFileId);

    const abortController = new AbortController();

    const uploadResult = fn({ files: filesToUpload, ...variables } as T, {
      signal: abortController.signal,
      onUploadProgress(progressEvent) {
        const loaded = progressEvent.loaded;
        const total = progressEvent.total;
        if (loaded && total) {
          const progress = loaded / total;
          const { updateFileProgress } = pendingFilesStore.getState();
          filesToUploadIds.forEach((fileId) => {
            updateFileProgress(fileId, progress);
          });
        }
      },
    }).catch((error) => {
      filesToUploadIds.forEach((fileId) => {
        pendingFilesStore.getState().cancelUpload(fileId);
      });
      throw error;
    });

    const cancelledFilesSet = new Set<string>();

    const mappedUploadResult = filesToUpload.map((file, index) => {
      const fileId = filesToUploadIds[index];
      const fileUploadResult = uploadResult.then((files) => files[index]);

      pendingFilesStore
        .getState()
        .addFile(fileId, file, fileUploadResult, () => {
          cancelledFilesSet.add(fileId);
          if (cancelledFilesSet.size === filesToUpload.length) {
            abortController.abort();
          }
        });

      return fileUploadResult;
    });

    return Promise.all([...mappedUploadResult, foundPendingFilesResult]).then(
      (results) => results.flat(),
    );
  };
}
