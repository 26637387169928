import type { ComponentStyleConfig } from '@chakra-ui/theme';
import { cssVar } from '@chakra-ui/theme-tools';

const $bg = cssVar('alert-bg');
const $fg = cssVar('alert-fg');

const Alert: ComponentStyleConfig = {
  parts: [],
  baseStyle: {
    container: {
      '& > div': {
        minW: 0,
      },
    },
  },
  variants: {
    solid(props) {
      const { colorScheme: c } = props;

      return {
        container: {
          [$bg.variable]: `colors.${c}.shade`,
          [$fg.variable]: `colors.${c}.default`,
          color: 'text.default',
          maxW: '372px',
          border: '1px',
          borderColor: [$fg.reference],
        },
      };
    },
    subtle(props) {
      const { colorScheme: c } = props;

      return {
        container: {
          [$bg.variable]: `colors.${c}.shade`,
          [$fg.variable]: `colors.${c}.default`,
          color: 'text.default',
        },
      };
    },
  },
};

export default Alert;
