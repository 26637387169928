import {
  cloneElement,
  ReactElement,
  RefObject,
  useEffect,
  useRef,
} from 'react';

import { useCallbackRef } from '@chakra-ui/hooks';

export type VisibilityObserverProps = {
  children: ReactElement<{ ref: RefObject<HTMLElement> }>;
  onFirstTimeVisible?: () => void;
};

export const VisibilityObserver = ({
  onFirstTimeVisible,
  children,
}: VisibilityObserverProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const wasVisible = useRef(false);

  const handleIntersectionChange: IntersectionObserverCallback = ([entry]) => {
    const isFirstTimeVisible = !wasVisible.current && entry.isIntersecting;

    if (isFirstTimeVisible) {
      onFirstTimeVisible?.();
      wasVisible.current = true;
    }
  };

  const handleIntersectionChangeRef = useCallbackRef(handleIntersectionChange);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) {
      return;
    }
    const observer = new IntersectionObserver(handleIntersectionChangeRef);

    // Fix issue on chrome - when scrolling very fast into observer it does not trigger onFirstTimeVisible
    const timeout = setTimeout(() => {
      observer.observe(container);
    }, 10);

    return () => {
      clearTimeout(timeout);
      observer.disconnect();
    };
  }, [handleIntersectionChangeRef]);

  return cloneElement(children, { ref: containerRef });
};
