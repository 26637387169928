import { useSuspenseQuery } from '@tanstack/react-query';
import { None } from 'typescript-functional-extensions';

import { ContractorDto } from '@proptly/contractor-contract-classes';
import { ApiResponse, contractorClient } from '@proptly/ui';

export const getPublicContractor = (contractorId: string) => {
  return contractorClient
    .get<ApiResponse<ContractorDto>>(`/contractor/${contractorId}`)
    .then((resp) => resp.data.data);
};

export const useGetPublicContractorQuery = (contractorId: string) => {
  return useSuspenseQuery({
    queryKey: ['public', 'contractor', contractorId],
    queryFn: () => getPublicContractor(contractorId).catch(() => null),
    retry: false,
  });
};

export const useGetPublicContractorOptionalQuery = (
  contractorId: string | None,
) => {
  return useSuspenseQuery({
    queryKey: ['public', 'contractor', contractorId],
    queryFn: () => {
      if (!contractorId) {
        return null;
      }

      return getPublicContractor(contractorId).catch(() => null);
    },
    retry: false,
  });
};
