import { useMemo } from 'react';

import { prune } from '@proptly/shared';

import {
  useCreateContractorRatingMutation,
  useGetContractorRatingQuery,
} from '../../api/contractor';
import { useConfirmProjectRating } from '../../hooks';
import {
  ProjectRatingForm,
  ProjectRatingFormProps,
} from './project-rating-form';

interface RatingSectionProps {
  token: string;
  onRatingAdded: () => void;
}

export const ProjectRatingSection = ({
  token,
  onRatingAdded,
}: RatingSectionProps) => {
  const { data: rating } = useGetContractorRatingQuery(token);
  const { mutateAsync: createContractorRating } =
    useCreateContractorRatingMutation();
  const confirmProjectRating = useConfirmProjectRating();

  const defaultValues: ProjectRatingFormProps['defaultValues'] = useMemo(() => {
    return prune({
      overallScore: String(rating?.overallScore),
      comment: rating?.comment,
    });
  }, [rating]);

  const onSubmit: ProjectRatingFormProps['onSubmit'] = async (values) => {
    const isConfirmed = await confirmProjectRating();

    if (isConfirmed) {
      await createContractorRating({
        token,
        overallScore: Number(values.overallScore),
        comment: values.comment,
      });
      onRatingAdded();
    }
  };

  return (
    <ProjectRatingForm
      onSubmit={onSubmit}
      isReadOnly={!!rating}
      defaultValues={rating ? defaultValues : undefined}
    />
  );
};
