import { AxiosRequestConfig } from 'axios';
import { createStore } from 'zustand';

import { ValueDto } from '@proptly/shared';

import { getFileId } from '../../utils';

export type UploadedFile = ValueDto<string> & {
  __type?: 'UploadedFile';
};

export type UploadFileFnAxiosConfig = Pick<
  AxiosRequestConfig,
  'signal' | 'onUploadProgress'
>;

interface PendingFilesStore {
  files: {
    [fileId: string]:
      | {
          id: string;
          file: File;
          uploadResult: Promise<UploadedFile>;
          progress: number;
          __cancelUpload: () => void;
        }
      | undefined;
  };
  updateFileProgress: (fileId: string, progress: number) => void;
  addFile: (
    id: string,
    file: File,
    uploadPromise: Promise<UploadedFile>,
    cancelUpload: () => void,
  ) => void;
  cancelUpload: (fileId: string) => void;
}

export const pendingFilesStore = createStore<PendingFilesStore>((set, get) => ({
  files: {},
  updateFileProgress(fileId, progress) {
    const file = get().files[fileId];
    if (file) {
      set((store) => ({
        files: {
          ...store.files,
          [fileId]: {
            ...file,
            progress,
          },
        },
      }));
    }
  },
  addFile(id, file, uploadResult, cancelUpload) {
    set((store) => ({
      files: {
        ...store.files,
        [id]: {
          id,
          file,
          uploadResult,
          __cancelUpload: cancelUpload,
          progress: 0,
        },
      },
    }));
  },
  cancelUpload(fileId) {
    const file = get().files[fileId];
    if (file) {
      file.__cancelUpload();

      set((store) => ({
        files: {
          ...store.files,
          [fileId]: undefined,
        },
      }));
    }
  },
}));

export function createUploadFileFn(
  fn: (
    file: File,
    axiosConfig: UploadFileFnAxiosConfig,
  ) => Promise<UploadedFile>,
) {
  const uploadFiles = createUploadFilesFn((files, axiosConfig) =>
    Promise.all([fn(files[0], axiosConfig)]),
  );

  return (file: File) => uploadFiles([file]).then((results) => results[0]);
}

export function createUploadFilesFn(
  fn: (
    files: File[],
    axiosConfig: UploadFileFnAxiosConfig,
  ) => Promise<UploadedFile[]>,
) {
  return function uploadFiles(files: File[]) {
    const fileIds = files.map(getFileId);

    const pendingFiles = pendingFilesStore.getState().files;
    const foundPendingFiles = fileIds
      .map((fileId) => pendingFiles[fileId])
      .filter((file): file is NonNullable<typeof file> => !!file);

    const foundPendingFilesResult = Promise.all(
      foundPendingFiles.map((file) => file.uploadResult),
    );

    if (foundPendingFiles.length === fileIds.length) {
      return foundPendingFilesResult;
    }

    const foundFiles = foundPendingFiles.map((file) => file.file);
    const filesToUpload = files.filter((file) => !foundFiles.includes(file));
    const filesToUploadIds = filesToUpload.map(getFileId);

    const abortController = new AbortController();

    const uploadResult = fn(filesToUpload, {
      signal: abortController.signal,
      onUploadProgress(progressEvent) {
        const loaded = progressEvent.loaded;
        const total = progressEvent.total;
        if (loaded && total) {
          const progress = loaded / total;
          const { updateFileProgress } = pendingFilesStore.getState();
          filesToUploadIds.forEach((fileId) => {
            updateFileProgress(fileId, progress);
          });
        }
      },
    }).catch((error) => {
      filesToUploadIds.forEach((fileId) => {
        pendingFilesStore.getState().cancelUpload(fileId);
      });
      throw error;
    });

    const cancelledFilesSet = new Set<string>();

    const mappedUploadResult = filesToUpload.map((file, index) => {
      const fileId = filesToUploadIds[index];
      const fileUploadResult = uploadResult.then((files) => files[index]);

      pendingFilesStore
        .getState()
        .addFile(fileId, file, fileUploadResult, () => {
          cancelledFilesSet.add(fileId);
          if (cancelledFilesSet.size === filesToUpload.length) {
            abortController.abort();
          }
        });

      return fileUploadResult;
    });

    return Promise.all([...mappedUploadResult, foundPendingFilesResult]).then(
      (results) => results.flat(),
    );
  };
}
