import { useMemo } from 'react';

import { getFileId, getFileSrc } from '../utils';

export const useMapFilesToPreview = (files: File[]) => {
  const filesPreview = useMemo(
    () =>
      files.map((file) => ({
        id: getFileId(file),
        fileName: file.name,
        src: getFileSrc(file),
        file,
        size: file.size,
      })),

    [files],
  );

  return filesPreview;
};
