import type { ComponentStyleConfig } from '@chakra-ui/theme';

import { getTypography } from '../utils/typography';
import { filledInputOverride, outlineInputOverride } from './common-input';

const Input: ComponentStyleConfig = {
  defaultProps: {
    variant: 'filled',
    size: 'lg',
  },
  baseStyle: {
    field: {
      '&::-webkit-date-and-time-value': {
        textAlign: 'start',
      },
    },
  },
  variants: {
    filled: {
      field: {
        ...filledInputOverride,
      },
    },
    outline: {
      field: {
        ...outlineInputOverride,
      },
    },
    readOnly: {
      element: {
        color: 'grey.default',
      },
      field: {
        color: 'grey.default',
        border: '1px dashed',
      },
    },
    search: {
      element: {
        color: 'text.grey',
      },
      field: {
        border: '1px solid',
        borderColor: 'transparent',
        bg: 'white',
        color: 'primary.default',
        _focusVisible: {
          borderColor: 'primary.default',
        },
      },
    },
  },
  sizes: {
    md(props) {
      return {
        field: {
          ...getTypography('bodySmall', props.theme),
        },
        element: {
          h: '10',
          w: '10',
        },
      };
    },
    lg(props) {
      return {
        field: {
          ...getTypography('body', props.theme),
        },
      };
    },
  },
};

export default Input;
