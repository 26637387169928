import { useMediaQuery, useQuery } from '@chakra-ui/react';

export const useView = () => {
  const aboveDesktopQuery = useQuery({ above: 'desktop' });
  const aboveLgQuery = useQuery({ above: 'lg' });
  const [isAboveDesktop] = useMediaQuery(aboveDesktopQuery, { ssr: false });
  const [isAboveLg] = useMediaQuery(aboveLgQuery, { ssr: false });

  return {
    isAboveDesktop,
    isBelowDesktop: !isAboveDesktop,
    isAboveLg,
    isBelowLg: !isAboveLg,
  };
};
