import { Box, BoxProps } from '@chakra-ui/react';

export const ChatBadge = ({ text, ...props }: { text: string } & BoxProps) => {
  return (
    <Box
      px="1"
      bg="grey.shade"
      color="grey.dark"
      fontSize="10px"
      lineHeight="16px"
      fontWeight="600"
      w="fit-content"
      rounded="4px"
      {...props}
    >
      {text}
    </Box>
  );
};
