import type { ComponentStyleConfig } from '@chakra-ui/theme';

import { getTypography } from '../utils/typography';

const Tabs: ComponentStyleConfig = {
  variants: {
    oversimplified(props) {
      const { theme } = props;

      return {
        tablist: {
          gap: 12,
        },
        tab: {
          ...getTypography('body', theme),
          color: 'text.grey',
          p: 0,
          _selected: {
            color: 'primary.default',
          },
          _active: {
            bg: 'grey.shade',
          },
          _disabled: {
            _active: { bg: 'none' },
          },
        },
        tabpanel: {
          p: 0,
        },
      };
    },
    navbar(props) {
      const { orientation, theme } = props;
      const isVertical = orientation === 'vertical';
      const borderProp =
        orientation === 'vertical' ? 'borderStart' : 'borderBottom';
      const marginProp = isVertical ? 'marginStart' : 'marginBottom';

      return {
        tablist: {
          h: 20,
          [borderProp]: '1px solid',
          borderColor: 'grey.shade',
          alignItems: 'flex-end',
          bg: 'white',
          justifyContent: 'center',
        },
        tab: {
          ...getTypography('labelMedium', theme),
          pb: 6,
          [borderProp]: '1px solid',
          borderColor: 'grey.shade',
          [marginProp]: '-1px',
          color: 'text.grey',
          _selected: {
            color: 'primary.default',
            borderColor: 'currentColor',
          },
          _active: {
            bg: 'grey.shade',
          },
          _disabled: {
            _active: { bg: 'none' },
          },
        },
      };
    },
  },
};

export default Tabs;
