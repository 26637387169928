import { useMemo } from 'react';

import { Box, Flex } from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import uniq from 'lodash/uniq';

import { isEmptyOrWhitespace } from '@proptly/shared';
import {
  Chat,
  getErrorId,
  queryClient,
  useChatFilesStore,
  useMapFilesToPreview,
  useMergePagedMessages,
} from '@proptly/ui';

import {
  useCreateMessageMutation,
  useUpdateChatReadProgressMutation,
} from '../../api/chat';
import { uploadFileAndTrackProgress } from '../../api/project-feedback/upload-file-v2';
import { useGetPublicTenantQuery } from '../../api/tenant';
import { useGetTimelineInfiniteQuery } from '../../api/timeline';
import {
  useChatMessages,
  useOrderChatId,
  useOrderChatParsedMessages,
  useOrderToken,
  usePublicOrder,
} from '../../hooks';
import {
  useOrderChatMessageEndOfList,
  useOrderChatPermissions,
} from '../../hooks/order';
import {
  systemMessagesParserCLIENT_CE,
  systemMessagesParserCLIENT_RO,
} from '../../hooks/project/use-timeline-system-messages';
import { CustomerPortalChatMessageAttachmentsRenderer } from '../customer-portal-chat-message-attachments-renderer';
import { OrderChatEmpty } from './order-chat-empty';

const timelineEventTypes = uniq([
  ...systemMessagesParserCLIENT_RO.eventTypes,
  ...systemMessagesParserCLIENT_CE.eventTypes,
]);

export const OrderChatMessages = () => {
  const token = useOrderToken();
  const chatId = useOrderChatId();
  const order = usePublicOrder();

  const files = useChatFilesStore((store) => store.files);
  const openAddFilesDialog = useChatFilesStore((store) => store.onOpen);
  const removeFile = useChatFilesStore((store) => store.removeFile);
  const clearFiles = useChatFilesStore((store) => store.clearFiles);
  const filesPreview = useMapFilesToPreview(files);

  const isContractorClientChat = order.clientContractorChatId === chatId;
  const isRoutingClientChat = order.routingClientChatId === chatId;
  const isRoutedToBoundContractor = !!order.isRoutedToBoundContractor;

  const systemMessageParser = isContractorClientChat
    ? systemMessagesParserCLIENT_CE
    : systemMessagesParserCLIENT_RO;
  const timeline = useGetTimelineInfiniteQuery(token, {
    pageSize: 100,
    types: timelineEventTypes,
  });

  const tenantName = useGetPublicTenantQuery(order.tenantId).data?.name;

  const { canChatWithContractor, canChatWithOperator } =
    useOrderChatPermissions();

  const isInputVisible =
    (isContractorClientChat && canChatWithContractor) ||
    (isRoutingClientChat && canChatWithOperator);

  const timelineEntries = useMemo(() => {
    return timeline.data?.pages.flatMap((page) => page.values) ?? [];
  }, [timeline.data]);

  const systemMessages = systemMessageParser.useMessages(timelineEntries, {
    tenantName,
    isRoutedToBoundContractor,
  });
  const { mutateAsync: createMessage } = useCreateMessageMutation();

  const { mutate: updateChatReadProgress } =
    useUpdateChatReadProgressMutation(token);

  const messagesResponse = useChatMessages({
    pageSize: 10,
  });

  const userMessages = useOrderChatParsedMessages(messagesResponse.messages);
  const newestUserMessage = userMessages.flat().at(-1);
  const oldestUserMessage = userMessages.flat().at(0);

  const messages = useMergePagedMessages({
    userMessages,
    systemMessages,
    hasMoreUserMessages: !!messagesResponse.hasNextPage,
  });

  const _handleCreateMessage = async (text: string) => {
    try {
      const attachments = await Promise.all(
        files.map((file) =>
          uploadFileAndTrackProgress({ file, token }).then((fileId) => ({
            fileId: fileId.value,
          })),
        ),
      );

      if (attachments.length || !isEmptyOrWhitespace(text)) {
        await createMessage({
          chatId,
          text,
          attachments,
          token,
        }).then(() => clearFiles());
      }
    } catch (error) {
      if (getErrorId(error) === 'CHAT_ERROR.THREAD_IS_CLOSED') {
        queryClient.invalidateQueries({
          queryKey: ['public', 'chat', 'permissions'],
        });
        queryClient.invalidateQueries({ queryKey: ['public', 'order', token] });

        return;
      }

      throw error;
    }
  };

  const { mutateAsync: handleCreateMessage, isPending: isLoading } =
    useMutation({
      mutationFn: _handleCreateMessage,
    });

  const {
    shouldRender: shouldRenderEndOfListElement,
    Component: EndOfListElement,
  } = useOrderChatMessageEndOfList();

  const hasNoUserMessages = !newestUserMessage;

  const hasEmptyMessagesFallback = isInputVisible && hasNoUserMessages;

  return (
    <>
      <Chat.MessageList
        messages={messages}
        emptyMessagesFallback={<OrderChatEmpty />}
        hasEmptyMessagesFallback={hasEmptyMessagesFallback}
        shouldRenderEndOfListElement={shouldRenderEndOfListElement}
        endOfListElement={EndOfListElement}
        AttachmentsRenderer={CustomerPortalChatMessageAttachmentsRenderer}
        bg="white"
        px={{ base: '6', lg: '10' }}
        pt={{ base: '6', lg: '10' }}
        pb={{
          base: !isInputVisible ? '6' : '0',
          lg: !isInputVisible ? '10' : '0',
        }}
        onLoadMore={messagesResponse.fetchNextPage}
        hasMore={messagesResponse.hasNextPage}
        currentPageKey={oldestUserMessage?.id}
        {...(newestUserMessage && {
          onFirstUnreadMessageVisible: () =>
            updateChatReadProgress({
              chatId,
              lastTimeRead: newestUserMessage?.createdAt,
            }),
        })}
      />
      {isInputVisible && (
        <Box bg="white" p={{ base: '6', lg: '10' }}>
          <Flex gap="2" align="end">
            <Chat.AttachmentButton
              isDisabled={isLoading}
              onClick={openAddFilesDialog}
            />
            <Chat.Input
              chatId={chatId}
              userId={token}
              onMessageAdded={handleCreateMessage}
              files={filesPreview}
              onFileRemove={removeFile}
            />
          </Flex>
        </Box>
      )}
    </>
  );
};
