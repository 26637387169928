import { useSuspenseQuery } from '@tanstack/react-query';

import { FeedbackProjectDetailsDto } from '@proptly/contractor-contract-classes';
import { ApiResponse, contractorClient } from '@proptly/ui';

export function getFeedbackProjectDetails(token: string) {
  return contractorClient
    .get<ApiResponse<FeedbackProjectDetailsDto>>(`/feedback/${token}/project`)
    .then((resp) => resp.data.data);
}

export function useGetFeedbackProjectDetailsQuery(token: string) {
  return useSuspenseQuery({
    queryKey: ['project'],
    queryFn: () => getFeedbackProjectDetails(token),
  });
}
