import { ReactNode, useState } from 'react';

import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
} from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';

import { questionMark } from '../../../images';
import { CloseIconButton } from '../../atoms/close-icon-button';
import { AboveDesktopView, BelowDesktopView } from '../../functional/view';
import { AnimateImageCircleBox } from '../../molecules/image-circle-box';
import { ConfirmOptions } from './confirm';
import { ConfirmModalProvider } from './confirm-modal-context';

export interface ConfirmModalProps extends ConfirmOptions, ModalProps {
  onConfirm: () => Promise<void> | void;
  hideClose?: boolean;
  children: ReactNode;
}

export const ConfirmModal = ({
  isOpen,
  onClose,
  onConfirm,
  children,
  hideClose = false,
  modalBodyProps,
  modalContentProps,
  modalFooterProps,
}: ConfirmModalProps) => {
  const [footerElement, setFooterElement] = useState<HTMLElement | null>(null);
  const { mutateAsync, isPending: isLoading } = useMutation({
    mutationFn: onConfirm as () => Promise<void>,
  });

  const handleConfirm = async () => {
    await mutateAsync();
    onClose();
  };

  return (
    <>
      <BelowDesktopView>
        <Drawer
          placement="bottom"
          isOpen={isOpen}
          onClose={isLoading ? () => undefined : onClose}
        >
          <DrawerOverlay zIndex="1401" />

          <DrawerContent
            containerProps={{
              zIndex: '1402',
            }}
            borderTopRadius="xl"
            gap="2"
            {...modalContentProps}
          >
            <ConfirmModalProvider
              value={{
                footerElement,
                onConfirm: handleConfirm,
                isLoading,
              }}
            >
              <DrawerHeader
                border="none"
                pt="4"
                px="4"
                pb="0"
                display="flex"
                justifyContent="flex-end"
              >
                {!hideClose && <CloseIconButton onClick={onClose} />}
              </DrawerHeader>

              <DrawerBody
                display="grid"
                justifyItems="center"
                gap="6"
                px="8"
                pb="4"
                {...modalBodyProps}
              >
                <AnimateImageCircleBox>
                  <Image src={questionMark} />
                </AnimateImageCircleBox>

                {children}
              </DrawerBody>

              <DrawerFooter
                ref={setFooterElement}
                border="none"
                gap="2"
                w="full"
                pt="0"
                pb="8"
                alignSelf="center"
                maxW="343px"
                flexDir="column"
                {...modalFooterProps}
              />
            </ConfirmModalProvider>
          </DrawerContent>
        </Drawer>
      </BelowDesktopView>

      <AboveDesktopView>
        <Modal
          isCentered
          isOpen={isOpen}
          onClose={isLoading ? () => undefined : onClose}
        >
          <ModalOverlay />

          <ModalContent borderRadius="xl" gap="2" {...modalContentProps}>
            <ConfirmModalProvider
              value={{
                footerElement,
                onConfirm: handleConfirm,
                isLoading,
              }}
            >
              <ModalHeader
                border="none"
                pt="4"
                px="4"
                pb="0"
                display="flex"
                justifyContent="flex-end"
              >
                {!hideClose && <CloseIconButton onClick={onClose} />}
              </ModalHeader>

              <ModalBody
                display="grid"
                justifyItems="center"
                gap="6"
                px="8"
                mb="4"
                {...modalBodyProps}
              >
                <AnimateImageCircleBox>
                  <Image src={questionMark} />
                </AnimateImageCircleBox>

                {children}
              </ModalBody>

              <ModalFooter
                ref={setFooterElement}
                gap="2"
                w="full"
                pt="0"
                pb="8"
                alignSelf="center"
                maxW="343px"
                flexDir="column"
                {...modalFooterProps}
              />
            </ConfirmModalProvider>
          </ModalContent>
        </Modal>
      </AboveDesktopView>
    </>
  );
};
