import { ChakraProps } from '@chakra-ui/react';
import { randomColor } from '@chakra-ui/theme-tools';

import { rainbowColors } from '../foundations/colors';

export const getAvatarColor = (name?: string) =>
  name && randomColor({ string: name, colors: rainbowColors });

export interface ColorsMapping {
  bg: ChakraProps['bg'];
  color: ChakraProps['color'];
}

export const greyColorsMapping: ColorsMapping = {
  bg: 'grey.shade',
  color: 'text.grey',
};

export const darkGreyColorsMapping: ColorsMapping = {
  bg: 'grey.dark',
  color: 'white',
};

export const greenColorsMapping: ColorsMapping = {
  bg: 'green.shade',
  color: 'green.default',
};

export const limeColorsMapping: ColorsMapping = {
  bg: 'lime.shade',
  color: 'green.default',
};

export const redColorsMapping: ColorsMapping = {
  bg: 'red.shade',
  color: 'red.default',
};

export const spiceColorsMapping: ColorsMapping = {
  bg: 'yellow.shade',
  color: 'spice.default',
};

export const purpleColorsMapping: ColorsMapping = {
  bg: 'purple.shade',
  color: 'purple.default',
};

export const peacockColorsMapping: ColorsMapping = {
  bg: 'peacock.shade',
  color: 'peacock.default',
};

export const indigoColorsMapping: ColorsMapping = {
  bg: 'indigo.shade',
  color: 'indigo.default',
};

export const reverseColorsMapping = (
  colorsMapping: ColorsMapping,
): ColorsMapping => ({
  bg: colorsMapping.color,
  color: colorsMapping.bg,
});
