/* eslint-disable @typescript-eslint/ban-ts-comment */

import { ReactNode } from 'react';

import {
  ModalBodyProps,
  ModalContentProps,
  ModalFooterProps,
} from '@chakra-ui/react';

let confirmsCount = 0;

export interface ConfirmOptions {
  modalContentProps?: ModalContentProps;
  modalBodyProps?: ModalBodyProps;
  modalFooterProps?: ModalFooterProps;
}

export class Confirm {
  isOpen = false;
  id: number;
  private resolve: (confirmResult: boolean) => void;

  constructor(
    public render: () => ReactNode,
    public update: () => void,
    public unregister: () => void,
    public options: ConfirmOptions = {},
  ) {
    this.id = confirmsCount++;
  }

  confirm<T>(cb: () => T): Promise<Awaited<T> | false>;
  //@ts-ignore
  confirm(): Promise<boolean>;

  //@ts-ignore
  confirm = <T>(cb?: () => T): Promise<Awaited<T> | boolean> => {
    this.isOpen = true;
    this.update();

    let resolveConfirm: (value: Awaited<T> | boolean) => void;
    let rejectConfirm: (error: unknown) => void;

    const promise = new Promise<Awaited<T> | boolean>(
      (resolvePromise, rejectPromise) => {
        resolveConfirm = resolvePromise;
        rejectConfirm = rejectPromise;
      },
    );

    this.resolve = async (confirmResult) => {
      if (!confirmResult) {
        resolveConfirm(false);

        return;
      }

      try {
        if (cb) {
          const cbResult = await cb();
          resolveConfirm(cbResult);

          return cbResult;
        } else {
          resolveConfirm(true);

          return true;
        }
      } catch (error) {
        rejectConfirm(error);
        throw error;
      }
    };

    return promise;
  };

  onClose = () => {
    this.isOpen = false;
    this.update();
    this.resolve(false);
  };
  onConfirm = () => {
    return this.resolve(true);
  };
}
