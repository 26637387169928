import { Styles } from '@chakra-ui/theme-tools';

const global = {
  body: {
    color: 'black',
    overscrollBehavior: 'none',
    bg: 'background',
    transition: 'unset',
  },
  'b, strong': {
    fontWeight: 500,
  },
  a: {
    overflowWrap: 'anywhere',
  },
  '@supports (height: 100dvh)': {
    ':root': {
      '--chakra-vh': '100dvh',
    },
  },
  'input[type="date"],input[type="time"]': {
    '&::-webkit-calendar-picker-indicator': {
      display: 'none',
      webKitAppearance: 'none',
    },
  },
  html: {
    '&:not([data-whatinput="keyboard"]) *:not(.chakra-popover__content)': {
      '&:focus': {
        boxShadow: 'none',
      },
      '&:focus-visible': {
        boxShadow: 'none',
      },
      '&[data-focus]': {
        boxShadow: 'none',
      },
      '&[data-focus-visible]': {
        boxShadow: 'none',
      },
    },
  },
  [`input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration`]: {
    WebkitAppearance: 'none',
  },
  '#webpack-dev-server-client-overlay': {
    display: 'none',
  },
  '#react-refresh-overlay': {
    display: 'none',
  },
  '*': {
    WebkitTapHighlightColor: 'transparent',
    _placeholder: {
      color: 'text.grey',
    },
    '&::-ms-reveal': {
      display: 'none',
    },
  },
  '.no-scrollbars': {
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
  },
};

const styles: Styles = { global };

export default styles;
