import {
  Box,
  Flex,
  IconButton,
  Progress,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { X } from '@phosphor-icons/react';
import { useStore } from 'zustand';

import { formatSize, isImageFile } from '../../utils';
import { Spinner } from '../atoms';
import { FileIcon } from './file-icon';
import { pendingFilesStore } from './pending-files-store';
import { isVideoFile } from './utils';

export interface PendingFileProps {
  file: {
    id: string;
    src: string;
    fileName: string;
    size?: number;
    hasError?: boolean;
  };
  onRemove?: () => void;
  onClick?: () => void;
  isLoading?: boolean;
}

export const PendingFile = ({
  file,
  onRemove,
  isLoading,
  onClick,
}: PendingFileProps) => {
  const cancelUpload = useStore(
    pendingFilesStore,
    (store) => store.cancelUpload,
  );

  const isUploading = useStore(pendingFilesStore, (store) => {
    const pendingFile = store.files[file.id];

    return !!pendingFile && pendingFile.progress < 1;
  });

  const isImageType = isImageFile(file);
  const isVideoType = isVideoFile(file);

  const removeButton = onRemove && (
    <IconButton
      aria-label="remove"
      bg="white"
      borderRadius="full"
      borderColor="grey.shade"
      position="absolute"
      top="-8px"
      left="-8px"
      size="xs"
      icon={<X size={8} weight="bold" />}
      onClick={() => {
        cancelUpload(file.id);
        onRemove();
      }}
    />
  );

  const uploadingSpinner = (
    <Box
      position="absolute"
      top="50%"
      left="50%"
      transform="translate(-50%, -50%)"
      pointerEvents="none"
    >
      <Spinner />
    </Box>
  );

  return isImageType || isVideoType ? (
    <Flex position="relative">
      <FileIcon
        border="1px solid"
        borderColor={file.hasError ? 'red.default' : 'transparent'}
        {...(isUploading && { opacity: 0.3 })}
        file={file}
        size={48}
        {...(onClick && {
          onClick,
          cursor: 'pointer',
        })}
      />
      <FileProgressBar fileId={file.id} />
      {!isLoading && removeButton}
      {isUploading && uploadingSpinner}
    </Flex>
  ) : (
    <Box position="relative" color="text.grey">
      <Flex
        {...(isUploading && { opacity: 0.3 })}
        border="1px solid"
        borderRadius="6px"
        borderColor={file.hasError ? 'red.default' : 'grey.shade'}
        bg="white"
        h="48px"
        align="center"
        px="4"
        gap="2"
        {...(onClick && {
          onClick,
          cursor: 'pointer',
        })}
      >
        <FileIcon file={file} size={24} color="text.grey" bg="white" />

        <Flex maxW="120px" flexDir="column">
          <Tooltip label={file.fileName}>
            <Text textStyle="labelSmall" noOfLines={1}>
              {file.fileName}
            </Text>
          </Tooltip>
          {!!file.size && (
            <Text textStyle="labelXS">
              {file.size && formatSize(file.size)}
            </Text>
          )}
        </Flex>
      </Flex>
      <FileProgressBar fileId={file.id} />
      {!isLoading && removeButton}
      {isUploading && uploadingSpinner}
    </Box>
  );
};

interface FileProgressBarProps {
  fileId: string;
}

const FileProgressBar = ({ fileId }: FileProgressBarProps) => {
  const progress = useStore(
    pendingFilesStore,
    (store) => store.files[fileId]?.progress,
  );

  if (typeof progress !== 'number' || progress === 1) {
    return null;
  }

  return (
    <Box
      position="absolute"
      bottom="0"
      left="0"
      right="0"
      h="6px"
      borderBottomRadius="6px"
      overflow="hidden"
    >
      <Progress
        mt="2px"
        value={progress * 100}
        h="4px"
        bg="primary.shade"
        sx={{
          '& [role="progressbar"]': {
            bg: 'primary.default',
          },
        }}
      />
    </Box>
  );
};
