import { Flex, forwardRef } from '@chakra-ui/react';

import { MessageAuthorSkeleton } from './message-author-skeleton';
import { MessageTextSkeleton } from './message-text-skeleton';

export const MessageListSkeleton = forwardRef((props, ref) => {
  return (
    <Flex
      flexDir="column"
      gap={{ mobile: '6', desktop: '10' }}
      ref={ref}
      {...props}
    >
      <Flex flexDir="column" gap={{ mobile: '1', desktop: '0' }}>
        <Flex flexDir="column" gap="1" ml={{ mobile: '0', desktop: '12' }}>
          <MessageTextSkeleton w={{ mobile: '70%', desktop: '45%' }} />
          <MessageTextSkeleton w={{ mobile: '40%', desktop: '30%' }} />
        </Flex>
        <MessageAuthorSkeleton w={{ mobile: '60%', desktop: '45%' }} />
      </Flex>

      <Flex
        transform="scaleX(-1)"
        flexDir="column"
        gap={{ mobile: '1', desktop: '0' }}
      >
        <Flex flexDir="column" gap="1" ml={{ mobile: '0', desktop: '12' }}>
          <MessageTextSkeleton w={{ mobile: '75%', desktop: '60%' }} />
          <MessageTextSkeleton w={{ mobile: '50%', desktop: '25%' }} />
        </Flex>
        <MessageAuthorSkeleton w={{ mobile: '75%', desktop: '50%' }} />
      </Flex>

      <Flex flexDir="column" gap={{ mobile: '1', desktop: '0' }}>
        <Flex flexDir="column" gap="1" ml={{ mobile: '0', desktop: '12' }}>
          <MessageTextSkeleton w={{ mobile: '70%', desktop: '45%' }} />
          <MessageTextSkeleton w={{ mobile: '40%', desktop: '30%' }} />
        </Flex>
        <MessageAuthorSkeleton w={{ mobile: '60%', desktop: '45%' }} />
      </Flex>

      <Flex
        transform="scaleX(-1)"
        flexDir="column"
        gap={{ mobile: '1', desktop: '0' }}
      >
        <Flex flexDir="column" gap="1" ml={{ mobile: '0', desktop: '12' }}>
          <MessageTextSkeleton w={{ mobile: '75%', desktop: '60%' }} />
          <MessageTextSkeleton w={{ mobile: '50%', desktop: '25%' }} />
        </Flex>
        <MessageAuthorSkeleton w={{ mobile: '75%', desktop: '50%' }} />
      </Flex>
    </Flex>
  );
});
