import { ReactNode } from 'react';

import { ImageProps, Text } from '@chakra-ui/react';

import { ErrorBoundary, getTenantLogoUrl, TryCompanyLogo } from '@proptly/ui';

import { useGetPublicTenantOptionalQuery } from '../api/tenant';

interface PublicTenantLogoProps extends Omit<ImageProps, 'src'> {
  tenantId?: string | null;
  notFoundFallback?: ReactNode;
  contentBeforeLogo?: ReactNode;
}

export const PublicTenantLogo = ({
  tenantId,
  notFoundFallback,
  contentBeforeLogo,
  ...props
}: PublicTenantLogoProps) => {
  const { data: tenant } = useGetPublicTenantOptionalQuery(tenantId);

  if (!tenantId || !tenant) {
    return notFoundFallback || null;
  }

  const tenantLogoSrc = getTenantLogoUrl(tenantId);

  return (
    <>
      {contentBeforeLogo}

      <ErrorBoundary
        fallbackRender={() => <Text textStyle="titleLarge">{tenant.name}</Text>}
      >
        <TryCompanyLogo src={tenantLogoSrc} {...props} />
      </ErrorBoundary>
    </>
  );
};
