const typography = {
  fonts: {
    body: "'Poppins', sans-serif",
    heading: "'Poppins', sans-serif",
  },
  textStyles: {
    h1: {
      fontSize: '2.25rem',
      fontWeight: 500,
      lineHeight: '3rem',
      letterSpacing: 0,
    },
    h2: {
      fontSize: '1.75rem',
      fontWeight: 500,
      lineHeight: '2.25rem',
      letterSpacing: 0,
    },
    titleLarge: {
      fontSize: '1.375rem',
      fontWeight: 500,
      lineHeight: '2rem',
    },
    title: {
      fontSize: '1.25rem',
      fontWeight: 400,
      lineHeight: '1.75rem',
    },
    bodyLarge: {
      fontSize: '1.125rem',
      fontWeight: 500,
      lineHeight: '1.5rem',
    },
    bodyMedium: {
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: '1.5rem',
    },
    body: {
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: '1.5rem',
    },
    bodySmall: {
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: '1.25rem',
    },
    labelMedium: {
      fontSize: '0.875rem',
      fontWeight: 500,
      lineHeight: '1.5rem',
    },
    labelSmall: {
      fontSize: '0.75rem',
      fontWeight: 500,
      lineHeight: '1rem',
    },
    labelXS: {
      fontSize: '0.75rem',
      fontWeight: 400,
      lineHeight: '1rem',
    },
    label: {
      fontSize: '0.875rem',
      fontWeight: 500,
      lineHeight: '1.5rem',
    },
    overline: {
      fontSize: '0.625rem',
      fontWeight: 400,
      lineHeight: '1rem',
    },
    overlineSemibold: {
      fontSize: '0.625rem',
      fontWeight: 600,
      lineHeight: '1rem',
    },
    XSmall: {
      fontSize: '0.5rem',
      fontWeight: 400,
      lineHeight: '0.625rem',
    },
  },
};

export default typography;
