import { useTranslation } from 'react-i18next';

import { Actor } from '@proptly/contractor-contract-enums';

export const useProjectFeedbackMessageAuthor = (
  createdBy: string,
  contractorName: string,
) => {
  const [t] = useTranslation();

  let author = contractorName;

  if (createdBy === Actor.Client) {
    author = t('you');
  }

  return { author };
};
