import { Button, Flex, FlexProps, Text } from '@chakra-ui/react';
import { Image } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { ns } from '@proptly/locale';

import { cactus } from '../../../images';
import { AnimateImageCircleBox } from '../../molecules';

export interface NotFoundFallbackProps {
  resetErrorBoundary?: () => void;
  containerProps?: FlexProps;
  text?: string;
}

export const NotFoundFallback = ({
  resetErrorBoundary,
  containerProps,
  text,
}: NotFoundFallbackProps) => {
  const [errt] = useTranslation(ns.Errors);
  const [t] = useTranslation();

  return (
    <Flex
      flexDir="column"
      textAlign="center"
      align="center"
      justify="center"
      flex="1"
      p="6"
      {...containerProps}
    >
      <AnimateImageCircleBox mb="6" pt="4" w="96px" h="96px">
        <Image src={cactus} w="96px" h="96px" />
      </AnimateImageCircleBox>

      <Text maxW="253px" mb="2" textStyle="bodyMedium">
        {text ?? errt('notFound')}
      </Text>
      {resetErrorBoundary && (
        <Button variant="outline" onClick={resetErrorBoundary}>
          {t('tryAgain')}
        </Button>
      )}
    </Flex>
  );
};
