import { useParams } from 'react-router-dom';

import { NotFoundError } from '@proptly/ui';

export const useProjectChatId = () => {
  const { chatId } = useParams<{ chatId: string }>();

  if (!chatId) {
    throw new NotFoundError();
  }

  return chatId;
};
