import {
  chakra,
  forwardRef,
  Grid,
  RadioProps,
  useRadio,
} from '@chakra-ui/react';

export const ProjectRatingRadio = forwardRef(
  ({ children, isReadOnly, ...props }: RadioProps, ref) => {
    const { getInputProps, getRadioProps, htmlProps } = useRadio(props);

    const input = getInputProps({ ref });
    const radio = getRadioProps();

    return (
      <chakra.label {...htmlProps} cursor={isReadOnly ? 'auto' : 'pointer'}>
        <input {...input} hidden />

        <Grid
          {...radio}
          placeItems="center"
          borderRadius="4px"
          w="46px"
          h="38px"
          bg="primary.shade"
          _focus={{
            bg: 'red',
          }}
          _checked={{
            bg: 'primary.default',
            color: 'white',
          }}
        >
          {children}
        </Grid>
      </chakra.label>
    );
  },
);
