import { forwardRef, IconButton, IconButtonProps } from '@chakra-ui/react';
import { UploadSimple } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';

export interface ChatInputAttachmentButtonProps
  extends Omit<IconButtonProps, 'aria-label'> {
  'aria-label'?: string;
}

export const ChatInputAttachmentButton = forwardRef<
  ChatInputAttachmentButtonProps,
  'button'
>((props, ref) => {
  const [t] = useTranslation();

  return (
    <IconButton
      ref={ref}
      display="grid"
      variant="unstyled"
      border="1px solid"
      borderColor="grey.shade"
      flexShrink="0"
      aria-label={t('addAttachments')}
      h={{ mobile: '12', desktop: '14' }}
      w={{ mobile: '12', desktop: '14' }}
      rounded="md"
      icon={<UploadSimple size="16" />}
      _hover={{ borderColor: 'primary.default' }}
      {...props}
    />
  );
});
