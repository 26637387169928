import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
  CreateMessagePayload,
  CreateMessagesResponse,
} from '@proptly/chat-shared';
import { ApiResponse, chatClient } from '@proptly/ui';

import { updateChatReadProgress } from './update-chat-read-progress';

export const createMessage = (
  chatId: string,
  token: string,
  payload: CreateMessagePayload,
) => {
  return chatClient
    .post<ApiResponse<CreateMessagesResponse>>(
      `/public/chat/${chatId}/message/${token}`,
      payload,
    )
    .then((resp) => resp.data.data);
};

interface MutationVariables extends CreateMessagePayload {
  chatId: string;
  token: string;
}

export const useCreateMessageMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ chatId, token, ...payload }: MutationVariables) =>
      createMessage(chatId, token, payload),
    onSuccess({ createdAt }, { token, chatId }) {
      updateChatReadProgress(token, chatId, { lastTimeRead: createdAt }).then(
        () => {
          queryClient.invalidateQueries({
            queryKey: ['chat', chatId, 'messages'],
          });
        },
      );
    },
  });
};
