import { Avatar } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { getFullName } from '@proptly/shared';
import { ChatEmpty } from '@proptly/ui';

import { useGetPublicProjectAssigneeQuery } from '../../api/project';
import {
  useOrderChatId,
  useProject,
  useProjectToken,
  usePublicContractor,
} from '../../hooks';

export const ProjectChatEmpty = () => {
  const project = useProject();
  const chatId = useOrderChatId();

  const chatEmptyDetails = useChatEmptyDetails({
    isClientContractorChat: chatId === project.clientContractorChatId,
  });

  return <ChatEmpty {...chatEmptyDetails} />;
};

const useChatEmptyDetails = ({
  isClientContractorChat,
}: {
  isClientContractorChat: boolean;
}) => {
  const [t] = useTranslation();

  const project = useProject();
  const token = useProjectToken();
  const contractorName = usePublicContractor(project.contractorId)?.name;

  const { data: projectAssignee } = useGetPublicProjectAssigneeQuery(token);

  const commonAvatarProps = {
    variant: 'user',
    w: '154px',
    h: '154px',
  };

  if (isClientContractorChat && projectAssignee && contractorName) {
    return {
      avatar: <Avatar {...commonAvatarProps} />,
      title: t('chatContractorWelcome', {
        userName: getFullName(projectAssignee),
        companyName: contractorName,
      }),
      subtitle: t('feelFreeToChat'),
    };
  }

  return {
    avatar: <Avatar {...commonAvatarProps} />,
    title: t('chatEmpty'),
  };
};
