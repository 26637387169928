import { useToast as useChakraToast, UseToastOptions } from '@chakra-ui/react';

const defaultOptions: UseToastOptions = {
  isClosable: true,
};

export const useToast: typeof useChakraToast = (options) => {
  return useChakraToast({
    ...defaultOptions,
    ...options,
  });
};
