import { ComponentType, useMemo } from 'react';

import { useCallbackRef, useUpdateEffect } from '@chakra-ui/react';
import { createContext } from '@chakra-ui/react-utils';
import {
  DeepPartial,
  FieldValues,
  FormProvider,
  useForm,
} from 'react-hook-form';

import { SubmitButtonProps } from '../atoms';

export interface CommonFormContextValue {
  isReadOnly: boolean;
}

export const [CommonFormContextProvider, useCommonFormContext] = createContext<
  CommonFormContextValue | undefined
>({
  name: 'CommonFormContext',
  errorMessage:
    'useCommonFormContext: "CommonFormContext" is undefined. Seems you forgot to wrap component within the CommonFormContextProvider',
  strict: false,
});

export interface CommonFormProps<
  TFieldValues extends FieldValues = FieldValues,
  TSubmitValue = TFieldValues,
> {
  submitButtonContainer?: SubmitButtonProps['container'];
  onLoadingChange?: (isLoading: boolean) => void;
  onDirtyChange?: (isDirty: boolean) => void;
  defaultValues?: DeepPartial<TFieldValues>;
  onSubmit: (values: TSubmitValue) => void;
  isReadOnly?: boolean;
}

export interface CommonFormOptions<
  TFieldValues extends FieldValues = FieldValues,
> {
  defaultValue: TFieldValues;
}

export const commonForm = <
  TFieldValues extends FieldValues = FieldValues,
  TSubmitValues = TFieldValues,
  P extends CommonFormProps<TFieldValues, TSubmitValues> = CommonFormProps<
    TFieldValues,
    TSubmitValues
  >,
>(
  Component: ComponentType<P>,
  options: CommonFormOptions<TFieldValues>,
) => {
  const { defaultValue } = options;

  return (props: P) => {
    const {
      defaultValues: propDefaultValues,
      onDirtyChange,
      onLoadingChange,
      isReadOnly,
    } = props;

    const defaultValues = Object.assign({}, defaultValue, propDefaultValues);

    const form = useForm<TFieldValues>({
      defaultValues,
    });

    const { formState } = form;
    const { isDirty, isSubmitting } = formState;

    const onDirtyChangeRef = useCallbackRef(onDirtyChange);
    const onLoadingChangeRef = useCallbackRef(onLoadingChange);

    useUpdateEffect(() => {
      onDirtyChangeRef(isDirty);

      return () => {
        onDirtyChangeRef(false);
      };
    }, [isDirty]);

    useUpdateEffect(() => {
      onLoadingChangeRef(isSubmitting);

      return () => {
        onLoadingChangeRef(false);
      };
    }, [isSubmitting]);

    const commonFormContextValue: CommonFormContextValue = useMemo(
      () => ({ isReadOnly: !!isReadOnly }),
      [isReadOnly],
    );

    return (
      <FormProvider {...form}>
        <CommonFormContextProvider value={commonFormContextValue}>
          <Component {...props} />
        </CommonFormContextProvider>
      </FormProvider>
    );
  };
};
