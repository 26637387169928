import { useEffect, useRef, useState } from 'react';

import {
  Box,
  Flex,
  forwardRef,
  IconButton,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { mergeRefs } from '@chakra-ui/react-utils';
import { BookmarkSimple, DotsThree } from '@phosphor-icons/react';
import { t } from 'i18next';
import { Link } from 'react-router-dom';

import { useFormatTime } from '../../../hooks';
import { FilesPreviewModal, PendingFile } from '../../files';
import { PopoverConditionalTrigger } from '../../functional';
import { Message } from './messages-body';

export interface MessageBubbleProps {
  message: Message;
  selectedMessage?: Message;
  onOptions?: (message: Message) => void;
  autoFocus?: boolean;
}

const HIGHLIGHT_TIMEOUT = 1000;

export const MessageBubble = forwardRef(
  (
    { message, autoFocus, onOptions, selectedMessage }: MessageBubbleProps,
    ref,
  ) => {
    const bubbleRef = useRef<HTMLElement>(null);
    const mergedRefs = mergeRefs(bubbleRef, ref);
    const [isHighlighted, setIsHighlighted] = useState(autoFocus);

    const formatTime = useFormatTime();

    useEffect(() => {
      const removeHighlighted = setTimeout(() => {
        if (autoFocus) {
          setIsHighlighted(false);
        }
      }, HIGHLIGHT_TIMEOUT);

      if (autoFocus && bubbleRef.current) {
        bubbleRef.current.scrollIntoView({
          block: 'center',
        });
      }

      return () => clearTimeout(removeHighlighted);
    }, [autoFocus]);

    if (message.__type === 'SystemMessage') {
      return (
        <Flex justify="center" ref={mergedRefs}>
          <Text textStyle="body" textAlign="center">
            {message.text}, {formatTime(message.createdAt)}
          </Text>
        </Flex>
      );
    }

    const messageIndent = { mobile: 14, desktop: '25%' };
    const isMyMessage = message.isMyMessage;
    const isSelected = message.id === selectedMessage?.id;
    const noteUrl = message.noteUrl;

    return (
      <Flex
        my="4"
        mx={{ mobile: 2, desktop: 0 }}
        {...(isMyMessage
          ? { ml: messageIndent, justify: 'flex-end' }
          : { mr: messageIndent, justify: 'flex-start' })}
        align="center"
        gap="2"
        ref={mergedRefs}
      >
        <Box
          overflow="hidden"
          borderRadius="base"
          p="4"
          border="1px"
          borderColor={isHighlighted ? 'primary.default' : 'grey.shade'}
          {...(isMyMessage && {
            bg: 'primary.shade',
          })}
          minW={{ base: '180px', desktop: '235px' }}
          maxW="635px"
        >
          <Flex
            textStyle="bodySmall"
            mb="2"
            gap="1ch"
            align="center"
            color="text.grey"
          >
            <Text as="span" noOfLines={1}>
              {message.author}
            </Text>

            <span>{formatTime(message.createdAt)}</span>

            {(onOptions || noteUrl) && (
              <Flex gap="1" ml="auto">
                {noteUrl && (
                  <IconButton
                    as={Link}
                    variant="link"
                    to={noteUrl}
                    icon={
                      <BookmarkSimple
                        color="var(--chakra-colors-primary-default)"
                        weight="fill"
                      />
                    }
                    aria-label={t('goToNote')}
                  />
                )}

                {onOptions && (
                  <PopoverConditionalTrigger isEnabled={isSelected}>
                    <IconButton
                      variant="link"
                      size="sm"
                      onClick={() => onOptions(message)}
                      icon={<DotsThree />}
                      aria-label={t('options')}
                    />
                  </PopoverConditionalTrigger>
                )}
              </Flex>
            )}
          </Flex>
          <Text textStyle="body" whiteSpace="pre-wrap">
            {message.text}
          </Text>
          {message.files && message.files.length > 0 && (
            <Flex gap="2" wrap="wrap" pt="4">
              <FilesListWithPreview files={message.files} />
            </Flex>
          )}
        </Box>
      </Flex>
    );
  },
);

export interface FilesListWithPreviewProps {
  files: {
    fileName: string;
    src: string;
    id: string;
  }[];
}

export const FilesListWithPreview = ({ files }: FilesListWithPreviewProps) => {
  const galleryDisclosure = useDisclosure();
  const [fileIndex, setFileIndex] = useState(0);

  const onFileClick = (id: string) => {
    const index = files.findIndex((f) => f.id === id);
    setFileIndex(index);
    galleryDisclosure.onOpen();
  };

  return (
    <>
      {files.map((file) => (
        <button key={file.id} onClick={() => onFileClick(file.id)}>
          <PendingFile file={file} />
        </button>
      ))}
      <FilesPreviewModal
        files={files}
        isOpen={galleryDisclosure.isOpen}
        onClose={galleryDisclosure.onClose}
        fileIndex={fileIndex}
        onFileIndexChange={setFileIndex}
      />
    </>
  );
};
