import { Flex } from '@chakra-ui/layout';
import { Skeleton } from '@chakra-ui/react';

import { MessageListSkeleton } from '@proptly/ui';

export const CustomerPortalChatSkeleton = () => {
  return (
    <>
      <Flex
        bg="white"
        flex="1"
        align="center"
        justify="center"
        overflowY="auto"
        px={{ base: '6', lg: '10' }}
        pt={{ base: '6', lg: '10' }}
        pb={{ base: '6', md: '0' }}
      >
        <MessageListSkeleton w="full" />
      </Flex>
      <Flex bg="white" p={{ base: '6', lg: '10' }}>
        <Skeleton
          w="full"
          h={{
            base: '12',
            lg: '14',
          }}
          rounded="md"
        />
      </Flex>
    </>
  );
};
