import { Container, Heading, Text } from '@chakra-ui/react';
import { FallbackProps } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';

import { ns } from '@proptly/locale';
import { getErrorMessage, NotFoundError } from '@proptly/ui';

export function NotFoundFallback({ error }: FallbackProps) {
  if (
    !(error instanceof NotFoundError) &&
    getErrorMessage(error) !== 'FEEDBACK_ERROR.DOES_NOT_EXIST'
  ) {
    throw error;
  }
  const [t] = useTranslation(ns.Errors);

  return (
    <Container py="10">
      <Heading color="primary.default" as="h1" mb="2">
        404
      </Heading>
      <Text textStyle="bodyLarge" color="primary.default" mb="2">
        Not Found
      </Text>
      <Text maxW="440px" mb="4" whiteSpace="pre-wrap">
        {t('pageDoesNotExist')}
      </Text>
    </Container>
  );
}
