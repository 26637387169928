import { Navigate, useParams } from 'react-router-dom';

import { NotFoundError } from '@proptly/ui';

import { useProject, useProjectToken } from '../hooks';

export const NavigateToProjectChatPage = () => {
  const token = useProjectToken();
  const project = useProject();

  const projectChatId = project.clientContractorChatId;

  const { chatId } = useParams<{
    chatId: string | undefined;
  }>();

  if (chatId) {
    return;
  }

  if (!projectChatId) {
    throw new NotFoundError();
  }

  return (
    <Navigate to={`/project/${token}/chat/${projectChatId}/messages`} replace />
  );
};
