import { create } from 'zustand';

import { NewMessagesResponse } from '@proptly/chat-shared';

import { chatClient } from '../../config/axios';
import { useSharedEffect } from '../../hooks/use-shared-effect';

interface NewMessagesStore {
  lastMessage: NewMessagesResponse | null;
}

const newMessageStore = create<NewMessagesStore>(() => ({
  lastMessage: null,
}));

export const useNewMessagesStream = () => {
  useSharedEffect(
    'new-messages-stream',
    () => {
      const source = new EventSource(
        chatClient.getUri({ url: 'chat/new-messages' }),
        { withCredentials: true },
      );

      source.addEventListener('message', (event) => {
        newMessageStore.setState({ lastMessage: JSON.parse(event.data) });
      });

      return () => {
        source.close();
      };
    },
    (close) => {
      close();
    },
  );

  return newMessageStore;
};

export const useNewMessagesPublicStream = (token: string) => {
  useSharedEffect(
    'new-messages-public-stream',
    () => {
      const source = new EventSource(
        chatClient.getUri({ url: `public/chat/new-messages/${token}` }),
        { withCredentials: true },
      );

      source.addEventListener('message', (event) => {
        newMessageStore.setState({ lastMessage: JSON.parse(event.data) });
      });

      return () => {
        source.close();
      };
    },
    (close) => {
      close();
    },
    [token],
  );

  return newMessageStore;
};
