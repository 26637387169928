import { QueryClient } from '@tanstack/react-query';

import { getErrorStatus } from '../utils';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 30000,
      retry: (failuresCount, error) => {
        const status = getErrorStatus(error);
        if (typeof status === 'number' && status >= 400 && status < 500) {
          return false;
        }
        if (failuresCount <= 3) {
          return false;
        }

        return true;
      },
    },
  },
});
