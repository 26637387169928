import { FileTypes } from '@proptly/shared';

import { getFileExt, isImageFile } from '../../utils';
import { FileToPreview } from './files-preview-modal';

export type FormWithFilesData = Record<string, { file: File }[]>;

export type PreviewVariant = 'image' | 'pdf' | 'video' | 'not-supported';

export const getPreviewVariant = (file: FileToPreview): PreviewVariant => {
  if (isImageFile(file)) {
    return 'image';
  }

  if (isPdfFile(file)) {
    return 'pdf';
  }

  if (isVideoFile(file)) {
    return 'video';
  }

  return 'not-supported';
};

export const isPdfFile = (file: { fileName: string }) => {
  const ext = getFileExt(file.fileName);

  return !!ext && 'pdf'.includes(ext.toLowerCase());
};

export const isVideoFile = (file: { fileName: string }) => {
  const ext = getFileExt(file.fileName);

  return !!ext && FileTypes.videoExtensions.includes(ext);
};

export const getImageFromCanvas = async (
  canvas: HTMLCanvasElement,
  fileName: string,
): Promise<{ file: File; blob: Blob } | null> => {
  const blob = await new Promise<Blob>((resolve, reject) => {
    canvas.toBlob((blob) => {
      if (!blob) {
        reject(new Error('Canvas is empty'));

        return;
      }

      resolve(blob);
    }, 'image/png');
  });

  const file = new File([blob], fileName, {
    lastModified: new Date().getTime(),
    type: blob.type,
  });

  return { file, blob };
};
