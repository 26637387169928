import { useEffect } from 'react';

import { useCurrentLang } from './use-current-lang';

export const useHtmlLangUpdater = () => {
  const currentLang = useCurrentLang();

  useEffect(() => {
    const html = document.querySelector('html');
    html?.setAttribute('lang', currentLang);
  }, [currentLang]);
};
