import { useTranslation } from 'react-i18next';

import { OrderStatus } from '@proptly/routing-contract-enums';
import { getFullName, ProjectStatus } from '@proptly/shared';
import { ChatTabs } from '@proptly/ui';

import { useGetPublicContractorOptionalQuery } from '../../api/contractor';
import { useGetPublicProjectAssigneeOptionalQuery } from '../../api/project';
import { useGetPublicTenantQuery } from '../../api/tenant';
import { useOrderChatPermissions, usePublicOrder } from '../../hooks';

export const OrderChatTabs = () => {
  const [t] = useTranslation();

  const {
    token,
    clientContractorChatId,
    routingClientChatId,
    tenantId,
    routingOperator,
    contractorApiId,
    isRoutedToBoundContractor,
  } = usePublicOrder();
  const tenantName = useGetPublicTenantQuery(tenantId).data?.name;
  const { data: contractor } =
    useGetPublicContractorOptionalQuery(contractorApiId);
  const order = usePublicOrder();
  const isContractorCancelledOrder =
    order.projectStatus === ProjectStatus.Cancelled;
  const isOrderPending = order.status === OrderStatus.Pending;

  const { isChatWithContractorAvailable, isChatWithOperatorAvailable } =
    useOrderChatPermissions();

  const { data: projectAssignee } = useGetPublicProjectAssigneeOptionalQuery(
    !!isRoutedToBoundContractor && !isContractorCancelledOrder
      ? token
      : undefined,
  );

  const showContractorDetails =
    !!order.isRoutedToBoundContractor &&
    isChatWithContractorAvailable &&
    !isContractorCancelledOrder &&
    !isOrderPending;

  return (
    <ChatTabs.TabList
      px="6"
      transform="translateY(50%)"
      zIndex="1"
      _before={{
        content: '""',
        position: 'absolute',
        top: '50%',
        left: '0',
        right: '0',
        height: '1px',
        bg: 'grey.shade',
      }}
    >
      {clientContractorChatId && isChatWithContractorAvailable && (
        <ChatTabs.Tab
          {...getChatTabDetails({
            token,
            chatId: clientContractorChatId,
            title:
              projectAssignee && contractor?.name && showContractorDetails
                ? t('userNameFromOrganisationName', {
                    userName: getFullName(projectAssignee),
                    organisationName: contractor?.name,
                  })
                : null,
            description: t('contactPerson'),
            fallback: t('contractor'),
          })}
          zIndex="1"
        />
      )}
      {routingClientChatId && isChatWithOperatorAvailable && (
        <ChatTabs.Tab
          {...getChatTabDetails({
            token,
            chatId: routingClientChatId,
            title: routingOperator
              ? t('userNameFromOrganisationName', {
                  userName: getFullName(routingOperator),
                  organisationName: tenantName,
                })
              : null,
            description: t('contactPerson'),
            fallback: t('operator'),
          })}
          zIndex="1"
        />
      )}
    </ChatTabs.TabList>
  );
};

const getChatTabDetails = ({
  token,
  chatId,
  title,
  description,
  fallback,
}: {
  token: string;
  chatId: string;
  title: string | null;
  fallback: string;
  description?: string;
}) => ({
  title: title ?? fallback,
  description: !title || !description ? undefined : description,
  to: `/order/${token}/chat/${chatId}/messages`,
});
