import {
  ChangeEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { forwardRef, Textarea, TextareaProps } from '@chakra-ui/react';
import { mergeRefs } from '@chakra-ui/react-utils';

export interface AutoresizeableTextareaProps extends TextareaProps {
  maxRows?: number;
  minRows?: number;
}

const getHeightHelper = () => {
  const helperId = 'autoresizable-textarea-height-calc-helper';
  const heightCalcHelper = document.querySelector(
    `#${helperId}`,
  ) as HTMLElement | null;
  if (heightCalcHelper) {
    return heightCalcHelper;
  }
  const newHelper = document.createElement('div');
  newHelper.id = helperId;
  newHelper.style.visibility = 'hidden';
  newHelper.style.position = 'fixed';
  newHelper.style.whiteSpace = 'pre-wrap';
  newHelper.setAttribute('aria-hidden', 'true');
  document.body.append(newHelper);

  return newHelper;
};

function getTextareaRows(textArea: HTMLTextAreaElement): number {
  const value = textArea.value;
  const textAreaStyle = document.defaultView?.getComputedStyle(textArea, null);
  if (!textAreaStyle) {
    return textArea.rows;
  }

  const div = getHeightHelper();

  div.innerText = value + ' extraword';
  div.style.fontFamily = textAreaStyle.fontFamily;
  div.style.lineHeight = textAreaStyle.lineHeight;
  div.style.fontSize = textAreaStyle.fontSize;
  div.style.letterSpacing = textAreaStyle.letterSpacing;
  div.style.fontWeight = textAreaStyle.fontWeight;
  div.style.fontStyle = textAreaStyle.fontStyle;
  div.style.width = textAreaStyle.width;
  div.style.paddingLeft = textAreaStyle.paddingLeft;
  div.style.paddingRight = textAreaStyle.paddingRight;
  div.style.borderLeft = textAreaStyle.borderLeft;
  div.style.borderRight = textAreaStyle.borderRight;

  const height = div.scrollHeight;

  const lineHeight = parseInt(textAreaStyle.lineHeight, 10);

  const rows = Math.floor(height / lineHeight) || 1;

  return rows;
}

export const AutoresizeableTextarea = forwardRef<
  AutoresizeableTextareaProps,
  typeof Textarea
>(({ maxRows, minRows, ...props }, ref) => {
  const [rows, _setRows] = useState(minRows || 1);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const setRows = useCallback(
    (count: number) => {
      if (typeof maxRows !== 'undefined' && count > maxRows) {
        _setRows(maxRows);
      } else if (typeof minRows !== 'undefined' && count < minRows) {
        _setRows(minRows);
      } else {
        _setRows(count);
      }
    },
    [maxRows, minRows],
  );

  const update = useCallback(() => {
    const textarea = textareaRef.current;
    if (!textarea) {
      return;
    }
    const rows = getTextareaRows(textarea);
    setRows(rows);
  }, [setRows]);

  useEffect(() => {
    update();
  }, [props.value, update]);

  const handleChange: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    update();
    props.onChange?.(e);
  };

  useEffect(() => {
    const textarea = textareaRef.current;
    if (!textarea) {
      return;
    }

    const observer = new ResizeObserver(update);
    observer.observe(textarea);
    update();

    return () => {
      observer.disconnect();
    };
  }, [update]);

  return (
    <Textarea
      {...props}
      rows={rows}
      onChange={handleChange}
      ref={mergeRefs(textareaRef, ref)}
    />
  );
});
