import { GetNextPageParamFunction } from '@tanstack/react-query';

import { PagedValuesDto } from '@proptly/shared';

export const getNextPageParam: GetNextPageParamFunction<
  number,
  PagedValuesDto<unknown>
> = (lastPage, allPages) => {
  if (!lastPage) {
    return 0;
  }
  const itemsCount = lastPage.count;
  const fetchedItemsCount = allPages.map((page) => page.values).flat().length;
  if (fetchedItemsCount < itemsCount) {
    return allPages.length;
  }

  return undefined;
};

export const getPaginationMeta = (
  pageSize: number,
  page: number,
  itemsCount: number,
) => {
  const pagesCount = Math.max(1, Math.ceil(itemsCount / pageSize));

  const expectedItems =
    pageSize * (page + 1) > itemsCount ? itemsCount % pageSize : pageSize;

  return {
    expectedItems,
    pagesCount,
  };
};

export const getEmptyPage = <T>() =>
  ({ count: 0, values: [] } as PagedValuesDto<T>);
