import { useMemo } from 'react';

import uniq from 'lodash/uniq';

import { GetMessagesResponse } from '@proptly/chat-shared';
import {
  ChatUserMessage,
  ChatUserRole,
  getChatUser,
  MessageVariant,
  useUniqueMessages,
} from '@proptly/ui';

import { useOrderChatParticipants } from './use-order-chat-participants';

export const useOrderChatParsedMessages = (
  messages: GetMessagesResponse[][],
): ChatUserMessage[][] => {
  const uniqueMessages = useUniqueMessages(messages);

  return useMemo(() => {
    return uniqueMessages
      .filter((page) => page.length !== 0)
      .reverse()
      .map((page) => {
        const participantsIds = extractUniqueParticipantIds(page);

        return page
          .map((message) => createUserMessage(message, participantsIds))
          .reverse();
      });
  }, [uniqueMessages]);
};

const extractUniqueParticipantIds = (messages: GetMessagesResponse[]) => {
  return uniq(messages.map((message) => message.chatUserId));
};

const createUserMessage = (
  message: GetMessagesResponse,
  participantsIds: string[],
): ChatUserMessage => {
  return {
    __type: 'UserMessage',
    id: message.id,
    createdAt: message.createdAt,
    chatUserId: message.chatUserId,
    useDetails: () => {
      const participants = useOrderChatParticipants(participantsIds);

      if (!participants) {
        throw new Error('[useDetails] participants is undefined');
      }

      const chatUser = getChatUser(participants, message.chatUserId);
      const messageVariant = getMessageVariant(chatUser.role);
      const isMe = chatUser.role === ChatUserRole.Client;

      return {
        author: chatUser,
        isUnread: !isMe && !message.wasRead,
        placement: isMe ? 'right' : 'left',
        variant: messageVariant,
      };
    },
    isUnreadRaw: !message.wasRead,
    text: message.message,
    attachments: message.attachments,
  };
};

const getMessageVariant = (role: ChatUserRole | null): MessageVariant => {
  switch (role) {
    case ChatUserRole.Client:
      return 'green-default';
    case ChatUserRole.ContractorEmployee:
    case ChatUserRole.RoutingOperator:
      return 'background';
    case ChatUserRole.PreviousContractorEmployee:
    case ChatUserRole.PreviousRoutingOperator:
      return 'grey-dark';
    default:
      return 'background';
  }
};
