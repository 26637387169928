import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ChangeFeedbackStatusPayload } from '@proptly/contractor-contract-classes';
import { contractorClient } from '@proptly/ui';

interface SubmitFeedbackData extends ChangeFeedbackStatusPayload {
  files?: File[];
}

function toFormData(data: SubmitFeedbackData) {
  const formData = new FormData();
  if (data.comment) {
    formData.set('comment', data.comment);
  }

  data.files?.forEach((file) => {
    formData.append('file', file);
  });

  return formData;
}

export function acceptFeedback(token: string, data: SubmitFeedbackData) {
  const formData = toFormData(data);

  return contractorClient.post(`/feedback/${token}/accept`, formData);
}

export function rejectFeedback(
  token: string,
  data: ChangeFeedbackStatusPayload,
) {
  return contractorClient.post(`/feedback/${token}/reject`, data);
}

export interface SubmitFeedbackMutationData extends SubmitFeedbackData {
  accept: boolean;
  token: string;
}

export function useSubmitFeedbackMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ token, accept, ...data }: SubmitFeedbackMutationData) =>
      accept
        ? acceptFeedback(token, data)
        : rejectFeedback(token, { comment: data.comment }),

    onSuccess: (_, { token }) => {
      queryClient.resetQueries({
        queryKey: ['feedback', token],
      });
    },
  });
}
