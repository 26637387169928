import { useSuspenseQuery } from '@tanstack/react-query';
import { None } from 'typescript-functional-extensions';

import { ContractorUserDto } from '@proptly/contractor-contract-classes';
import { ApiResponse, contractorClient } from '@proptly/ui';

export const getPublicProjectAssignee = (token: string) => {
  return contractorClient
    .get<ApiResponse<ContractorUserDto>>(`/project/${token}/public/assignee`)
    .then((resp) => resp.data.data);
};

export const useGetPublicProjectAssigneeQuery = (token: string) => {
  return useSuspenseQuery({
    queryKey: ['public', 'project', token, 'assignee'],

    queryFn: () => getPublicProjectAssignee(token).catch(() => null),

    retry: false,
  });
};

export const useGetPublicProjectAssigneeOptionalQuery = (
  token: string | None,
) => {
  return useSuspenseQuery({
    queryKey: ['public', 'project', token, 'assignee'],

    queryFn: () => {
      if (!token) {
        return null;
      }

      return getPublicProjectAssignee(token).catch(() => null);
    },
    retry: false,
  });
};
