import { ReactNode } from 'react';

import { ThemeTypings } from '@chakra-ui/react';

import { useBreakpointValue } from '../../hooks';

export type CreateResponsiveContainerContainers<P extends object> = Partial<
  Record<ThemeTypings['breakpoints'], (props: P) => ReactNode>
>;

export const createResponsiveContainer = <P extends object>(
  containers: CreateResponsiveContainerContainers<P>,
) => {
  const ResponsiveContainer = (props: P) => {
    const CurrentBreakpointContainer = useBreakpointValue(containers);

    if (!CurrentBreakpointContainer) {
      return null;
    }

    return <CurrentBreakpointContainer {...props} />;
  };

  return ResponsiveContainer;
};
