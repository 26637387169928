/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from 'react';

import { dinero } from 'dinero.js';
import compact from 'lodash/compact';

import {
  FeedbackSource,
  FeedbackStatus,
} from '@proptly/contractor-contract-enums';
import {
  formatAddress,
  getCurrencyByCode,
  useFormatAmountWithCurrentLang,
  useFormatTime,
  useMediumFormatDate,
} from '@proptly/ui';

import { getFileUrl } from '../../api/project-feedback';
import { useProjectFeedback } from './use-project-feedback';

const paramsOrder = [
  'name',
  'contractName',
  'jobType',
  'creationDate',
  'type',
  'date',
  'time',
  'address',
  'deadline',
  'description',
  'amount',
  'file',
  'question',
  'comment',
  'attachments',
];

export type AttachmentsParamValue = {
  _type: 'attachments';
  files: FileParamValue[];
};

export type FileParamValue = {
  _type: 'file';
  fileName: string;
  token: string;
  src: string;
};

export type FeedbackParamValue =
  | string
  | FileParamValue
  | AttachmentsParamValue;

export const useProjectFeedbackParams = () => {
  const feedback = useProjectFeedback();

  const formatAmount = useFormatAmountWithCurrentLang({
    useBrowserDefaultLocale: true,
  });
  const formatTime = useFormatTime({
    useBrowserDefaultLocale: true,
  });
  const mediumFormatDate = useMediumFormatDate({
    useBrowserDefaultLocale: true,
  });

  return useMemo(() => {
    const { source } = feedback;
    const inputMap = new Map(
      feedback.params.map((param) => [param.key, param.value]),
    );

    if (feedback.status === FeedbackStatus.Pending) {
      inputMap.delete('question');
    }

    const outputMap = new Map<string, FeedbackParamValue>();

    const on = inputMap.get('on');
    if (on) {
      inputMap.delete('on');
      outputMap.set('date', mediumFormatDate(on));
      outputMap.set('time', formatTime(on));
    }

    const deadline = inputMap.get('deadline');
    if (deadline) {
      inputMap.delete('deadline');
      outputMap.set('deadline', mediumFormatDate(deadline));
    }

    const address = inputMap.get('address');
    const city = inputMap.get('city');
    const postalCode = inputMap.get('postalCode');
    if (address && city && postalCode) {
      inputMap.delete('address');
      inputMap.delete('city');
      inputMap.delete('postalCode');
      outputMap.set('address', formatAddress({ address, city, postalCode }));
    }

    const fileName = inputMap.get('fileName');
    const fileDownloadToken = inputMap.get('fileDownloadToken');
    if (fileName && fileDownloadToken) {
      inputMap.delete('fileName');
      inputMap.delete('fileDownloadToken');
      outputMap.set('file', {
        _type: 'file',
        fileName,
        token: fileDownloadToken,
        src: getFileUrl(fileDownloadToken),
      });
    }

    const fileNamesKeys = Array.from(inputMap.keys()).filter((key) =>
      key.startsWith('fileName_'),
    );

    const filesKeyPairs = fileNamesKeys.map((key) => {
      const id = key.split('_')[1];

      return [key, `fileDownloadToken_${id}`];
    });

    const attachments: AttachmentsParamValue = {
      _type: 'attachments',
      files: compact(
        filesKeyPairs.map(([nameKey, tokenKey]) => {
          const fileName = inputMap.get(nameKey);
          const token = inputMap.get(tokenKey);

          if (!fileName || !token) {
            return null;
          }
          const file: FileParamValue = {
            _type: 'file',
            fileName,
            token,
            src: getFileUrl(token),
          };

          inputMap.delete(nameKey);
          inputMap.delete(tokenKey);

          return file;
        }),
      ),
    };
    if (attachments.files.length) {
      outputMap.set('attachments', attachments);
    }

    const name = inputMap.get('name');
    if (name) {
      if (source === FeedbackSource.Contract) {
        inputMap.delete('name');
        outputMap.set('contractName', name);
      }
    }

    const type = inputMap.get('type');
    if (type) {
      if (source === FeedbackSource.Booking) {
        inputMap.delete('type');
      }
      if (source === FeedbackSource.Sale) {
        inputMap.delete('type');
      }
    }

    const amount = inputMap.get('amount');
    const currencyCode = inputMap.get('currency');
    const amountScale = inputMap.get('amountScale');

    if (amount && currencyCode && amountScale) {
      const currency = getCurrencyByCode(currencyCode);
      const money =
        currency &&
        dinero({
          amount: Number(amount),
          currency,
          scale: Number(amountScale),
        });

      outputMap.set(
        'amount',
        `${money && formatAmount(money)} ${currencyCode}`,
      );
    }

    inputMap.delete('amount');
    inputMap.delete('currency');
    inputMap.delete('amountScale');

    Array.from(inputMap).forEach((entry) => outputMap.set(...entry));

    return Array.from(outputMap).sort(([keyA], [keyB]) => {
      const keyAIndex = paramsOrder.indexOf(keyA);
      const keyBIndex = paramsOrder.indexOf(keyB);

      if (keyAIndex === -1 || keyBIndex === -1) {
        return keyBIndex - keyAIndex;
      }

      return keyAIndex - keyBIndex;
    });
  }, [feedback, formatAmount, formatTime, mediumFormatDate]);
};

export const getParamsFiles = (
  params: ReturnType<typeof useProjectFeedbackParams>,
) => {
  return params.reduce<{ id: string; fileName: string; src: string }[]>(
    (acc, [, param]) => {
      if (!param || typeof param === 'string') {
        return acc;
      }

      if (param._type === 'file') {
        acc.push({
          id: param.token,
          fileName: param.fileName,
          src: getFileUrl(param.token),
        });
      }

      if (param._type === 'attachments') {
        acc.push(
          ...param.files.map((file) => ({
            id: file.token,
            fileName: file.fileName,
            src: getFileUrl(file.token),
          })),
        );
      }

      return acc;
    },
    [],
  );
};
