import { Box, Button, ButtonProps, forwardRef } from '@chakra-ui/react';
import { AnimationProps, motion } from 'framer-motion';

const MotionBox = motion(Box);

interface AnimationButtonProps extends ButtonProps {
  animationProps?: AnimationProps;
}

export const AnimateButton = forwardRef(
  ({ children, animationProps, ...props }: AnimationButtonProps, ref) => {
    return (
      <Button
        ref={ref}
        w="full"
        borderRadius="full"
        size="lg"
        colorScheme="primary"
        fontWeight="medium"
        fontSize="sm"
        bg="transparent"
        _hover={{
          borderColor: 'transparent',
        }}
        {...props}
      >
        <MotionBox
          w="full"
          h="full"
          position="absolute"
          bg="primary.default"
          borderRadius="full"
          zIndex="-1"
          border="1px solid"
          borderColor="primary.default"
          animate={{
            scaleX: [0, 1.02, 1],
          }}
          transition={{
            duration: 0.5,
            delay: 0.1,
          }}
          {...animationProps}
        />
        {children}
      </Button>
    );
  },
);
