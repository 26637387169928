import { ThemingProps } from '@chakra-ui/react';
import type { ComponentStyleConfig } from '@chakra-ui/theme';

import { FeedbackStatus } from '@proptly/shared';

import {
  ColorsMapping,
  darkGreyColorsMapping,
  greenColorsMapping,
  greyColorsMapping,
  limeColorsMapping,
  peacockColorsMapping,
  purpleColorsMapping,
  redColorsMapping,
  reverseColorsMapping,
  spiceColorsMapping,
} from '../utils';
import { getTypography } from '../utils/typography';

const _tagColorsMapping = {
  grey: greyColorsMapping,
  green: greenColorsMapping,
  lime: limeColorsMapping,
  red: redColorsMapping,
  spice: spiceColorsMapping,
  purple: purpleColorsMapping,
  peacock: peacockColorsMapping,
  spiceReversed: reverseColorsMapping(spiceColorsMapping),
  greenReversed: reverseColorsMapping(greenColorsMapping),
  darkGrey: darkGreyColorsMapping,
};

export const tagColorsMapping: Record<string, ColorsMapping> =
  _tagColorsMapping;

const projectFeedbackStatusColorsMapping: Record<string, ColorsMapping> = {
  [FeedbackStatus.Rejected]: redColorsMapping,
  [FeedbackStatus.Cancelled]: spiceColorsMapping,
  [FeedbackStatus.Accepted]: greenColorsMapping,
  ANSWERED: greenColorsMapping,
  EXPIRED: redColorsMapping,
};

declare module '@chakra-ui/react' {
  interface TagProps {
    colorScheme?:
      | ThemingProps<'Tag'>['colorScheme']
      | keyof typeof _tagColorsMapping;
  }
}

const arrowSizeMap: Record<string, string> = {
  sm: '10px',
  md: '20px',
  lg: '20px',
  xl: '20px',
};

const arrowPaddingMap: Record<string, number> = {
  sm: 4,
  md: 7,
  lg: 7,
  xl: 7,
};

const Tag: ComponentStyleConfig = {
  baseStyle(props) {
    const arrowSize = arrowSizeMap[props.size];
    const arrowPadding = arrowPaddingMap[props.size];

    return {
      container: {
        '&.arrow': {
          ...(arrowSize &&
            arrowPadding && {
              clipPath: `polygon(0% 0%, calc(100% - ${arrowSize}) 0%, 100% 50%, calc(100% - ${arrowSize}) 100%, 0% 100%)`,
              pr: arrowPadding,
            }),
        },
      },
    };
  },
  parts: [],
  variants: {
    default(props) {
      const { theme, colorScheme } = props;
      const { bg, color } = tagColorsMapping[colorScheme] || {};

      return {
        container: {
          ...getTypography('labelSmall', theme),
          bg: bg ?? 'primary.shade',
          color: color ?? 'primary.default',
          borderRadius: 'full',
          px: 4,
        },
      };
    },
    fieldArrayField(props) {
      const { theme } = props;

      return {
        label: {
          ...getTypography('body', theme),
        },
        container: {
          bg: 'primary.shade',
          color: 'primary.default',
          borderRadius: 'base',
          px: 2,
        },
      };
    },
    projectFeedbackStatus(props) {
      const { colorScheme: c, theme } = props;

      const { bg, color } =
        projectFeedbackStatusColorsMapping[c] || greyColorsMapping;

      return {
        container: {
          ...getTypography('labelSmall', theme),
          bg,
          color,
          borderRadius: 'full',
          px: 4,
        },
      };
    },
  },
  defaultProps: {
    variant: 'default',
  },
  sizes: {
    xl: {
      container: {
        h: 10,
      },
    },
  },
};

export default Tag;
