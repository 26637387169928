/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  DependencyList,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
} from 'react';

import { useCallbackRef, useUpdateEffect } from '@chakra-ui/react';

import {
  Confirm,
  ConfirmOptions,
} from '../components/organisms/confirm-modal/confirm';
import { useConfirmContext } from '../components/organisms/confirm-modal/confirm-context';

export const useConfirm = (
  render: () => ReactNode,
  options?: ConfirmOptions,
  deps: DependencyList = [],
) => {
  const register = useConfirmContext();
  const confirmRef = useRef<Confirm>();
  const renderRef = useCallbackRef(render);
  const optionsRef = useRef(options);
  optionsRef.current = options;

  useEffect(() => {
    const confirm = register(renderRef, optionsRef.current);

    confirmRef.current = confirm;

    return () => {
      confirm.unregister();
    };
  }, [register, renderRef]);

  useUpdateEffect(() => {
    confirmRef.current?.update();
  }, deps);

  //@ts-ignore
  return useCallback<Confirm['confirm']>((cb) => {
    const confirm = confirmRef.current;
    if (!confirm) {
      return Promise.resolve(false);
    }

    return confirm.confirm(cb);
  }, []);
};
