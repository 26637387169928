import { useState } from 'react';

import { List, ListItem, ListProps, useDisclosure } from '@chakra-ui/react';

import { FileButton } from './file-button';
import { FilesPreviewModal } from './files-preview-modal';

export interface FilesListWithPreviewProps extends ListProps {
  files: {
    fileName: string;
    src: string;
    id: string;
  }[];
}

export const FilesListWithPreview = ({
  files,
  ...props
}: FilesListWithPreviewProps) => {
  const galleryDisclosure = useDisclosure();
  const [fileIndex, setFileIndex] = useState(0);

  const onFileClick = (file: { id: string }) => {
    const index = files.findIndex((f) => f.id === file.id);
    setFileIndex(index);
    galleryDisclosure.onOpen();
  };

  return (
    <>
      <List display="grid" gap="2" {...props}>
        {files.map((file) => (
          <ListItem key={file.id}>
            <FileButton file={file} onClick={onFileClick} />
          </ListItem>
        ))}
      </List>
      <FilesPreviewModal
        files={files}
        isOpen={galleryDisclosure.isOpen}
        onClose={galleryDisclosure.onClose}
        fileIndex={fileIndex}
        onFileIndexChange={setFileIndex}
      />
    </>
  );
};
