import { useTranslation } from 'react-i18next';

import { Details } from '@proptly/ui';

import { useProjectFeedbackProjectDetails } from '../../hooks';

export const ProjectFeedbackProjectDetails = () => {
  const [t] = useTranslation();
  const project = useProjectFeedbackProjectDetails();

  const contactPerson =
    project.contactPersonFirstName && project.contactPersonLastName
      ? `${project.contactPersonFirstName} ${project.contactPersonLastName}`
      : undefined;

  return (
    <>
      <Details.Group>
        <Details.Label>{t('job')}</Details.Label>
        <Details.Value mb="0">{project.projectName}</Details.Value>
      </Details.Group>

      <Details.Group>
        <Details.Label>{t('company')}</Details.Label>
        <Details.Value mb="0">{project.contractorName}</Details.Value>
      </Details.Group>

      {contactPerson && (
        <Details.Group>
          <Details.Label>{t('contactPerson')}</Details.Label>
          <Details.Value mb="0">{contactPerson}</Details.Value>
        </Details.Group>
      )}
    </>
  );
};
