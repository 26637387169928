import { useTranslation } from 'react-i18next';

import { getFullName } from '@proptly/shared';
import { ChatTabs } from '@proptly/ui';

import { useGetPublicProjectAssigneeQuery } from '../../api/project';
import { useProject, useProjectToken, usePublicContractor } from '../../hooks';

export const ProjectChatTabs = () => {
  const [t] = useTranslation();

  const token = useProjectToken();
  const { clientContractorChatId, contractorId } = useProject();
  const contractorName = usePublicContractor(contractorId)?.name;

  const { data: projectAssignee } = useGetPublicProjectAssigneeQuery(token);

  return (
    <ChatTabs.TabList
      px="6"
      transform="translateY(50%)"
      zIndex="sticky"
      _before={{
        content: '""',
        position: 'absolute',
        top: '50%',
        left: '0',
        right: '0',
        height: '1px',
        bg: 'grey.shade',
      }}
    >
      {clientContractorChatId && (
        <ChatTabs.Tab
          {...getChatTabDetails({
            token,
            chatId: clientContractorChatId,
            title: projectAssignee
              ? t('userNameFromOrganisationName', {
                  userName: getFullName(projectAssignee),
                  organisationName: contractorName,
                })
              : null,
            description: t('contactPerson'),
            fallback: t('contractor'),
          })}
          zIndex="sticky"
        />
      )}
    </ChatTabs.TabList>
  );
};

const getChatTabDetails = ({
  token,
  chatId,
  title,
  description,
  fallback,
}: {
  token: string;
  chatId: string;
  title: string | null;
  fallback: string;
  description?: string;
}) => ({
  title: title ?? fallback,
  description: !title || !description ? undefined : description,
  to: `/project/${token}/chat/${chatId}/messages`,
});
