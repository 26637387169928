import { useMutation } from '@tanstack/react-query';

import { APP_RECAPTCHA_HEADER, FILE_MAX_UPLOAD_SIZE } from '@proptly/shared';
import {
  ApiResponse,
  createUploadFilesFn,
  FileTooLargeError,
  contractorClient,
  withRecaptcha,
  UploadedFile,
} from '@proptly/ui';

export const uploadFiles = createUploadFilesFn(async function (
  files,
  axiosConfig,
) {
  const formData = new FormData();

  files.forEach((file) => {
    if (file.size > FILE_MAX_UPLOAD_SIZE) {
      throw new FileTooLargeError(FILE_MAX_UPLOAD_SIZE, file);
    }

    formData.append('files', file);
  });

  return withRecaptcha((recaptcha) =>
    contractorClient.post<ApiResponse<UploadedFile[]>>(
      'file/public',
      formData,
      {
        headers: {
          [APP_RECAPTCHA_HEADER]: recaptcha,
        },
        ...axiosConfig,
      },
    ),
  ).then((resp) => resp.data.data);
});

export function useUploadFilesMutation() {
  return useMutation({
    mutationFn: uploadFiles,
  });
}
