import {
  cloneElement,
  ReactElement,
  RefObject,
  useEffect,
  useRef,
} from 'react';

export type MaintainScrollPositionProps = {
  children: ReactElement<{ ref: RefObject<HTMLElement> }>;
};

export const MaintainScrollPosition = ({
  children,
}: MaintainScrollPositionProps) => {
  const containerRef = useRef<HTMLElement>(null);

  useEffect(() => {
    const scrollContainer = containerRef.current;
    if (!scrollContainer) {
      return;
    }
    let previousScroll = scrollContainer.scrollTop;

    const saveScrollHeight = () => {
      previousScroll = scrollContainer.scrollTop;
    };

    const handleMutation = () => {
      scrollContainer.scrollTop = previousScroll;
    };

    const observer = new MutationObserver(handleMutation);
    observer.observe(scrollContainer, { childList: true, subtree: true });
    scrollContainer.addEventListener('scroll', saveScrollHeight);

    return () => {
      observer.disconnect();
      scrollContainer.removeEventListener('scroll', saveScrollHeight);
    };
  }, []);

  return cloneElement(children, { ref: containerRef });
};
