import { useTranslation } from 'react-i18next';

import { OrderStatus } from '@proptly/routing-contract-enums';
import { checkMark, MessageListFinalStatus, xBadge } from '@proptly/ui';

import { usePublicOrder } from './use-public-order';

export const useOrderChatMessageEndOfList = () => {
  const [t] = useTranslation();

  const order = usePublicOrder();

  const orderDoneTimestamp =
    order.status === OrderStatus.Done ? order.closedAt : null;
  const orderCancelledTimestamp =
    order.status === OrderStatus.Cancelled ? order.closedAt : null;

  const timestamp = orderDoneTimestamp || orderCancelledTimestamp;

  const title = orderDoneTimestamp
    ? t('orderHasBeenCompleted')
    : t('orderHasBeenCancelled');
  const iconSrc = orderDoneTimestamp ? checkMark : xBadge;

  return {
    shouldRender: !!timestamp,
    Component: (
      <MessageListFinalStatus
        iconSrc={iconSrc}
        timestamp={timestamp as Date}
        title={title}
      />
    ),
  };
};
