import { useQueryClient } from '@tanstack/react-query';

import { useNewMessagesPublicStream, useSharedEffect } from '@proptly/ui';

import { useOrderToken } from './order';

export const useRealTimeMessaging = () => {
  const queryClient = useQueryClient();
  const token = useOrderToken();
  const stream = useNewMessagesPublicStream(token);

  useSharedEffect(
    'real-time-messaging',
    () => {
      return stream.subscribe((state) => {
        if (!state.lastMessage) {
          return;
        }
        const chatId = state.lastMessage.chatId;
        queryClient.invalidateQueries({
          queryKey: ['chat', chatId, 'messages'],
        });
      });
    },
    (clear) => clear(),
    [queryClient, stream, token],
  );
};
