import { Flex, Icon, Text, VisuallyHiddenInput } from '@chakra-ui/react';
import { Info } from '@phosphor-icons/react';
import { Controller, UseFieldArrayReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ns } from '@proptly/locale';

import { formatSize, getFileSrc } from '../../utils';
import { FileRow } from './file-row';
import { FormWithFilesData } from './utils';

export interface FilesFieldFilesListProps {
  fieldArray: UseFieldArrayReturn<FormWithFilesData>;
  name: string;
  onFocus?: () => void;
  maxSize?: number;
  onFileClick?: (rhfId: string) => void;
}

export const FilesFieldFilesList = ({
  fieldArray,
  name: nameProp,
  onFocus,
  maxSize,
  onFileClick,
}: FilesFieldFilesListProps) => {
  const { fields, remove } = fieldArray;

  return (
    <>
      {fields.map((field, index) => {
        const name = `${nameProp}.${index}.file` as const;

        return (
          <FileItem
            name={name}
            onRemove={() => remove(index)}
            key={field.id}
            maxSize={maxSize}
            onFocus={onFocus}
            onFileClick={() => onFileClick?.(field.id)}
          />
        );
      })}
    </>
  );
};

interface FileItemProps {
  name: `${string}.${number}.file`;
  maxSize?: number;
  onRemove: () => void;
  onFocus?: () => void;
  onFileClick?: (file: File) => void;
}

const FileItem = ({
  name,
  maxSize,
  onRemove,
  onFocus,
  onFileClick,
}: FileItemProps) => {
  const [t] = useTranslation(ns.Errors);

  return (
    <Controller<FormWithFilesData, typeof name>
      name={name}
      rules={{
        validate: {
          ...(maxSize && {
            maxSize: (file) => {
              if (file.size > maxSize) {
                return t('maxSize', {
                  maxSize: formatSize(maxSize),
                });
              }

              return undefined;
            },
          }),
        },
      }}
      render={({
        field,
        fieldState: { error },
        formState: { isSubmitting },
      }) => {
        const file = field.value;

        return (
          <div>
            <VisuallyHiddenInput
              tabIndex={-1}
              ref={field.ref}
              onFocus={onFocus}
            />
            <FileRow
              isDisabled={isSubmitting}
              isInvalid={!!error}
              file={{
                fileName: file.name,
                src: getFileSrc(file),
              }}
              onRemove={onRemove}
              onFileClick={() => onFileClick?.(file)}
            />
            {error && (
              <Flex color="red.default" align="center" gap="1">
                <Icon fontSize="1rem" as={Info} />
                <Text>{error?.message}</Text>
              </Flex>
            )}
          </div>
        );
      }}
    />
  );
};
