import axios from 'axios';

import {
  AUTH_API_URL,
  CHAT_API_URL,
  CONTRACTOR_API_URL,
  FILE_API_URL,
  ROUTING_API_URL,
  TIMELINE_API_URL,
} from './constants';

export const contractorClient = axios.create({
  baseURL: `${CONTRACTOR_API_URL}/api`,
  withCredentials: true,
});

export const routingClient = axios.create({
  baseURL: `${ROUTING_API_URL}/api`,
  withCredentials: true,
});

export const authClient = axios.create({
  baseURL: `${AUTH_API_URL}/api`,
  withCredentials: true,
});

export const timelineClient = axios.create({
  baseURL: `${TIMELINE_API_URL}/api`,
  withCredentials: true,
});

export const chatClient = axios.create({
  baseURL: `${CHAT_API_URL}/api`,
  withCredentials: true,
});

export const fileClient = axios.create({
  baseURL: `${FILE_API_URL}/api`,
  withCredentials: true,
});
