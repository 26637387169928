import { ReactNode, Suspense } from 'react';

import { Flex, forwardRef, ModalBody, ModalBodyProps } from '@chakra-ui/react';

import { Spinner } from '../atoms';
import { ErrorBoundary, ErrorBoundaryProps } from '../functional';

export interface SuspendedModalBodyProps extends ModalBodyProps {
  resetKeys?: ErrorBoundaryProps['resetKeys'];
  fallback?: ReactNode;
}

export const SuspendedModalBody = forwardRef<SuspendedModalBodyProps, 'div'>(
  ({ children, resetKeys, fallback, ...props }, ref) => {
    return (
      <ModalBody {...props} ref={ref}>
        <ErrorBoundary resetKeys={resetKeys}>
          <Suspense
            fallback={
              fallback || (
                <Flex
                  h={{ mobile: '80vh', desktop: '300px' }}
                  maxH="80vh"
                  justify="center"
                  align="center"
                >
                  <Spinner />
                </Flex>
              )
            }
          >
            {children}
          </Suspense>
        </ErrorBoundary>
      </ModalBody>
    );
  },
);
