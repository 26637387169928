import { chakra, Icon } from '@chakra-ui/react';
import { Check, X } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';

const Label = chakra('h3', {
  baseStyle: {
    textStyle: 'bodySmall',
    color: 'text.grey',
    mb: 1,
    overflowWrap: 'anywhere',
  },
});

const Value = chakra('p', {
  baseStyle: {
    textStyle: 'body',
    mb: 4,
    overflowWrap: 'anywhere',
  },
});

const Title = chakra('h2', {
  baseStyle: {
    textStyle: 'bodyLarge',
    mb: 2,
    overflowWrap: 'anywhere',
  },
});

const Group = chakra('div', {
  baseStyle: {
    minW: 0,
  },
});

const PendingValue = () => {
  const [t] = useTranslation();

  return <chakra.span color="text.grey">{t('waitingForResponse')}</chakra.span>;
};

const NotSeenYet = () => {
  const [t] = useTranslation();

  return <chakra.span color="text.grey">{t('notSeenYet')}</chakra.span>;
};

const NoValue = () => {
  const [t] = useTranslation();

  return <chakra.span color="text.grey">{t('noValue')}</chakra.span>;
};

const Boolean = ({ value }: { value: boolean }) => (
  <Icon
    as={value ? Check : X}
    fontSize="1.5rem"
    color={value ? 'green.default' : 'red.default'}
  />
);

export const Details = {
  Label,
  Value,
  Title,
  Group,
  PendingValue,
  NotSeenYet,
  NoValue,
  Boolean,
};
