import { FormHelperText, forwardRef } from '@chakra-ui/react';
import { useWatch } from 'react-hook-form';

import { CommonInputProps, useCommonInputProps } from '../../hooks';
import {
  AutoresizeableTextarea,
  AutoresizeableTextareaProps,
} from './autoresizeable-textarea';

export interface TextAreaFieldProps
  extends AutoresizeableTextareaProps,
    CommonInputProps {
  name: string;
}

export const TextareaField = forwardRef<TextAreaFieldProps, 'textarea'>(
  (props, ref) => {
    const commonProps = useCommonInputProps(props, ref);

    return (
      <>
        <AutoresizeableTextarea {...commonProps} />
        {!!props.maxLength && (
          <LenghtIndicator maxLength={props.maxLength} name={props.name} />
        )}
      </>
    );
  },
);

interface LenghtIndicatorProps {
  maxLength: number;
  name: string;
}

const LenghtIndicator = ({ name, maxLength }: LenghtIndicatorProps) => {
  const value = useWatch({ name });

  const stringValue = typeof value === 'string' ? value : '';

  return (
    <FormHelperText float="right">
      {stringValue.length}/{maxLength}
    </FormHelperText>
  );
};
