import { useMemo } from 'react';

import { Avatar } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import {
  ChatUser,
  ChatUserRole,
  clientAvatar,
  getChatUserRole,
} from '@proptly/ui';

import { useGetPublicChatUsersQuery } from '../../api/chat';
import { useGetPublicProjectAssigneeQuery } from '../../api/project';
import { useProject } from './use-project';
import { useProjectChatId } from './use-project-chat-id';
import { useProjectToken } from './use-project-token';

export const useProjectChatParticipants = (participantIds: string[]) => {
  const [t] = useTranslation();

  const token = useProjectToken();
  const chatId = useProjectChatId();
  const project = useProject();

  const { data: chatParticipants } = useGetPublicChatUsersQuery(
    token,
    chatId,
    {
      chatUserIds: participantIds,
    },
    {
      enabled: !!chatId && participantIds.length > 0,
    },
  );

  const { data: projectAssignee } = useGetPublicProjectAssigneeQuery(token);

  return useMemo(
    () =>
      chatParticipants?.map((chatUser) => {
        if (!chatUser) {
          throw new Error('[useChatParticipants] Cannot find chat user');
        }

        const role = getChatUserRole({
          chatUser,
          projectContractorId: project.contractorId,
          projectAssigneeSupertokensUserId: projectAssignee?.supertokensUserId,
          // Intentionally set to null because in this case we don't need to check for order routing operator
          orderRoutingOperatorSupertokensUserId: null,
        });
        const isMe = role === ChatUserRole.Client;

        return {
          id: chatUser.id,
          fullName: isMe ? t('you') : chatUser.fullName,
          avatar: isMe ? (
            <Avatar variant="user" src={clientAvatar} h="10" w="10" />
          ) : (
            // TODO: get participant avatar from backend in future
            <Avatar variant="user" h="10" w="10" />
          ),
          role,
          isRoleVisible: !isMe,
        } as ChatUser;
      }),
    [
      chatParticipants,
      project.contractorId,
      projectAssignee?.supertokensUserId,
      t,
    ],
  );
};
