import { Button, forwardRef } from '@chakra-ui/react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';

import { AnimateButton } from '../../molecules/animate-button';
import { useConfirmModalContext } from './confirm-modal-context';

export const ConfirmModalConfirmButton: typeof Button = forwardRef(
  ({ children, ...props }, ref) => {
    const { onConfirm, isLoading, footerElement } = useConfirmModalContext();
    const [t] = useTranslation();

    const button = (
      <AnimateButton
        ref={ref}
        isLoading={isLoading}
        onClick={onConfirm}
        {...props}
      >
        {children || t('yes')}
      </AnimateButton>
    );

    if (!footerElement) {
      return button;
    }

    return createPortal(button, footerElement);
  },
);
