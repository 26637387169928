import { useEffect } from 'react';

import {
  NOTIFICATION_CLICK_TRACKING_API_PARAM,
  NOTIFICATION_CLICK_TRACKING_IDENTIFIER_PARAM,
  TrackingApi,
} from '@proptly/shared';

import { recordNotificationLinkClick } from '../api/record-notification-link-click';
import { contractorClient, routingClient } from '../config';

export const useClickTracking = () => {
  useEffect(() => {
    const currentUrl = new URL(window.location.href);
    const trackingId = currentUrl.searchParams.get(
      NOTIFICATION_CLICK_TRACKING_IDENTIFIER_PARAM,
    );
    const trackingApi = currentUrl.searchParams.get(
      NOTIFICATION_CLICK_TRACKING_API_PARAM,
    ) as TrackingApi | null;

    if (trackingId && trackingApi) {
      switch (trackingApi) {
        case TrackingApi.ContractorApi: {
          recordNotificationLinkClick(contractorClient, trackingId);
          break;
        }
        case TrackingApi.RoutingApi: {
          recordNotificationLinkClick(routingClient, trackingId);
          break;
        }
      }
    }

    let paramsModified = false;

    if (trackingId) {
      currentUrl.searchParams.delete(
        NOTIFICATION_CLICK_TRACKING_IDENTIFIER_PARAM,
      );
      paramsModified = true;
    }

    if (trackingApi) {
      currentUrl.searchParams.delete(NOTIFICATION_CLICK_TRACKING_API_PARAM);
      paramsModified = true;
    }

    if (paramsModified) {
      window.history.replaceState(
        window.history.state,
        '',
        currentUrl.toString(),
      );
    }
  }, []);
};
