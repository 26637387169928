import { useState } from 'react';

import { Box, chakra, Grid, GridProps, Image } from '@chakra-ui/react';

import { videoPlay } from '../../../images';
import { useFilesPreview } from '../../files';
import { MessageFile } from '../chat.types';
import { MessageFileItem } from './message-file';

interface MessageVideoProps extends GridProps {
  files: MessageFile[];
}

const MAX_VIDEOS_IN_ROW = 2;

export const MessageVideo = ({ files, ...props }: MessageVideoProps) => {
  const previewFile = useFilesPreview();
  const filesCount = files.length;
  const videosInRow = Math.min(filesCount, MAX_VIDEOS_IN_ROW);

  return (
    <Grid
      gridTemplateColumns={`repeat(${videosInRow}, 1fr)`}
      gap="1"
      {...props}
    >
      {files.map((file, index) => (
        <VideoItem
          key={file.id}
          file={file}
          onClick={() => previewFile(files, index)}
        />
      ))}
    </Grid>
  );
};

const VideoItem = ({
  file,
  onClick,
}: {
  file: MessageFile;
  onClick: () => void;
}) => {
  const [isError, setIsError] = useState(false);

  if (isError) {
    return <MessageFileItem onClick={onClick} file={file} />;
  }

  return (
    <Box position="relative" h="fit-content">
      <chakra.video
        objectFit="cover"
        borderRadius="6px"
        src={file.src}
        onClick={onClick}
        onError={() => setIsError(true)}
        rounded="md"
        cursor="pointer"
        maxH="340px"
        w="full"
      />
      <Box
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        pointerEvents="none"
        color="white"
      >
        <Image src={videoPlay} />
      </Box>
    </Box>
  );
};
