export enum StringLengthsEnum {
  CharsLength10 = 10,
  CharsLength50 = 50,
  CharsLength150 = 150,
  CharsLength200 = 200,
  CharsLength250 = 250,
  CharsLength1000 = 1000,
  CharsLength2000 = 2000,
  CharsLength5000 = 5000,
}
