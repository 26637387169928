import type { ComponentStyleConfig } from '@chakra-ui/theme';

import {
  checkboxAndRadioCommonControl,
  checkboxAndRadioCommonLabel,
} from './common-input';

const Radio: ComponentStyleConfig = {
  baseStyle: {
    control: checkboxAndRadioCommonControl,
    label: {
      ...checkboxAndRadioCommonLabel,
      minW: 0,
    },
  },
  variants: {
    default: {
      container: {
        justifyContent: 'space-between',
        gap: 2,
        flexDir: 'row-reverse',
      },
    },
  },
  sizes: {
    xl: {
      control: { w: 6, h: 6 },
    },
  },
  defaultProps: {
    variant: 'default',
    size: 'xl',
  },
};

export default Radio;
