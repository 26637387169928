import { Dispatch, ReactElement, SetStateAction, lazy, useState } from 'react';

import { Box, Flex, IconButton } from '@chakra-ui/react';
import { mergeRefs } from '@chakra-ui/react-utils';
import {
  ArrowLeft,
  ArrowRight,
  MinusCircle,
  PlusCircle,
} from '@phosphor-icons/react';
import type { PDFDocumentProxy } from 'pdfjs-dist';
import { useTranslation } from 'react-i18next';

import { ns } from '@proptly/locale';

import { useArrowNavigation, useScrollByDragging, useZoom } from '../../hooks';
import { isImageFile } from '../../utils';
import { NotFoundFallback, SuspendedModalBody } from '../atoms';
import { FileToPreview } from './files-preview-modal';
import { PreviewImage } from './preview-image';
import { PreviewLoader } from './preview-loader';
import { PreviewNotSupported } from './preview-not-supported';
import { PreviewPdfPagination } from './preview-pdf/preview-pdf-pagination';
import { PreviewVideo } from './preview-video';
import { getPreviewVariant, isPdfFile, PreviewVariant } from './utils';

const PreviewPdf = lazy(() => import('./preview-pdf/preview-pdf'));

interface PreviewModalBodyProps {
  totalFilesCount: number;
  setNewFileIndex: Dispatch<SetStateAction<number>>;
  file?: FileToPreview;
  error?: Error;
  onErrorChange?: Dispatch<SetStateAction<Error | undefined>>;
}

export const PreviewModalBody = ({
  file,
  totalFilesCount,
  setNewFileIndex,
  error,
  onErrorChange,
}: PreviewModalBodyProps) => {
  const [t] = useTranslation();
  const [errt] = useTranslation(ns.Errors);

  const [totalPdfFiles, setTotalPdfFiles] = useState<number | null>(null);
  const [currentPdfPage, setCurrentPdfPage] = useState(1);

  const changePdfPage = (offset: number) => {
    setCurrentPdfPage((prevPageNumber) => prevPageNumber + offset);
  };

  const { zoomIn, zoomOut, setZoom, previewFileRef, containerRef } = useZoom();
  const scrollByDraggingRefs = useScrollByDragging();

  const nextFile = () => {
    if (totalFilesCount <= 1) {
      return;
    }
    setNewFileIndex((prev) => (prev + 1) % totalFilesCount);
    resetPreviewSettings();
  };

  const prevFile = () => {
    if (totalFilesCount <= 1) {
      return;
    }
    setNewFileIndex((prev) => (prev - 1 + totalFilesCount) % totalFilesCount);
    resetPreviewSettings();
  };

  const resetPreviewSettings = () => {
    onErrorChange?.(undefined);
    setCurrentPdfPage(1);
    setZoom(1);
  };

  useArrowNavigation(prevFile, nextFile);

  if (!file) {
    return null;
  }

  const onPdfDocumentLoadSuccess = ({ numPages }: PDFDocumentProxy) => {
    setTotalPdfFiles(numPages);
  };

  const isZoomEnabled = isImageFile(file) || isPdfFile(file);
  const previewVariant = getPreviewVariant(file);

  const previewVariantComponent: Record<PreviewVariant, ReactElement> = {
    image: (
      <Box m="auto">
        <PreviewImage
          fallback={<PreviewLoader />}
          key={file.src}
          maxW="unset"
          src={file.src}
          ref={previewFileRef}
          onError={() => onErrorChange?.(new Error())}
        />
      </Box>
    ),
    pdf: (
      <PreviewPdf
        key={file.src}
        fileSrc={file.src}
        canvasRef={previewFileRef}
        currentPage={currentPdfPage}
        onDocumentLoadSuccess={onPdfDocumentLoadSuccess}
        onError={onErrorChange}
      />
    ),
    video: (
      <PreviewVideo
        fallback={<PreviewLoader />}
        key={file.src}
        src={file.src}
      />
    ),
    'not-supported': (
      <PreviewNotSupported onError={onErrorChange} fileSrc={file.src} />
    ),
  };

  const renderModalBody = () => {
    if (error) {
      return (
        <SuspendedModalBody
          fallback={<PreviewLoader />}
          h="400px"
          w="600px"
          maxW="calc(100dvw - 48px)"
          flex="unset"
          m="auto"
          bg="white"
          borderRadius="8px"
          display="flex"
        >
          <NotFoundFallback text={errt('fileNotFound')} />
        </SuspendedModalBody>
      );
    }

    return (
      <SuspendedModalBody
        ref={mergeRefs(containerRef, scrollByDraggingRefs.containerRef)}
        resetKeys={[file.src]}
        fallback={<PreviewLoader />}
        px="0"
        display="flex"
        {...(isZoomEnabled && {
          cursor: 'move',
        })}
        sx={{
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
        }}
      >
        {previewVariantComponent[previewVariant]}
      </SuspendedModalBody>
    );
  };

  const renderNavigation = () => {
    if (totalFilesCount > 1) {
      return (
        <>
          <IconButton
            size="md"
            position="fixed"
            top="50%"
            transform="translateY(-50%)"
            left="4"
            onClick={prevFile}
            aria-label={t('previous')}
            icon={<ArrowLeft />}
          />
          <IconButton
            size="md"
            position="fixed"
            top="50%"
            transform="translateY(-50%)"
            right="4"
            onClick={nextFile}
            aria-label={t('next')}
            icon={<ArrowRight />}
          />
        </>
      );
    }

    return null;
  };

  const renderZoom = () => {
    if (!isZoomEnabled || error) {
      return null;
    }

    return (
      <Flex
        position="fixed"
        gap="4"
        bottom="4"
        left="50%"
        transform="translateX(-50%)"
        filter="drop-shadow(0px 0px 1px black)"
      >
        <IconButton
          color="white"
          size="md"
          variant="link"
          onClick={zoomOut}
          aria-label={t('zoomOut')}
          icon={<MinusCircle />}
        />

        {isPdfFile(file) && (
          <PreviewPdfPagination
            totalPages={totalPdfFiles}
            currentPage={currentPdfPage}
            changePage={changePdfPage}
          />
        )}

        <IconButton
          color="white"
          size="md"
          variant="link"
          onClick={zoomIn}
          aria-label={t('zoomIn')}
          icon={<PlusCircle />}
        />
      </Flex>
    );
  };

  return (
    <>
      {renderModalBody()}
      {renderNavigation()}
      {renderZoom()}
    </>
  );
};
