import { useCallback, useEffect, useRef } from 'react';

import { getPaddings } from '../utils';

const ZOOM_STEP = 1.5;

export const useZoom = () => {
  const zoomRef = useRef(0);
  const initialZoomRef = useRef(0);
  const imageRef = useRef<HTMLImageElement | HTMLCanvasElement | null>(null);
  const containerRef = useRef<HTMLElement | null>(null);

  const getMaxZoom = () => initialZoomRef.current * ZOOM_STEP ** 5;
  const getMinZoom = () => initialZoomRef.current;

  const setZoom = useCallback((zoom: number) => {
    zoomRef.current = zoom;
    const image = imageRef.current;

    if (!image) {
      return;
    }
    image.style.transform = `scale(${zoom})`;
    image.style.marginLeft = (image.width * zoom - image.width) / 2 + 'px';
    image.style.marginRight = (image.width * zoom - image.width) / 2 + 'px';
    image.style.marginTop = (image.height * zoom - image.height) / 2 + 'px';
    image.style.marginBottom = (image.height * zoom - image.height) / 2 + 'px';
  }, []);

  const zoomOut = useCallback(() => {
    const container = containerRef.current;

    if (!container) {
      return;
    }
    const prev = zoomRef.current;
    const next = Math.max(getMinZoom(), prev / ZOOM_STEP);
    const tweakScroll = createScrollTweaker(container, prev, next);
    setZoom(next);
    tweakScroll?.();
  }, [setZoom]);

  const zoomIn = useCallback(() => {
    const container = containerRef.current;
    if (!container) {
      return;
    }
    const prev = zoomRef.current;
    const next = Math.min(getMaxZoom(), prev * ZOOM_STEP);
    const tweakScroll = createScrollTweaker(container, prev, next);
    setZoom(next);
    tweakScroll?.();
  }, [setZoom]);

  const intervalRef = useRef<number>();

  useEffect(() => {
    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  const setNaturalZoom = (
    image: HTMLImageElement | HTMLCanvasElement,
    container: HTMLElement,
  ) => {
    const onImageLoad = () => {
      const { x, y } = getPaddings(container);

      const naturalZoom = Math.min(
        (container.clientWidth - x) / image.width,
        (container.clientHeight - y) / image.height,
        1,
      );

      setZoom(naturalZoom);
      initialZoomRef.current = naturalZoom;
    };

    if (image.width) {
      onImageLoad();
    } else {
      clearInterval(intervalRef.current);
      intervalRef.current = window.setInterval(() => {
        if (image.width) {
          onImageLoad();
          clearInterval(intervalRef.current);
        }
      }, 10);
    }
  };

  const setContainerRef = (container: HTMLElement | null) => {
    clearInterval(intervalRef.current);
    containerRef.current = container;
    const image = imageRef.current;

    if (image && container) {
      setNaturalZoom(image, container);
    }
  };

  const setPreviewFileRef = (
    image: HTMLImageElement | HTMLCanvasElement | null,
  ) => {
    clearInterval(intervalRef.current);
    imageRef.current = image;
    const container = containerRef.current;

    if (image && container) {
      setNaturalZoom(image, container);
    }
  };

  return {
    zoomIn,
    zoomOut,
    setZoom,
    previewFileRef: setPreviewFileRef,
    containerRef: setContainerRef,
  };
};

const createScrollTweaker = (
  container: HTMLElement,
  prevZoom: number,
  nextZoom: number,
) => {
  const ratio = nextZoom / prevZoom;

  const { x, y } = getPaddings(container);

  const prevScrollWidth = container.scrollWidth - x;
  const prevScrollHeight = container.scrollHeight - y;
  const prevClientWidth = container.clientWidth - x;
  const prevClientHeight = container.clientHeight - y;

  const prevScrollXSize = prevScrollWidth - prevClientWidth;
  const prevScrollYSize = prevScrollHeight - prevClientHeight;

  const prevScrollLeft = container.scrollLeft;
  const prevScrollTop = container.scrollTop;

  return () => {
    const { x, y } = getPaddings(container);

    const nextScrollWidth = prevScrollWidth * ratio;
    const nextScrollHeight = prevScrollHeight * ratio;

    const nextClientWidth = container.clientWidth - x;
    const nextClientHeight = container.clientHeight - y;

    const nextScrollXSize = nextScrollWidth - nextClientWidth;
    const nextScrollYSize = nextScrollHeight - nextClientHeight;

    const scrollWidthDiff = nextScrollXSize - prevScrollXSize;
    const scrollHeightDiff = nextScrollYSize - prevScrollYSize;

    const scrollXRatio =
      (prevClientWidth / 2 + prevScrollLeft) / prevScrollWidth;
    const scrollYRatio =
      (prevClientHeight / 2 + prevScrollTop) / prevScrollHeight;

    const nextScrollLeft = prevScrollLeft + scrollWidthDiff * scrollXRatio;

    const nextScrollTop = prevScrollTop + scrollHeightDiff * scrollYRatio;

    container.scrollTop = nextScrollTop;
    container.scrollLeft = nextScrollLeft;
  };
};
