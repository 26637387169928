import { contractorClient } from '../config';

export function getContractorLogoUrl(
  contractorId: string,
  logoFileId?: string,
) {
  return contractorClient.getUri({
    url: `/contractor/${contractorId}/logo`,
    params: {
      v: logoFileId,
    },
  });
}
