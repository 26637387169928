import { BoxProps, Flex, Text } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';

import { useScrollToLink } from '../../../hooks';
import { UnreadDot } from '../unread-dot';

export interface ChatItemLinkProps extends BoxProps {
  to: string;
  title: string;
  description?: string;
  hasUnreadMessage?: boolean;
}

export const ChatItemLink = ({
  to,
  title,
  description,
  hasUnreadMessage = false,
  ...props
}: ChatItemLinkProps) => {
  const navRef = useScrollToLink(to);

  return (
    <Flex
      ref={navRef}
      as={NavLink}
      to={to}
      bg="white"
      h="14"
      px="3"
      justify="center"
      align="center"
      border="1px solid"
      borderColor="grey.shade"
      flexDir="column"
      rounded="md"
      minW="138px"
      flex="1"
      sx={{
        '&.active': {
          borderColor: 'primary.default',
        },
      }}
      {...props}
    >
      <Flex align="center" gap="2">
        {hasUnreadMessage && <UnreadDot />}
        <Text textStyle="labelSmall" textAlign="center" noOfLines={2}>
          {title}
        </Text>
      </Flex>
      {description && (
        <Text fontSize="10px" fontWeight="500" color="grey.dark">
          {description}
        </Text>
      )}
    </Flex>
  );
};
