import { PropsWithChildren } from 'react';

import { QueryErrorResetBoundary } from '@tanstack/react-query';
import {
  ErrorBoundary as ReactErrorBoundary,
  ErrorBoundaryProps as ReactErrorBoundaryProps,
} from 'react-error-boundary';

import { ErrorFallback, FullPageErrorFallback } from '../atoms';

export type ErrorBoundaryProps = PropsWithChildren<
  | ReactErrorBoundaryProps
  | Omit<
      ReactErrorBoundaryProps,
      'FallbackComponent' | 'fallbackRender' | 'fallback'
    >
> & { inContainer?: boolean };

export const ErrorBoundary = ({
  inContainer,
  ...props
}: ErrorBoundaryProps) => {
  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ReactErrorBoundary
          onReset={reset}
          {...('FallbackComponent' in props ||
          'fallbackRender' in props ||
          'fallback' in props
            ? props
            : {
                FallbackComponent: inContainer
                  ? FullPageErrorFallback
                  : ErrorFallback,
                ...props,
              })}
        />
      )}
    </QueryErrorResetBoundary>
  );
};
