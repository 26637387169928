import { Fragment } from 'react';

import { Flex, FlexProps } from '@chakra-ui/react';

import { useDebouncedValue } from '../../../hooks/use-debounced-value';
import { VisibilityObserver } from '../../functional';
import {
  AttachmentsRenderer,
  ChatMessage,
  ChatUserMessage,
  MessagePlacement,
} from '../chat.types';
import { UnreadDot } from '../unread-dot';
import { MessageAuthor } from './message-author';
import { MessageSystem } from './message-system';
import { MessageText } from './message-text';

interface MessageProps extends FlexProps {
  message: ChatMessage;
  nextMessage?: ChatMessage;
  AttachmentsRenderer?: AttachmentsRenderer;
  onFirstTimeVisible?: () => void;
}

export const Message = (props: MessageProps) => {
  if (props.message?.__type === 'SystemMessage') {
    return <MessageSystem message={props.message} />;
  }

  return <UserMessage {...props} message={props.message} />;
};

export interface UserMessageProps extends MessageProps {
  message: ChatUserMessage;
}

export const UserMessage = ({
  message,
  nextMessage,
  AttachmentsRenderer,
  onFirstTimeVisible,
  ...props
}: UserMessageProps) => {
  const details = message.useDetails();

  const isUnread = useDebouncedValue(details.isUnread, 5_000);

  const isSameAuthorAsNext =
    nextMessage?.__type === 'UserMessage' &&
    nextMessage.chatUserId === message.chatUserId;
  const showAuthor = !isSameAuthorAsNext;

  const ConditionalObserver = onFirstTimeVisible
    ? VisibilityObserver
    : Fragment;

  return (
    <Flex
      flexDir="column"
      {...(!isSameAuthorAsNext && {
        sx: {
          '&:not(:last-child)': {
            mb: { mobile: '6', desktop: '10' },
          },
        },
      })}
      gap={{ mobile: '1', desktop: '0' }}
      {...props}
    >
      <ConditionalObserver {...(onFirstTimeVisible && { onFirstTimeVisible })}>
        <Flex flexDir="column" gap="4">
          {AttachmentsRenderer &&
          message.attachments &&
          message.attachments.length > 0 ? (
            <MessageContainer placement={details.placement} maxW="530px">
              {isUnread && <StyledUnreadDot placement={details.placement} />}
              <AttachmentsRenderer
                attachments={message.attachments}
                placement={details.placement}
              />
            </MessageContainer>
          ) : null}

          {message.text && (
            <MessageContainer placement={details.placement}>
              {isUnread && <StyledUnreadDot placement={details.placement} />}
              <MessageText text={message.text} variant={details.variant} />
            </MessageContainer>
          )}
        </Flex>
      </ConditionalObserver>

      {showAuthor && (
        <MessageAuthor
          avatar={details.author.avatar}
          fullName={details.author.fullName}
          role={details.author.role}
          createdAt={message.createdAt}
          placement={details.placement}
          isRoleVisible={details.author.isRoleVisible}
        />
      )}
    </Flex>
  );
};

const MessageContainer = ({
  children,
  placement,
  ...props
}: FlexProps & { placement: MessagePlacement }) => {
  return (
    <Flex
      align="center"
      gap={{ mobile: '1', desktop: '2' }}
      w="full"
      maxW="480px"
      {...(placement === 'right' && {
        alignSelf: 'flex-end',
        flexDir: 'row-reverse',
        pr: {
          desktop: '12',
        },
        pl: {
          mobile: '12',
        },
      })}
      {...(placement === 'left' && {
        alignSelf: 'flex-start',
        flexDir: 'row',
        pr: {
          mobile: '12',
        },
        pl: {
          desktop: '12',
        },
      })}
      {...props}
    >
      {children}
    </Flex>
  );
};

const StyledUnreadDot = ({ placement }: { placement: MessagePlacement }) => {
  return (
    <UnreadDot
      mr={{
        desktop: placement === 'right' ? '-14px' : '0',
      }}
      ml={{
        desktop: placement === 'right' ? '0' : '-14px',
      }}
    />
  );
};
