import { useFilesPreview } from '../../files';
import { MessageFile } from '../chat.types';
import { MessageFileItem } from './message-file';

interface MessageDocumentProps {
  files: MessageFile[];
}

export const MessageDocument = ({ files }: MessageDocumentProps) => {
  const previewFile = useFilesPreview();

  return files.map((file, index) => {
    return (
      <MessageFileItem
        key={file.id}
        file={file}
        onClick={() => previewFile(files, index)}
      />
    );
  });
};
