import { ComponentProps, Suspense } from 'react';

import {
  AttachmentsRendererProps,
  getFileExt,
  MessageAttachmentsSkeleton,
  MessageMediaRenderer,
} from '@proptly/ui';

import {
  FileMetadataWithSrcResponse,
  useGetFilesMetadataQueries,
} from '../api/project-feedback/get-file-metadata';
import { useOrderToken } from '../hooks';

const Component = ({ attachments, placement }: AttachmentsRendererProps) => {
  const token = useOrderToken();
  const fileIds = attachments?.map((f) => f.id) ?? [];
  const filesMetadataQueries = useGetFilesMetadataQueries(fileIds, token);
  const filesMetadata = filesMetadataQueries
    .map((f) => f.data)
    .filter((f): f is FileMetadataWithSrcResponse => !!f);

  if (!filesMetadata.length) {
    return null;
  }

  const files = filesMetadata.map((fileMetadata) => ({
    id: fileMetadata.id,
    src: fileMetadata.src,
    fileName: fileMetadata.name,
    fileSize: fileMetadata.size,
    fileType: getFileExt(fileMetadata.name),
  }));

  return <MessageMediaRenderer files={files} placement={placement} />;
};

export const CustomerPortalChatMessageAttachmentsRenderer = (
  props: ComponentProps<typeof Component>,
) => {
  return (
    <Suspense
      fallback={<MessageAttachmentsSkeleton placement={props.placement} />}
    >
      <Component {...props} />
    </Suspense>
  );
};
