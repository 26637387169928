import { format, formatDistanceToNowStrict } from 'date-fns';
import dateFnsFormatISO from 'date-fns/formatISO';
import {
  format as _formatPhoneNumber,
  NationalNumber,
  NumberFormat,
} from 'libphonenumber-js';
import prettyBytes from 'pretty-bytes';

import { DateLike, isNotEmptyOrWhitespace } from '@proptly/shared';

import { countryCodeDisplayName } from '../country';

export const omitDatestring = (date: DateLike) =>
  typeof date !== 'string'
    ? date
    : date.includes('T')
    ? new Date(date)
    : new Date(`${date}T${format(new Date(), 'HH:mm')}`);

export function formatSize(bytes: number) {
  return prettyBytes(bytes);
}

export const formatISODateOnly = (date: DateLike) => {
  return dateFnsFormatISO(omitDatestring(date), { representation: 'date' });
};

export const formatISOTimeOnly = (date: DateLike) => {
  return format(omitDatestring(date), 'HH:mm');
};

export const formatDaysToNow = (
  date: Date | number,
  options?: {
    addSuffix?: boolean;
    locale?: Locale;
  },
) => {
  const dateWithCurrentTime = new Date(
    `${dateFnsFormatISO(date, { representation: 'date' })}T${dateFnsFormatISO(
      new Date(),
      { representation: 'time' },
    )}`,
  );

  return formatDistanceToNowStrict(dateWithCurrentTime, {
    ...options,
    unit: 'day',
  });
};

export function addressToSearchQuery(object: {
  address?: string | null;
  city?: string | null;
  postalCode?: string | null;
}) {
  return [object.postalCode, object.city, object.address]
    .filter(isNotEmptyOrWhitespace)
    .join(' ');
}

export function hasAddress(object: {
  address?: string | null;
  city?: string | null;
  postalCode?: string | null;
}) {
  return (
    [object.address, object.postalCode, object.city].filter(
      isNotEmptyOrWhitespace,
    ).length > 0
  );
}

export function formatAddress(object: {
  address?: string | null;
  city?: string | null;
  postalCode?: string | null;
}) {
  const postalCodeAndCity = [object.postalCode, object.city]
    .filter(isNotEmptyOrWhitespace)
    .join(' ');

  return [object.address, postalCodeAndCity]
    .filter(isNotEmptyOrWhitespace)
    .join(', ');
}

export function formatFullAddress(
  object: {
    address?: string | null;
    city?: string | null;
    postalCode?: string | null;
    countryCode?: string | null;
  },
  locales?: Intl.LocalesArgument,
) {
  return [
    formatAddress(object),
    object.countryCode && countryCodeDisplayName(object.countryCode, locales),
  ]
    .filter(isNotEmptyOrWhitespace)
    .join(', ');
}

export const formatPhoneNumber = (
  parsedNumber: NationalNumber,
  format: NumberFormat = 'INTERNATIONAL',
) => _formatPhoneNumber(parsedNumber, format);

export const formatEmailLink = (email: string) => {
  return `mailto:${email}`;
};

const langNameOverride = new Map([
  // override Norwegian Bokmål with just Norwegian
  ['nb', 'no'],
]);

export const getLanguageName = (
  lang: string,
  locales?: Intl.LocalesArgument,
) => {
  const langToUse = langNameOverride.get(lang) || lang;
  try {
    const langName =
      new Intl.DisplayNames(locales, { type: 'language' }).of(langToUse) ??
      langToUse;

    return `${langName[0].toUpperCase()}${langName.substring(1)}`;
  } catch {
    return langToUse;
  }
};
