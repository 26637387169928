import { PropsWithChildren, useEffect } from 'react';

import { Box, Grid, GridProps } from '@chakra-ui/react';
import { createPortal } from 'react-dom';

import { createStoreContext } from '../../utils';

export interface ContainerContextValue {
  headerElement?: HTMLElement | null;
  footerElement?: HTMLElement | null;
  useHideContainer: (isHidden: boolean) => void;
  isHidden: boolean;
}

export const [ContainerContext, useContainerContext] =
  createStoreContext<ContainerContextValue>(
    (set) => ({
      useHideContainer: (isHidden) => {
        useEffect(() => {
          set({ isHidden });
        }, [isHidden]);
      },
      isHidden: false,
    }),
    {
      name: 'ContainerContext',
      errorMessage:
        'useContainerContext: "ContainerContext" is undefined. Seems you forgot to wrap component within the Provider',
    },
  );

export const HideContainerContentHandler = ({
  children,
}: PropsWithChildren) => {
  const isHidden = useContainerContext((store) => store.isHidden);

  return <Box {...(isHidden && { display: 'none' })}>{children}</Box>;
};

export const ContainerHeader = ({
  children: childrenProp,
  ...props
}: GridProps) => {
  const container = useContainerContext((store) => store.headerElement);
  const children = (
    <Grid w="full" {...props}>
      {childrenProp}
    </Grid>
  );

  if (container) {
    return createPortal(children, container);
  }

  return children;
};

export const ContainerFooter = ({
  children: childrenProp,
  ...props
}: GridProps) => {
  const container = useContainerContext((store) => store.footerElement);

  const children = (
    <Grid p="6" w="full" {...props}>
      {childrenProp}
    </Grid>
  );

  if (container) {
    return createPortal(children, container);
  }

  return children;
};
