import {
  Avatar,
  Button,
  Checkbox,
  IconButton,
  ModalCloseButton,
  Select,
} from '@chakra-ui/react';
import { CaretDown, CaretLeft, User } from '@phosphor-icons/react';
import { Trans } from 'react-i18next';

import { CheckboxIcon, Spinner } from '../components';
import { i18nInterpolationVariables } from './i18n-interpolation';

export default () => {
  Button.defaultProps = {
    spinner: <Spinner />,
  };

  Checkbox.defaultProps = {
    icon: <CheckboxIcon />,
  };

  Select.defaultProps = {
    icon: <CaretDown />,
  };

  ModalCloseButton.defaultProps = {
    children: <CaretLeft />,
  };

  Avatar.defaultProps = {
    icon: <User weight="light" size="50%" />,
  };

  IconButton.defaultProps = {
    isRound: true,
    padding: '0 !important',
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  Trans.defaultProps = { components: i18nInterpolationVariables };
};
