import { useState } from 'react';

import { FocusLock } from '@chakra-ui/focus-lock';
import {
  Flex,
  IconButton,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  useControllableState,
} from '@chakra-ui/react';
import { ArrowLineDown, X } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';

import { PreviewModalBody } from './preview-modal-body';

export interface FileToPreview {
  fileName: string;
  src: string;
}

export interface FilesPreviewModalProps {
  isOpen: boolean;
  onClose: () => void;
  files: FileToPreview[];
  fileIndex?: number;
  defaultFileIndex?: number;
  onFileIndexChange?: (newIndex: number) => void;
}

export const FilesPreviewModal = ({
  isOpen,
  onClose,
  files,
  fileIndex,
  defaultFileIndex,
  onFileIndexChange,
}: FilesPreviewModalProps) => {
  const [t] = useTranslation();
  const [error, setError] = useState<Error>();
  const [visibleFileIndex, setVisibleFileIndex] = useControllableState({
    value: fileIndex,
    defaultValue: defaultFileIndex || 0,
    onChange: onFileIndexChange,
  });

  const file = files.at(visibleFileIndex);

  const isDownloadButtonVisible = !error && file;

  return (
    <Modal
      size={{ mobile: 'full', desktop: '6xl' }}
      isOpen={isOpen}
      onClose={() => {
        onClose();
        setError(undefined);
      }}
      scrollBehavior="inside"
      isCentered
      motionPreset="none"
    >
      <FocusLock>
        <ModalOverlay backdropFilter="blur(8px)" />
        <ModalContent
          mt="112px"
          bgColor="transparent"
          shadow="unset"
          maxW={{ desktop: '90vw' }}
          w={{ mobile: 'full', desktop: 'unset !important' }}
          mb="24"
        >
          <PreviewModalBody
            totalFilesCount={files.length}
            file={file}
            setNewFileIndex={setVisibleFileIndex}
            error={error}
            onErrorChange={setError}
          />
          <Flex
            position="fixed"
            gap="4"
            top="4"
            right="4"
            left="4"
            filter="drop-shadow(0px 0px 1px black)"
            color="white"
            align="flex-start"
          >
            <Text
              flex="1"
              minW="0"
              textStyle="bodyLarge"
              pl={{ desktop: 28 }}
              textAlign={{ desktop: 'center' }}
            >
              {visibleFileIndex + 1}/{files.length} {file?.fileName}
            </Text>
            {isDownloadButtonVisible && (
              <IconButton
                as="a"
                size="md"
                variant="link"
                color="white"
                href={file?.src}
                download
                aria-label={t('download')}
                icon={<ArrowLineDown />}
              />
            )}
            <IconButton
              size="md"
              variant="link"
              color="white"
              onClick={onClose}
              aria-label={t('close')}
              icon={<X />}
            />
          </Flex>
        </ModalContent>
      </FocusLock>
    </Modal>
  );
};
