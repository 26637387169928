import { Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { useWeekdayWithTimeFormatTimestamp } from '../../../hooks';
import { ChatSystemMessage } from '../chat.types';
import { MessageDivider } from './message-divider';

interface MessageSystemProps {
  message: ChatSystemMessage;
}

export const MessageSystem = ({ message }: MessageSystemProps) => {
  const [t] = useTranslation();

  const formatTimestamp = useWeekdayWithTimeFormatTimestamp();

  return (
    <Flex
      flexDir="column"
      sx={{
        '&:not(:last-child)': {
          mb: '9',
        },
      }}
    >
      <Text textStyle="labelSmall" color="primary.default" textAlign="center">
        {t('systemNotificationWithDate', {
          date: formatTimestamp(message.createdAt),
        })}
      </Text>
      <MessageDivider>{message.text}</MessageDivider>
    </Flex>
  );
};
