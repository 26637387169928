import { useState } from 'react';

import { Flex, forwardRef } from '@chakra-ui/react';

export const InputLikeDiv: typeof Flex = forwardRef((props, ref) => {
  const [hasFocus, setHasFocus] = useState(false);

  return (
    <Flex
      bg="background"
      gap="2"
      py="2.5"
      px="4"
      wrap="wrap"
      borderRadius="base"
      border="2px"
      borderColor="transparent"
      _hover={{ borderColor: 'primary.default' }}
      _invalid={{
        borderColor: 'red.default',
      }}
      _focus={{
        borderColor: 'primary.default',
      }}
      _readOnly={{
        color: 'text.grey',
        pointerEvents: 'none',
        _hover: {
          borderColor: 'transparent',
        },
      }}
      {...(hasFocus && {
        'data-focus': true,
      })}
      {...props}
      onFocus={(e) => {
        setHasFocus(true);
        props.onFocus?.(e);
      }}
      onBlur={(e) => {
        setHasFocus(false);
        props.onBlur?.(e);
      }}
      ref={ref}
    />
  );
});
