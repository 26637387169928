import type { ComponentStyleConfig } from '@chakra-ui/theme';

const Divider: ComponentStyleConfig = {
  baseStyle: {
    bg: 'grey.shade',
    opacity: 1,
  },
};

export default Divider;
