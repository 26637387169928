import { Flex, useFormControlContext, useRadioGroup } from '@chakra-ui/react';
import { Controller, useFormContext } from 'react-hook-form';

import { ProjectRatingRadio } from './project-rating-radio';

export interface ProjectRatingRadioGroupProps {
  name: string;
}

const RATING_LENGTH = 10;
export const ratings = Array.from({ length: RATING_LENGTH }, (_, i) =>
  String(i + 1),
);

export const ProjectRatingRadioGroup = ({
  name,
}: ProjectRatingRadioGroupProps) => {
  const {
    formState: { defaultValues },
  } = useFormContext();

  const { isReadOnly } = useFormControlContext();

  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    defaultValue: defaultValues?.[name],
  });

  const group = getRootProps();

  return (
    <Controller
      name={name}
      render={({ field }) => {
        return (
          <Flex
            columnGap="2"
            rowGap="5"
            wrap="wrap"
            maxW={{ mobile: '262px', desktop: '532px' }}
            mx="auto"
            {...group}
            {...field}
          >
            {ratings.map((value) => {
              return (
                <ProjectRatingRadio
                  key={value}
                  isReadOnly={isReadOnly}
                  {...getRadioProps({ value })}
                >
                  {value}
                </ProjectRatingRadio>
              );
            })}
          </Flex>
        );
      }}
    />
  );
};
