import Accordion from './accordion';
import Alert from './alert';
import { avatarTheme } from './avatar';
import Button from './button';
import Card from './card';
import Checkbox from './checkbox';
import Divider from './divider';
import Drawer from './drawer';
import Form from './form';
import FormError from './form-error';
import FormLabel from './form-label';
import Heading from './heading';
import Input from './input';
import Modal from './modal';
import Popover from './popover';
import Radio from './radio';
import Select from './select';
import Table from './table';
import Tabs from './tabs';
import Tag from './tag';
import Textarea from './textarea';

const components = {
  Heading,
  Input,
  Button,
  Accordion,
  Checkbox,
  Modal,
  Textarea,
  FormLabel,
  FormError,
  Form,
  Select,
  Tag,
  Table,
  Radio,
  Tabs,
  Card,
  Divider,
  Popover,
  Avatar: avatarTheme,
  Alert,
  Drawer,
  NumberInput: Input,
};

export default components;
