import {
  InfiniteData,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';

import {
  GetMessagesResponse,
  UpdateReadProgressRequest,
} from '@proptly/chat-shared';
import { ApiResponse, chatClient } from '@proptly/ui';

export const updateChatReadProgress = (
  token: string,
  chatId: string,
  payload: UpdateReadProgressRequest,
) => {
  return chatClient
    .patch<ApiResponse<undefined>>(
      `/public/chat/${chatId}/message/${token}/read-progress`,
      payload,
    )
    .then((resp) => resp.data.data);
};

interface MutationVariables extends UpdateReadProgressRequest {
  chatId: string;
}

export const useUpdateChatReadProgressMutation = (token: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ chatId, ...payload }: MutationVariables) =>
      updateChatReadProgress(token, chatId, payload),
    onSuccess(_, { chatId, lastTimeRead }) {
      queryClient.setQueriesData<InfiniteData<GetMessagesResponse[]>>(
        { queryKey: ['chat', chatId, 'messages'] },
        (infiniteData) => {
          if (!infiniteData || infiniteData.pages.length === 0) {
            return infiniteData;
          }

          const updatedData = infiniteData.pages.map((page) =>
            page.map((message) => {
              if (new Date(message.createdAt) > lastTimeRead) {
                return message;
              }

              return {
                ...message,
                wasRead: true,
              };
            }),
          );

          return {
            pages: updatedData,
            pageParams: infiniteData.pageParams,
          };
        },
      );
    },
  });
};
