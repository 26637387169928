import { useEffect, useRef } from 'react';

export const useScrollByDragging = () => {
  const isDraggingRef = useRef(false);

  const containerRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    const setDraggingOff = () => {
      isDraggingRef.current = false;
    };

    const onMouseMove = (e: MouseEvent) => {
      e.preventDefault();
      if (!isDraggingRef.current) {
        return;
      }

      const container = containerRef.current;

      if (!container) {
        return;
      }

      if (e.movementX) {
        container.scrollBy({
          left: -e.movementX,
        });
      }

      if (e.movementY) {
        container.scrollBy({
          top: -e.movementY,
        });
      }
    };

    document.addEventListener('mouseup', setDraggingOff);
    document.addEventListener('mousemove', onMouseMove);

    return () => {
      document.removeEventListener('mouseup', setDraggingOff);
      document.removeEventListener('mousemove', onMouseMove);
    };
  }, []);

  useEffect(() => {
    const container = containerRef.current;

    if (!container) {
      return;
    }

    const onMouseDown = (e: MouseEvent) => {
      e.preventDefault();
      isDraggingRef.current = true;
    };

    container.addEventListener('mousedown', onMouseDown);

    return () => {
      container.removeEventListener('mousedown', onMouseDown);
    };
  }, []);

  return { containerRef };
};
