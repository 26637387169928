import { ComponentStyleConfig } from '@chakra-ui/react';

import { getTypography } from '../utils/typography';

const Form: ComponentStyleConfig = {
  baseStyle(props) {
    return {
      container: {
        mb: 2,
      },
      helperText: {
        ...getTypography('labelMedium', props.theme),
      },
    };
  },
};

export default Form;
