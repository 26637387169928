import { Flex, Spinner } from '@chakra-ui/react';

export const PreviewLoader = ({ height }: { height?: string }) => (
  <Flex
    h={height || '50vh'}
    w="50vw"
    color="white"
    m="auto"
    overflow="hidden"
    justify="center"
    align="center"
  >
    <Spinner />
  </Flex>
);
