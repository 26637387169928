import { useEffect } from 'react';

import { useCallbackRef } from '@chakra-ui/react';

export const useArrowNavigation = (prev: () => void, next: () => void) => {
  const prevRef = useCallbackRef(prev);
  const nextRef = useCallbackRef(next);

  useEffect(() => {
    const handleKeyUp = (e: KeyboardEvent) => {
      if (e.key === 'ArrowLeft') {
        prevRef();
      }
      if (e.key === 'ArrowRight') {
        nextRef();
      }
    };

    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [nextRef, prevRef]);
};
