import { useMutation, useQueryClient } from '@tanstack/react-query';

import { CreateFeedbackMessagePayload } from '@proptly/contractor-contract-classes';
import { contractorClient } from '@proptly/ui';

export function createFeedbackMessage(
  token: string,
  data: CreateFeedbackMessagePayload,
) {
  return contractorClient.post(`feedback/${token}/message`, data);
}

interface MutationVariables extends CreateFeedbackMessagePayload {
  token: string;
}

export function useCreateFeedbackMessageMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ token, ...data }: MutationVariables) =>
      createFeedbackMessage(token, data),

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['feedback', 'messages'] });
    },
  });
}
