import { useMemo } from 'react';

import { Box, Flex } from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';

import { isEmptyOrWhitespace } from '@proptly/shared';
import {
  Chat,
  getErrorId,
  queryClient,
  useChatFilesStore,
  useMapFilesToPreview,
  useMergePagedMessages,
} from '@proptly/ui';

import {
  useCreateMessageMutation,
  useUpdateChatReadProgressMutation,
} from '../../api/chat';
import { uploadFileAndTrackProgress } from '../../api/project-feedback/upload-file-v2';
import { useGetTimelineInfiniteQuery } from '../../api/timeline';
import {
  useChatMessages,
  useProjectChatId,
  useProjectChatMessageEndOfList,
  useProjectChatParsedMessages,
  useProjectChatPermissions,
  useProjectToken,
} from '../../hooks';
import { systemMessagesParserCLIENT_CE } from '../../hooks/project/use-timeline-system-messages';
import { CustomerPortalChatMessageAttachmentsRenderer } from '../customer-portal-chat-message-attachments-renderer';
import { ProjectChatEmpty } from './project-chat-empty';

const timelineEventTypes = systemMessagesParserCLIENT_CE.eventTypes;

export const ProjectChatMessages = () => {
  const token = useProjectToken();
  const chatId = useProjectChatId();

  const files = useChatFilesStore((store) => store.files);
  const openAddFilesDialog = useChatFilesStore((store) => store.onOpen);
  const removeFile = useChatFilesStore((store) => store.removeFile);
  const clearFiles = useChatFilesStore((store) => store.clearFiles);
  const filesPreview = useMapFilesToPreview(files);

  const systemMessageParser = systemMessagesParserCLIENT_CE;

  const timeline = useGetTimelineInfiniteQuery(token, {
    pageSize: 100,
    types: timelineEventTypes,
  });

  const timelineEntries = useMemo(() => {
    return timeline.data?.pages.flatMap((page) => page.values) ?? [];
  }, [timeline.data]);

  const systemMessages = systemMessageParser.useMessages(timelineEntries, {});

  const { mutateAsync: createMessage } = useCreateMessageMutation();

  const { mutate: updateChatReadProgress } =
    useUpdateChatReadProgressMutation(token);

  const messagesResponse = useChatMessages({
    pageSize: 10,
  });

  const { canChatWithContractor } = useProjectChatPermissions();
  const isInputVisible = canChatWithContractor;

  const userMessages = useProjectChatParsedMessages(messagesResponse.messages);
  const newestUserMessage = userMessages.flat().at(-1);
  const oldestUserMessage = userMessages.flat().at(0);

  const messages = useMergePagedMessages({
    userMessages,
    systemMessages,
    hasMoreUserMessages: !!messagesResponse.hasNextPage,
  });

  const _handleCreateMessage = async (text: string) => {
    try {
      const attachments = await Promise.all(
        files.map((file) =>
          uploadFileAndTrackProgress({ file, token }).then((fileId) => ({
            fileId: fileId.value,
          })),
        ),
      );

      if (attachments.length || !isEmptyOrWhitespace(text)) {
        await createMessage({
          chatId,
          text,
          attachments,
          token,
        }).then(() => clearFiles());
      }
    } catch (error) {
      if (getErrorId(error) === 'CHAT_ERROR.THREAD_IS_CLOSED') {
        queryClient.invalidateQueries({
          queryKey: ['public', 'chat', 'permissions'],
        });
        queryClient.invalidateQueries({
          queryKey: ['public', 'project', token],
        });

        return;
      }

      throw error;
    }
  };

  const { mutateAsync: handleCreateMessage, isPending: isLoading } =
    useMutation({
      mutationFn: _handleCreateMessage,
    });

  const {
    shouldRender: shouldRenderEndOfListElement,
    Component: EndOfListElement,
  } = useProjectChatMessageEndOfList();

  const hasNoUserMessages = !newestUserMessage;

  const hasEmptyMessagesFallback = isInputVisible && hasNoUserMessages;

  return (
    <>
      <Chat.MessageList
        messages={messages}
        emptyMessagesFallback={<ProjectChatEmpty />}
        hasEmptyMessagesFallback={hasEmptyMessagesFallback}
        shouldRenderEndOfListElement={shouldRenderEndOfListElement}
        endOfListElement={EndOfListElement}
        AttachmentsRenderer={CustomerPortalChatMessageAttachmentsRenderer}
        bg="white"
        px={{ base: '6', lg: '10' }}
        pt={{ base: '6', lg: '10' }}
        pb={{
          base: !isInputVisible ? '6' : '0',
          lg: !isInputVisible ? '10' : '0',
        }}
        onLoadMore={messagesResponse.fetchNextPage}
        hasMore={messagesResponse.hasNextPage}
        currentPageKey={oldestUserMessage?.id}
        {...(newestUserMessage && {
          onFirstUnreadMessageVisible: () =>
            updateChatReadProgress({
              chatId,
              lastTimeRead: newestUserMessage?.createdAt,
            }),
        })}
      />
      {isInputVisible && (
        <Box bg="white" p={{ base: '6', lg: '10' }}>
          <Flex gap="2" align="end">
            <Chat.AttachmentButton
              isDisabled={isLoading}
              onClick={openAddFilesDialog}
            />
            <Chat.Input
              chatId={chatId}
              userId={token}
              onMessageAdded={handleCreateMessage}
              files={filesPreview}
              onFileRemove={removeFile}
            />
          </Flex>
        </Box>
      )}
    </>
  );
};
