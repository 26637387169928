import type { ComponentStyleConfig } from '@chakra-ui/theme';

import { getTypography } from '../utils/typography';

const Table: ComponentStyleConfig = {
  baseStyle: {
    th: {
      textTransform: 'none',
      letterSpacing: 0,
    },
  },
  variants: {
    default(props) {
      return {
        table: {
          borderCollapse: 'separate',
          color: 'grey.dark',
          borderSpacing: '0 8px',
        },
        tr: {
          bg: 'white',
        },
        th: {
          ...getTypography('labelMedium', props.theme),
          p: 4,
        },
        tbody: {
          tr: {
            '&.link': {
              cursor: 'pointer',
            },
          },
        },
        td: {
          borderY: '1px',
          borderColor: 'transparent',
          _last: {
            borderRight: '1px solid transparent',
          },
          _groupActive: {
            color: 'primary.default',
          },
          _groupHover: {
            borderColor: 'primary.default',
          },

          ...getTypography('body', props.theme),
          px: 4,
          py: 6,
          maxW: '200px',
          _first: {
            ...getTypography('bodyMedium', props.theme),
            color: 'black',
            minW: '350px',
            borderLeft: '1px solid transparent',
          },
        },
      };
    },
    default2(props) {
      return {
        table: {
          borderCollapse: 'separate',
          borderSpacing: '0 4px',
        },
        th: {
          ...getTypography('labelXS', props.theme),
          p: 2,
          whiteSpace: 'nowrap',
          _first: {
            pl: 4,
          },
        },
        tbody: {
          tr: {
            bg: 'white',
            '&.link': {
              cursor: 'pointer',
            },
            _first: {
              '& > td': {
                _first: {
                  borderTopLeftRadius: 'lg',
                },
                _last: {
                  borderTopRightRadius: 'lg',
                },
              },
            },
            _last: {
              '& > td': {
                _first: {
                  borderBottomLeftRadius: 'lg',
                },
                _last: {
                  borderBottomRightRadius: 'lg',
                },
              },
            },
          },
        },
        td: {
          borderY: '1px',
          borderColor: 'transparent',
          _last: {
            borderRight: '1px solid transparent',
          },
          _groupActive: {
            color: 'primary.default',
          },
          _groupHover: {
            borderColor: 'primary.default',
          },
          minW: '100px',
          ...getTypography('bodySmall', props.theme),
          pl: 2,
          pr: 4,
          py: 4,
          _first: {
            pl: 4,
            borderLeft: '1px solid transparent',
          },
        },
      };
    },
  },
  defaultProps: {
    variant: 'default',
  },
};

export default Table;
