/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo, useState } from 'react';

import { Box, Text, useDisclosure } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { isEmptyOrWhitespace } from '@proptly/shared';
import {
  Details,
  FileButton,
  FilesListWithPreview,
  FilesPreviewModal,
} from '@proptly/ui';

import {
  FileParamValue,
  getParamsFiles,
  useProjectFeedbackParams,
} from '../../hooks';

export const ProjectFeedbackDetails = () => {
  const [t] = useTranslation();
  const galleryDisclosure = useDisclosure();
  const [fileIndex, setFileIndex] = useState(0);

  const params = useProjectFeedbackParams();
  const files = useMemo(() => getParamsFiles(params), [params]);

  return (
    <>
      {params.map((param) => {
        const [key, value] = param;

        if (!value) {
          return null;
        }

        if (typeof value === 'string') {
          if (isEmptyOrWhitespace(value)) {
            return null;
          }

          return (
            <Details.Group key={key}>
              <Details.Label>{t(key as any)}</Details.Label>
              <Details.Value mb="0">{value}</Details.Value>
            </Details.Group>
          );
        }

        const onFileClick = (file: FileParamValue) => {
          const index = files.findIndex((f) => f.id === file.token);
          setFileIndex(index);
          galleryDisclosure.onOpen();
        };

        if (value._type === 'file') {
          return <FileButton file={value} key={key} onClick={onFileClick} />;
        }

        if (value._type === 'attachments') {
          return (
            <Box key={key}>
              <Text textStyle="titleLarge" mb="6">
                {t(key as any)}
              </Text>
              <FilesListWithPreview files={files} />
            </Box>
          );
        }

        return null;
      })}
      <FilesPreviewModal
        isOpen={galleryDisclosure.isOpen}
        onClose={galleryDisclosure.onClose}
        files={files}
        fileIndex={fileIndex}
        onFileIndexChange={setFileIndex}
      />
    </>
  );
};
