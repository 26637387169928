import { useState } from 'react';

import { Button, Flex } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FEEDBACK_COMMENT_MAX_LENGTH } from '@proptly/contractor-contract-enums';
import { FILE_MAX_UPLOAD_SIZE } from '@proptly/shared';
import {
  commonForm,
  CommonFormProps,
  FilesInputField,
  FormFieldControl,
  TextareaField,
} from '@proptly/ui';

export interface AcceptRejectFormData {
  comment: string;
  files: { file: File }[];
}

const defaultValue: AcceptRejectFormData = {
  comment: '',
  files: [],
};

export interface AcceptRejectFormProps
  extends CommonFormProps<
    AcceptRejectFormData,
    AcceptRejectFormData & { accept: boolean }
  > {
  withFiles?: boolean;
}

export const AcceptRejectFormFields = ({
  onSubmit,
  withFiles,
}: AcceptRejectFormProps) => {
  const [accept, setAccept] = useState(true);
  const form = useFormContext<AcceptRejectFormData>();
  const [t] = useTranslation();

  const { handleSubmit, formState } = form;

  const { isSubmitting } = formState;

  const handleBeforeSubmit = (accept: boolean) =>
    handleSubmit(async (values) => {
      setAccept(accept);
      await onSubmit?.({ ...values, accept });
    })();

  const handleAccept = () => handleBeforeSubmit(true);
  const handleReject = () => handleBeforeSubmit(false);

  const isAcceptLoading = isSubmitting && accept;
  const isRejectLoading = isSubmitting && !accept;

  return (
    <>
      <FormFieldControl name="comment" label={t('writeYourCommentHere')}>
        <TextareaField
          maxLength={FEEDBACK_COMMENT_MAX_LENGTH}
          minRows={4}
          name="comment"
        />
      </FormFieldControl>

      {withFiles && (
        <FormFieldControl name="files">
          <FilesInputField
            label={t('addAttachments')}
            maxSize={FILE_MAX_UPLOAD_SIZE}
            name="files"
          />
        </FormFieldControl>
      )}

      <Flex gap="3">
        <Button
          flex="1"
          isDisabled={isRejectLoading}
          isLoading={isAcceptLoading}
          onClick={handleAccept}
          colorScheme="success"
        >
          {t('accept')}
        </Button>

        <Button
          flex="1"
          isDisabled={isAcceptLoading}
          isLoading={isRejectLoading}
          onClick={handleReject}
          colorScheme="error"
        >
          {t('reject')}
        </Button>
      </Flex>
    </>
  );
};

export const AcceptRejectForm = commonForm<
  AcceptRejectFormData,
  AcceptRejectFormData & { accept: boolean },
  AcceptRejectFormProps
>(AcceptRejectFormFields, { defaultValue });
