import type { ComponentStyleConfig } from '@chakra-ui/theme';

import { getTypography } from '../utils';
import { filledInputOverride, outlineInputOverride } from './common-input';

const Select: ComponentStyleConfig = {
  defaultProps: {
    variant: 'filled',
    size: 'lg',
  },
  baseStyle: {
    field: {
      _disabled: {
        opacity: 1,
      },
    },
  },
  variants: {
    oversimplified: {
      field: {
        height: 'unset',
        cursor: 'pointer',
        pl: 1,
        border: '1px',
        borderColor: 'transparent',
        _hover: {
          borderColor: 'primary.default',
        },
        _focusVisible: {
          borderColor: 'primary.default',
        },
      },
    },
    outline: {
      field: {
        ...outlineInputOverride,
      },
    },
    filled: {
      field: {
        ...filledInputOverride,
      },
      icon: {
        color: 'primary.default',
        _disabled: {
          color: 'text.grey',
        },
      },
    },
    readOnly: {
      element: {
        color: 'grey.default',
      },
      field: {
        color: 'grey.default',
        border: '1px dashed',
      },
    },
  },
  sizes: {
    lg(props) {
      return {
        field: {
          ...getTypography('body', props.theme),
        },
        icon: {
          fontSize: '24px',
          right: 4,
        },
      };
    },
  },
};

export default Select;
