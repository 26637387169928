type NullToUndefined<T> = T extends null ? never : T | undefined;

export type Pruned<T extends Record<string, unknown>> = {
  [P in keyof T]: T[P] extends null ? never : NullToUndefined<T[P]>;
};

export const prune = <T extends Record<string, unknown>>(
  value: T,
): Pruned<T> => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  return Object.fromEntries(
    Object.entries(value).filter(([, value]) => value != null),
  );
};
