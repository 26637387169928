import { ReactElement, ReactNode } from 'react';

import { MessageAttachmentDto } from '@proptly/chat-shared';

import { MessageVariant } from './message/message-text';

export enum ChatUserRole {
  Client = 'CLIENT',
  ContractorEmployee = 'CONTRACTOR_EMPLOYEE',
  PreviousContractorEmployee = 'PREVIOUS_CONTRACTOR_EMPLOYEE',
  PreviousRoutingOperator = 'PREVIOUS_ROUTING_OPERATOR',
  RoutingOperator = 'ROUTING_OPERATOR',
}

export interface ChatUser {
  id: string;
  fullName: string;
  role: ChatUserRole;
  organisation?: string | null;
  unreadMessageCount?: number;
  isRoleVisible?: boolean;
  avatar?: ReactElement;
}

export interface MessageCenterChatOwnerUser {
  id: string;
  fullName: string;
  unreadMessageCount: number;
}

export type MessagePlacement = 'left' | 'right';
export type MessageFile = {
  id: string;
  src: string;
  fileName: string;
  fileSize: number;
  fileType: string | undefined;
};

export type ChatUserMessage = {
  __type: 'UserMessage';
  id: string;
  createdAt: Date;
  chatUserId: string;
  useDetails: () => {
    author: ChatUser;
    isUnread: boolean;
    placement: MessagePlacement;
    variant: MessageVariant;
  };
  isUnreadRaw: boolean;
  text?: string;
  attachments?: MessageAttachmentDto[] | null;
};

export type ChatSystemMessage = {
  __type: 'SystemMessage';
  id: string;
  createdAt: Date;
  text: string;
};

export type ChatMessage = ChatUserMessage | ChatSystemMessage;

export interface ChatGroupParticipation {
  chatId: string;
  participantFullName: string;
  participantOrganisation: string | null;
  lastMessageTimestamp: Date | null;
  hasUnreadMessage: boolean;
}

export type AttachmentsRendererProps = Pick<ChatUserMessage, 'attachments'> & {
  placement: MessagePlacement;
};

export type AttachmentsRenderer = (
  props: AttachmentsRendererProps,
) => ReactNode;
