import { ReactElement } from 'react';

import { Avatar, Flex, FlexProps, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { ns } from '@proptly/locale';

import { useFormatTime } from '../../../hooks';
import { Dot } from '../../atoms';
import { ChatUserRole, MessagePlacement } from '../chat.types';

const RowFlex = ({
  placement,
  children,
  ...props
}: { placement: MessagePlacement } & FlexProps) => (
  <Flex
    align="center"
    gap="2"
    flexDir={placement === 'left' ? 'row' : 'row-reverse'}
    {...props}
  >
    {children}
  </Flex>
);

interface MessageAuthorProps extends Omit<FlexProps, 'role'> {
  fullName: string;
  createdAt: Date;
  placement: MessagePlacement;
  role: ChatUserRole;
  isRoleVisible?: boolean;
  avatar?: ReactElement;
}

export const MessageAuthor = ({
  avatar,
  fullName,
  role,
  createdAt,
  placement,
  isRoleVisible = true,
  ...props
}: MessageAuthorProps) => {
  const [et] = useTranslation(ns.Enums);

  const formatTime = useFormatTime();
  const createdAtTime = formatTime(createdAt);

  return (
    <RowFlex placement={placement} {...props}>
      {avatar || <Avatar variant="user" h="10" w="10" />}

      <RowFlex placement={placement} align="baseline">
        <RowFlex
          placement={placement}
          gap="1"
          fontSize={{ mobile: '12px', desktop: '14px' }}
          fontWeight="500"
        >
          {isRoleVisible && (
            <>
              <Text>{et(`chatUserRole.${role}`)}</Text>
              <Dot />
            </>
          )}

          <Text>{fullName}</Text>
        </RowFlex>

        <Text textStyle="labelSmall" color="grey.dark">
          {createdAtTime}
        </Text>
      </RowFlex>
    </RowFlex>
  );
};
