export type Environment =
  | 'test'
  | 'development'
  | 'staging'
  | 'beta'
  | 'production';

export function getEnv(): Environment {
  const hostname = window.location.hostname;

  if (hostname.includes('localhost')) {
    return 'development';
  }

  if (
    hostname.includes('staging.proptly.tech') ||
    hostname.includes('staging.proptly.no')
  ) {
    return 'staging';
  }

  if (hostname.includes('beta.proptly.no')) {
    return 'beta';
  }

  if (hostname.includes('test.proptly.no')) {
    return 'test';
  }

  return 'production';
}
