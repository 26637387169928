import { Box, FlexProps, Grid, GridProps } from '@chakra-ui/react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { HorizontalScrollableContainer } from '../../molecules';
import { ChatItemLink } from './chat-item-link';

const ChatTabsLayout = ({ children, ...props }: GridProps) => {
  return (
    <Grid
      bg="white"
      border="1px solid"
      borderColor="grey.shade"
      rounded="md"
      px={{ mobile: '6', desktop: '10' }}
      pb={{ mobile: '6', desktop: '10' }}
      mt="28px"
      {...props}
    >
      {children}
    </Grid>
  );
};

const ChatTabList = ({ children, ...props }: FlexProps) => {
  return (
    <HorizontalScrollableContainer gap="4" mt="-28px" {...props}>
      {children}
    </HorizontalScrollableContainer>
  );
};

interface ChatTabPanelsProps extends GridProps {
  defaultPath?: string;
}

const ChatTabPanels = ({
  defaultPath,
  children,
  ...props
}: ChatTabPanelsProps) => {
  return (
    <Box mt="-28px" h="full" {...props}>
      <Routes>
        {children}
        {defaultPath && (
          <Route path="*" element={<Navigate to={defaultPath} replace />} />
        )}
      </Routes>
    </Box>
  );
};

export const ChatTabs = Object.assign(ChatTabsLayout, {
  TabList: ChatTabList,
  Tab: ChatItemLink,
  TabPanels: ChatTabPanels,
  TabPanel: Route,
});
