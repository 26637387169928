import { routingClient } from '../config';

export function getTenantLogoUrl(tenantSlugOrId: string, logoFileId?: string) {
  return routingClient.getUri({
    url: `tenant/${tenantSlugOrId}/logo`,
    params: {
      v: logoFileId,
    },
  });
}
