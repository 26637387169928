import { useSuspenseQuery } from '@tanstack/react-query';

import { ContractorRatingDto } from '@proptly/shared';
import { ApiResponse, contractorClient, getErrorStatus } from '@proptly/ui';

export const getContractorRating = (token: string) => {
  return contractorClient
    .get<ApiResponse<ContractorRatingDto>>(`/project/${token}/public/rating`)
    .then((resp) => resp.data.data);
};

export const useGetContractorRatingQuery = (token: string) => {
  return useSuspenseQuery({
    queryKey: ['project', token, 'rating'],
    queryFn: () => {
      return getContractorRating(token).catch((error) => {
        const status = getErrorStatus(error);

        if (typeof status === 'number' && status >= 500) {
          throw error;
        }

        return null;
      });
    },
  });
};
