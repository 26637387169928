import { GetUserDetailsResponse } from '@proptly/chat-shared';
import { OrganisationType } from '@proptly/organisation-structure-shared';

import { ChatUserRole } from '../../components/chat/chat.types';

interface GetChatUserRoleProps {
  chatUser: GetUserDetailsResponse;
  projectContractorId: string | null | undefined;
  projectAssigneeSupertokensUserId: string | null | undefined;
  orderRoutingOperatorSupertokensUserId?: string | null;
}

export const getChatUserRole = ({
  chatUser,
  projectContractorId,
  projectAssigneeSupertokensUserId,
  orderRoutingOperatorSupertokensUserId,
}: GetChatUserRoleProps): ChatUserRole => {
  const isClient =
    chatUser.organisationType === null || chatUser.supertokensUserId === null;

  if (isClient) {
    return ChatUserRole.Client;
  }

  const isContractorOrganisation =
    chatUser.organisationType === OrganisationType.Contractor;
  const isCurrentContractorOrganisation =
    chatUser.organisationId === projectContractorId;
  const isUserAssignedToProject =
    chatUser.supertokensUserId === projectAssigneeSupertokensUserId;

  const isRoutingOrganisation =
    chatUser.organisationType === OrganisationType.RoutingTenant;
  const isUserAssignedToOrder =
    chatUser.supertokensUserId === orderRoutingOperatorSupertokensUserId;

  if (isContractorOrganisation) {
    if (isCurrentContractorOrganisation) {
      if (isUserAssignedToProject) {
        return ChatUserRole.ContractorEmployee;
      }

      return ChatUserRole.PreviousContractorEmployee;
    }

    return ChatUserRole.PreviousContractorEmployee;
  }

  if (isRoutingOrganisation) {
    if (isUserAssignedToOrder) {
      return ChatUserRole.RoutingOperator;
    }

    return ChatUserRole.PreviousRoutingOperator;
  }

  throw new Error('[getChatUserRole] Cannot determine chat user role');
};
