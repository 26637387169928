import type { ComponentStyleConfig } from '@chakra-ui/theme';

const Card: ComponentStyleConfig = {
  parts: ['root'],
  baseStyle: {
    root: {
      display: 'flex',
      flexDir: 'column',
      bg: 'white',
      borderRadius: 'base',
    },
  },
  variants: {
    chat: {
      root: {
        minW: '375px',
        flex: 1,
        minH: { desktop: '500px' },
        h: { desktop: 'calc(var(--chakra-vh) - 220px)' },
      },
    },
  },
  sizes: {
    full: {
      root: {
        borderRadius: 'unset',
        position: 'fixed',
        top: 0,
        bottom: { mobile: 'mobileNavbar', desktop: 0 },
        left: 0,
        right: 0,
      },
    },
  },
};

export default Card;
