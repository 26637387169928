import { Box, BoxProps, Text } from '@chakra-ui/react';

import { SmartLinks } from '../../atoms';

export type MessageVariant =
  | 'grey-dark'
  | 'green-default'
  | 'background'
  | 'green-shade';

const variants: Record<
  MessageVariant,
  {
    color: string;
    bg: string;
  }
> = {
  'grey-dark': {
    color: 'white',
    bg: 'grey.dark',
  },
  'green-default': {
    color: 'background',
    bg: 'primary.default',
  },
  background: {
    color: 'grey.dark',
    bg: 'background',
  },
  'green-shade': {
    color: 'primary.dark',
    bg: 'primary.shade',
  },
};

interface MessageTextProps extends BoxProps {
  text: string;
  variant?: MessageVariant;
}

export const MessageText = ({
  text,
  variant = 'background',
  ...props
}: MessageTextProps) => {
  const variantStyle = variants[variant];

  return (
    <Box {...variantStyle} p="4" rounded="md" {...props}>
      <Text
        fontSize={{ mobile: '12px', desktop: '14px' }}
        lineHeight="1.7"
        overflowWrap="anywhere"
        whiteSpace="pre-line"
      >
        <SmartLinks>{text}</SmartLinks>
      </Text>
    </Box>
  );
};
