import { v4 as uuid } from 'uuid';

const actions: Record<
  string,
  {
    resolve: (value: unknown) => void;
    reject: (error: string) => void;
  }
> = {};

declare global {
  class ReactNativeWebView {
    static postMessage(message: string): void;
    static handleRNWebViewActionResponse(response: {
      actionId: string;
      payload?: unknown;
      error?: string;
    }): void;
  }
}

let isInitialized = false;
function initialize() {
  if (isInitialized) {
    return;
  }
  ReactNativeWebView.handleRNWebViewActionResponse = function (response) {
    const actionId = response?.actionId;

    if (actionId?.startsWith('action-')) {
      if (response.error) {
        actions[actionId]?.reject(response.error);
      } else {
        actions[actionId]?.resolve(response.payload);
      }
    }

    delete actions[actionId];
  };

  isInitialized = true;
}

export const sendWebViewAction = (action: {
  type: string;
  payload?: unknown;
}) => {
  initialize();

  const actionId = 'action-' + uuid();
  const promise = new Promise<unknown>((resolve, reject) => {
    actions[actionId] = { resolve, reject };
  });

  const message = JSON.stringify({ ...action, actionId });

  ReactNativeWebView.postMessage(message);

  return promise;
};

export type WebViewPlatform = 'ios' | 'android' | 'windows' | 'macos' | 'web';

export const getDevicePushToken = () =>
  sendWebViewAction({ type: 'GET_DEVICE_PUSH_TOKEN' }) as Promise<{
    platform: WebViewPlatform;
    token: string;
  }>;
