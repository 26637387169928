import { Button, ButtonProps, chakra } from '@chakra-ui/react';
import { Eye } from '@phosphor-icons/react';

import { FileIcon } from './file-icon';

export interface FileButtonProps<T extends { fileName: string; src?: string }>
  extends Omit<ButtonProps, 'onClick'> {
  file: T;
  onClick?: (file: T) => void;
}

export const FileButton = <T extends { fileName: string; src?: string }>({
  file,
  onClick,
  ...props
}: FileButtonProps<T>) => {
  return (
    <Button
      variant="ghost"
      gap="1"
      fontSize="sm"
      colorScheme="green"
      py="2"
      pl="2"
      pr="4"
      h="fit-content"
      justifyContent="flex-start"
      w="full"
      border="1px"
      borderColor="grey.shade"
      borderRadius="base"
      leftIcon={<FileIcon file={file} />}
      rightIcon={<Eye size={16} />}
      onClick={onClick && (() => onClick(file))}
      {...props}
    >
      <chakra.span
        mr="auto"
        whiteSpace="normal"
        wordBreak="break-all"
        textAlign="left"
        noOfLines={1}
        minW="0"
      >
        {file.fileName}
      </chakra.span>
    </Button>
  );
};
