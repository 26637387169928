/* eslint-disable react-hooks/exhaustive-deps */
import { DependencyList, useCallback, useEffect } from 'react';

interface SharedEffect<T = unknown> {
  count: number;
  subscription: T;
}

const sharedEffects: Record<string, SharedEffect> = {};

export function useSharedEffect<T>(
  effectId: string,
  effect: () => T,
  destructor?: (subscription: T) => void,
  deps?: DependencyList | undefined,
) {
  const dependencyList = [effectId, ...(deps || [])];

  const effectRef = useCallback(effect, dependencyList);
  const desctructorRef = useCallback(
    destructor || (() => undefined),
    dependencyList,
  );

  useEffect(() => {
    if (!sharedEffects[effectId]) {
      sharedEffects[effectId] = {
        count: 0,
        subscription: null,
      };
    }
    const sharedEffect = sharedEffects[effectId] as SharedEffect<T>;

    if (sharedEffect.count === 0) {
      const effectResult = effectRef();
      sharedEffect.subscription = effectResult;
    }
    sharedEffect.count++;

    return () => {
      sharedEffect.count--;
      if (sharedEffect.count === 0) {
        desctructorRef(sharedEffect.subscription);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [effectRef, desctructorRef, ...dependencyList]);
}
