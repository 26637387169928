import { useMemo, useRef } from 'react';

import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

import { TimelineEntryDto, TimelineEventType } from '@proptly/timeline-shared';

import { ChatSystemMessage } from '../chat';

export class SystemMessagesParserBuilder<TOptions> {
  private systemMessageProviders: SystemMessageProvider[] = [];

  add<
    TEventType extends TimelineEventType = TimelineEventType,
    TTFunction extends TFunction = TFunction,
  >(
    eventType: TEventType,
    getText: SystemMessageProvider<
      TEventType,
      TTFunction,
      TOptions & {
        timelineEntries: TimelineEntryDto[];
        currentIndex: number;
      }
    >['getText'],
  ) {
    this.systemMessageProviders.push({
      eventType,
      getText,
    });

    return this;
  }

  build() {
    const providersMap = new Map(
      this.systemMessageProviders.map((provider) => [
        provider.eventType,
        provider,
      ]),
    );

    const eventTypes = Array.from(providersMap.keys());

    const useMessages = (
      timelineEntries: TimelineEntryDto[],
      options: TOptions,
    ) => {
      const [t] = useTranslation();
      const optionsRef = useRef(options);
      optionsRef.current = options;

      return useMemo(() => {
        return timelineEntries.flatMap((timelineEntry, currentIndex) => {
          const systemMessageProvider = providersMap.get(
            timelineEntry.eventType,
          );

          if (!systemMessageProvider) {
            return [];
          }

          const text = systemMessageProvider.getText(t, timelineEntry, {
            ...optionsRef.current,
            currentIndex,
            timelineEntries,
          });

          if (!text) {
            return [];
          }

          const message: ChatSystemMessage = {
            __type: 'SystemMessage',
            createdAt: timelineEntry.timestamp,
            id: timelineEntry.id,
            text,
          };

          return message;
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [providersMap, timelineEntries, t]);
    };

    return { useMessages, eventTypes };
  }
}

export interface SystemMessageProvider<
  TEventType extends TimelineEventType = TimelineEventType,
  TTFunction extends TFunction = TFunction,
  TOptions = unknown,
> {
  eventType: TEventType;
  getText(
    t: TTFunction,
    timelineEntry: TimelineEntryDto & { eventType: TEventType },
    options: TOptions,
  ): string | null;
}

export const checkIsTimelineEntryAfterRerouting = (
  currentEntry: TimelineEntryDto,
  currentIndex: number,
  timelineEntries: TimelineEntryDto[],
) => {
  const previousEntires = timelineEntries.slice(0, currentIndex);
  const hadReroutingEntry = previousEntires.some(
    (entry) => entry.eventType === TimelineEventType.OrderRerouted,
  );
  if (hadReroutingEntry) {
    return true;
  }
  const nextEntry = timelineEntries[currentIndex + 1];

  return (
    !!nextEntry &&
    nextEntry.eventType === TimelineEventType.OrderRerouted &&
    nextEntry.timestamp === currentEntry.timestamp
  );
};
