import { ReactNode } from 'react';

import {
  chakra,
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormLabel,
} from '@chakra-ui/react';
import { Info } from '@phosphor-icons/react';
import kebabCase from 'lodash/kebabCase';
import { useFormContext } from 'react-hook-form';

import { useCommonFormContext } from '../functional/common-form';

export interface FormFieldControlProps extends Omit<FormControlProps, 'label'> {
  children: ReactNode;
  name: string;
  label?: ReactNode;
  htmlFor?: string;
}

export const FormFieldControl = ({
  children,
  name,
  label,
  htmlFor,
  ...props
}: FormFieldControlProps) => {
  const { getFieldState, formState } = useFormContext();
  const { isSubmitting } = formState;
  const { isReadOnly } = useCommonFormContext() || {};
  const { error } = getFieldState(name, formState);

  const isInvalid = !!error;
  const errorMessage = error?.root?.message || error?.message;

  return (
    <FormControl
      isReadOnly={isReadOnly || isSubmitting}
      isInvalid={isInvalid}
      {...props}
    >
      {label && (
        <FormLabel variant={props.variant} htmlFor={htmlFor}>
          {label}
        </FormLabel>
      )}
      {children}
      <FormErrorMessage alignItems="flex-start" pt="1">
        <Info size="1rem" weight="bold" />
        <chakra.span
          flex="1"
          mt="-1"
          data-testid={`${kebabCase(name)}-field-error`}
        >
          {errorMessage}
        </chakra.span>
      </FormErrorMessage>
    </FormControl>
  );
};
