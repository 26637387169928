import { Box, chakra, Flex } from '@chakra-ui/react';

export const UnreadDot = chakra(Box, {
  baseStyle: {
    w: '6px',
    h: '6px',
    borderRadius: 'full',
    bg: 'red.default',
    flexShrink: '0',
  },
});

export const UnreadDotWithCount = chakra(Flex, {
  baseStyle: {
    minW: '4',
    minH: '4',
    w: 'min-content',
    h: 'min-content',
    px: '1',
    py: '1',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '10px',
    lineHeight: '1',
    fontWeight: '500',
    bg: 'red.default',
    color: 'white',
    flexShrink: '0',
    borderRadius: 'full',
  },
});
