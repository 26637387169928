import { AxiosInstance } from 'axios';

import { NOTIFICATION_CLICK_TRACKING_IDENTIFIER_PARAM } from '@proptly/shared';

export const recordNotificationLinkClick = (
  client: AxiosInstance,
  clickTrackerId: string,
) =>
  client.post('notification/record', {
    [NOTIFICATION_CLICK_TRACKING_IDENTIFIER_PARAM]: clickTrackerId,
  });
