import { dinero } from 'dinero.js';

import {
  availableCurrenciesByCodeMap,
  Currency,
  CurrencyCode,
} from '@proptly/shared';

export const getDefaultCurrencyCode = (): CurrencyCode => 'NOK';

export const dineroFromFloat = ({
  amount: float,
  currency,
  scale = 0,
}: {
  amount: number;
  currency: Currency;
  scale?: number;
}) => {
  const factor = (currency.base as number) ** currency.exponent || scale || 0;
  const amount = Math.round(float * factor);

  return dinero({ amount, currency, scale });
};

export const getCurrencyByCode = (code?: string | null) => {
  if (!code) {
    return null;
  }

  const currency = availableCurrenciesByCodeMap.get(code);

  if (!currency) {
    throw new Error(`Currency ${code} not found`);
  }

  return currency;
};
