import { createContext } from '@chakra-ui/react-utils';

interface ConfirmModalContextValue {
  onConfirm: () => void;
  isLoading: boolean;
  footerElement: HTMLElement | null;
}

export const [ConfirmModalProvider, useConfirmModalContext] =
  createContext<ConfirmModalContextValue>({
    name: 'ConfirmModalContext',
  });
