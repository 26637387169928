import {
  ChatPermissions,
  GetChatsPermissionsResponse,
} from '@proptly/chat-shared';
import { ProjectDto } from '@proptly/contractor-contract-classes';

import { useGetPublicChatPermissionsOptionalQuery } from '../../api/chat';
import { useProject } from './use-project';
import { useProjectToken } from './use-project-token';

export const useProjectChatPermissions = () => {
  const project = useProject();
  const token = useProjectToken();

  const { data: chatWithContractor } = useGetPublicChatPermissionsOptionalQuery(
    token,
    project?.clientContractorChatId,
  );

  const isChatWithContractorAvailable = checkIsChatWithContractorAvailable(
    project,
    chatWithContractor?.at(0)?.permissions ?? [],
  );
  const canChatWithContractor = checkCanChatWithContractor(
    project,
    chatWithContractor?.at(0)?.permissions ?? [],
  );

  return {
    isChatWithContractorAvailable,
    canChatWithContractor,
  };
};

const checkIsChatWithContractorAvailable = (
  project: ProjectDto,
  permissions: GetChatsPermissionsResponse['permissions'],
): boolean => {
  return (
    !!project.clientContractorChatId &&
    permissions.includes(ChatPermissions.Read)
  );
};

const checkCanChatWithContractor = (
  project: ProjectDto,
  permissions: GetChatsPermissionsResponse['permissions'],
): boolean => {
  const hasChatAvailable = checkIsChatWithContractorAvailable(
    project,
    permissions,
  );
  const hasWritePermission = permissions.includes(ChatPermissions.Write);

  return hasChatAvailable && hasWritePermission;
};
