import { useMemo } from 'react';

import { GetMessagesResponse } from '@proptly/chat-shared';

export const useUniqueMessages = (
  messages: GetMessagesResponse[][],
): GetMessagesResponse[][] => {
  return useMemo(() => {
    const seenMessageIds: string[] = [];

    return messages.map((page) => {
      return page.filter((message) => {
        if (seenMessageIds.includes(message.id)) {
          return false;
        }
        seenMessageIds.push(message.id);

        return true;
      });
    });
  }, [messages]);
};
