import { ChatUser } from '../../components';

export const getChatUser = (users: ChatUser[], userId: string) => {
  const user = users.find((u) => u?.id === userId);

  if (!user) {
    throw new Error('[getChatUser] Cannot find user in chat participants list');
  }

  return user;
};
