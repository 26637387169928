import { useCallback } from 'react';

import { AxiosError } from 'axios';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ns } from '@proptly/locale';
import { isEmptyOrWhitespace } from '@proptly/shared';

import {
  ApiError,
  AssignFormContractorError,
  FileTooLargeError,
  UnsupportedFileTypeError,
  handleFormValidationError,
  isFormValidationError,
} from '../errors';
import { RecaptchaChallengeError, formatSize } from '../utils';
import { useToast } from './use-toast';

export const useSubmitErrorHandler = <
  TFieldValues extends FieldValues = FieldValues,
>(
  form?: UseFormReturn<TFieldValues>,
) => {
  const toast = useToast();
  const [ert] = useTranslation(ns.Errors);
  const { setError } = form || {};

  return useCallback(
    (error: unknown) => {
      if (error instanceof FileTooLargeError) {
        toast({
          title: ert('fileTooLarge'),
          description: ert('fileTooLargeDescription', {
            fileName: error.file.name,
            maxSize: formatSize(error.maxSize),
          }),
          status: 'error',
          duration: null,
        });

        return;
      }
      if (error instanceof UnsupportedFileTypeError) {
        toast({
          title: ert('canNotUploadFile'),
          description: ert('unsupportedFileTypeDescription', {
            fileName: error.file.name,
          }),
          status: 'error',
          duration: null,
        });

        return;
      }
      if (error instanceof AssignFormContractorError) {
        return;
      }

      if (setError && isFormValidationError<TFieldValues>(error)) {
        handleFormValidationError<TFieldValues>(setError, error);

        return;
      }
      if (error instanceof RecaptchaChallengeError) {
        return;
      }

      let title = 'Unknown Error';
      let description: string | undefined = undefined;

      if (typeof error === 'string' && !isEmptyOrWhitespace(error)) {
        title = error;
      }

      if (error instanceof Error) {
        title = error.name;
        description = error.message;
      }

      if (error instanceof AxiosError) {
        title = error.message;
        if (error.code) {
          description = ert(error.code, error.code) + '';
        } else {
          description = undefined;
        }

        const data = error.response?.data as ApiError | undefined;

        if (data?.error) {
          title = data.error;
          description = ert(data.message, data.message) + '';
        } else if (data?.message) {
          title = data.message;
          description = undefined;
        }
      }

      toast({
        title,
        description,
        status: 'error',
      });
    },
    [ert, toast, setError],
  );
};
