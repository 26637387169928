import { Flex, forwardRef, Skeleton, SkeletonText } from '@chakra-ui/react';

import { ratings } from './project-rating-radio-group';

export const ProjectRatingSectionSkeleton = forwardRef((_, ref) => {
  return (
    <Flex flexDir="column" gap="4" ref={ref}>
      <Flex
        columnGap="2"
        rowGap="5"
        wrap="wrap"
        maxW={{ mobile: '262px', desktop: '532px' }}
        mx="auto"
      >
        {ratings.map((_) => (
          <Skeleton borderRadius="4px" w="46px" h="38px" />
        ))}
      </Flex>

      <SkeletonText />
    </Flex>
  );
});
