import { useEffect, useRef, useState } from 'react';

export const useDebouncedValue = <T>(value: T, debounceMs: number) => {
  const [debouncedValue, setValue] = useState(value);

  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (isMounted.current) {
        setValue(value);
      }
    }, debounceMs);

    return () => {
      clearTimeout(timeout);
    };
  }, [value, debounceMs]);

  return debouncedValue;
};
