import { ReactEventHandler } from 'react';

import { Image, ImageProps } from '@chakra-ui/react';
import { ErrorBoundary, useErrorBoundary } from 'react-error-boundary';

import { isEmptyOrWhitespace } from '@proptly/shared';

import { proptlyLogo, proptlyLogoShort } from '../../images';

const commonProps: Partial<ImageProps> = {
  w: 'fit-content',
  h: 'fit-content',
  maxW: '200px',
  maxH: '60px',
  objectFit: 'contain',
};

interface ProptlyLogoProps extends Omit<ImageProps, 'src'> {
  variant?: 'short' | 'long';
}

export const ProptlyLogo = ({
  variant = 'long',
  ...props
}: ProptlyLogoProps) => {
  return (
    <Image
      maxW={variant === 'short' ? '30px' : '100px'}
      maxH={variant === 'short' ? '30px' : '50px'}
      objectFit="contain"
      {...props}
      src={variant === 'short' ? proptlyLogoShort : proptlyLogo}
    />
  );
};

export interface CompanyLogoProps extends Omit<ImageProps, 'src'> {
  src?: string;
}

export function TryCompanyLogo({ src, ...props }: CompanyLogoProps) {
  const { showBoundary } = useErrorBoundary();

  if (isEmptyOrWhitespace(src)) {
    return <ProptlyLogo {...props} />;
  }

  const handleError: ReactEventHandler = (e) => {
    showBoundary(e);
  };

  return <Image src={src} {...commonProps} {...props} onError={handleError} />;
}

export const CompanyLogo = (props: CompanyLogoProps) => {
  return (
    <ErrorBoundary fallback={<ProptlyLogo {...props} />}>
      <TryCompanyLogo {...props} />
    </ErrorBoundary>
  );
};
