import { useMutation, useQueryClient } from '@tanstack/react-query';

import { CreateRatingPayload } from '@proptly/contractor-contract-classes';
import { contractorClient } from '@proptly/ui';

export const createContractorMutation = (
  token: string,
  data: CreateRatingPayload,
) => {
  return contractorClient.post(`/project/${token}/public/rating`, data);
};

export const useCreateContractorRatingMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ token, ...data }: CreateRatingPayload & { token: string }) =>
      createContractorMutation(token, data),
    async onSuccess(_, { token }) {
      await queryClient.invalidateQueries({
        queryKey: ['project', token, 'rating'],
      });
    },
  });
};
