import type { ComponentStyleConfig } from '@chakra-ui/theme';

import { getTypography } from '../utils/typography';

const FormLabel: ComponentStyleConfig = {
  baseStyle(props) {
    return {
      ...getTypography('labelMedium', props.theme),
      py: 2,
      m: 0,
    };
  },
  variants: {
    readOnly: {
      color: 'grey.default',
      fontWeight: 400,
    },
  },
};

export default FormLabel;
