import { Fragment, ReactElement, ReactNode, Suspense, useMemo } from 'react';

import { chakra, Flex, FlexProps } from '@chakra-ui/react';
import groupBy from 'lodash/groupBy';

import { useFormatterWithAlt, useMediumFormatDate } from '../../../hooks';
import { MaintainScrollPosition, VisibilityObserver } from '../../functional';
import { AttachmentsRenderer, ChatMessage } from '../chat.types';
import { Message } from './message';
import { MessageDivider } from './message-divider';
import { MessageListSkeleton } from './message-skeletons';

const MessagesGroupContainer = chakra(Flex, {
  baseStyle: {
    flexDir: 'column',
    gap: 1,
  },
});

export interface MessageListProps extends FlexProps {
  messages: ChatMessage[][];
  emptyMessagesFallback?: ReactNode;
  hasMore?: boolean;
  onLoadMore?: () => void;
  endOfListElement?: ReactElement;
  shouldRenderEndOfListElement?: boolean;
  currentPageKey?: string;
  hasEmptyMessagesFallback?: boolean;
  AttachmentsRenderer?: AttachmentsRenderer;
  onFirstUnreadMessageVisible?: () => void;
}

export const MessageList = ({
  messages,
  emptyMessagesFallback,
  hasEmptyMessagesFallback,
  hasMore,
  onLoadMore,
  endOfListElement,
  shouldRenderEndOfListElement,
  currentPageKey,
  AttachmentsRenderer,
  onFirstUnreadMessageVisible,
  ...props
}: MessageListProps) => {
  const mediumFormatDate = useMediumFormatDate();
  const formatDateAlt = useFormatterWithAlt(mediumFormatDate);

  const groupedMessages = useMemo(() => {
    return messages.map((page) =>
      groupBy(page, (message) => formatDateAlt(message.createdAt)),
    );
  }, [messages, formatDateAlt]);

  const lastMessageId = messages.flat().at(-1)?.id;

  let prevMessageIsUnread = hasMore ? true : false;

  let prevGroupDate: string | null = null;

  const renderGroupedMessages = (
    groupMessages: ChatMessage[],
    readableDate: string,
    pageIndex: number,
  ) => {
    const showDate = prevGroupDate !== readableDate;
    prevGroupDate = readableDate;

    return (
      <Fragment key={readableDate}>
        {showDate && (
          <MessageDivider my={{ mobile: '6', desktop: '10' }}>
            {readableDate}
          </MessageDivider>
        )}
        <Flex flexDir="column" gap="1">
          {groupMessages.map((message, index) => {
            let isFirstUnreadMessage = false;
            if (message.__type === 'UserMessage') {
              const thisMessageIsUnread = message.isUnreadRaw;
              if (!prevMessageIsUnread && thisMessageIsUnread) {
                isFirstUnreadMessage = true;
              }
              prevMessageIsUnread = thisMessageIsUnread;
            }

            const getNextMessage = () => {
              const nextMessageInGroup = groupMessages[index + 1];

              if (nextMessageInGroup) {
                return nextMessageInGroup;
              }
              const firstMessageOnNextPage = messages.at(pageIndex + 1)?.at(0);

              if (
                firstMessageOnNextPage &&
                formatDateAlt(firstMessageOnNextPage.createdAt) === readableDate
              ) {
                return firstMessageOnNextPage;
              }

              return undefined;
            };

            const nextMessage = getNextMessage();

            return (
              <Message
                key={message.id}
                message={message}
                nextMessage={nextMessage}
                AttachmentsRenderer={AttachmentsRenderer}
                {...(isFirstUnreadMessage && {
                  onFirstTimeVisible: onFirstUnreadMessageVisible,
                })}
              />
            );
          })}
        </Flex>
      </Fragment>
    );
  };

  return (
    <MaintainScrollPosition
      // adding key here is necessary to update ref of element below
      key={lastMessageId}
    >
      <Flex
        flex="1"
        overflow="auto"
        flexDir="column-reverse"
        // adding key will rerender container, so it will scroll to bottom when new message appears
        key={lastMessageId}
        {...props}
      >
        <MessagesGroupContainer>
          {groupedMessages.map((page, pageIndex) => {
            const pageId = groupedMessages.length - pageIndex;

            return (
              <Suspense key={pageId} fallback={<MessageListSkeleton />}>
                {pageIndex === 0 && hasMore && (
                  <VisibilityObserver
                    key={currentPageKey}
                    onFirstTimeVisible={onLoadMore}
                  >
                    <MessageListSkeleton />
                  </VisibilityObserver>
                )}
                {Object.entries(page).map(([date, messages]) =>
                  renderGroupedMessages(messages, date, pageIndex),
                )}
              </Suspense>
            );
          })}

          {shouldRenderEndOfListElement && endOfListElement}
          {!shouldRenderEndOfListElement &&
            hasEmptyMessagesFallback &&
            emptyMessagesFallback}
        </MessagesGroupContainer>
      </Flex>
    </MaintainScrollPosition>
  );
};
