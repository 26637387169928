import typography from '../foundations/typography';

export type TextStyles = (typeof typography)['textStyles'];
export type TextStyle = TextStyles[keyof TextStyles];

export const getTypography = (
  textStyle: keyof TextStyles,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  theme: any,
): TextStyle => {
  return theme.textStyles[textStyle];
};
