import { useSuspenseQuery } from '@tanstack/react-query';

import { MessageDto } from '@proptly/shared';
import {
  ApiResponse,
  MESSAGES_REFETCH_INTERVAL,
  contractorClient,
} from '@proptly/ui';

export function getFeedbackMessages(token: string) {
  return contractorClient
    .get<ApiResponse<MessageDto[]>>(`feedback/${token}/message`)
    .then((resp) => resp.data.data);
}

export function useGetFeedbackMessagesQuery(token: string) {
  return useSuspenseQuery({
    queryKey: ['feedback', 'messages'] as const,
    queryFn: () => getFeedbackMessages(token),
    refetchInterval: MESSAGES_REFETCH_INTERVAL,
    retryDelay: MESSAGES_REFETCH_INTERVAL,
    retry: true,
  });
}
