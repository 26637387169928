import { ComponentType, ReactElement, useMemo } from 'react';

import { Flex } from '@chakra-ui/react';
import groupBy from 'lodash/groupBy';

import { FileTypes } from '@proptly/shared';

import { MessageFile, MessagePlacement } from '../chat.types';
import { MessageDocument } from './message-document';
import { MessageImage } from './message-image';
import { MessageVideo } from './message-video';

interface MessageMediaRendererProps {
  files: MessageFile[];
  placement: MessagePlacement;
}

type MediaComponentType = ComponentType<{ key: string; files: MessageFile[] }>;

interface MediaProps {
  extensions: string[];
  Component: MediaComponentType;
  groupedFiles: Record<string, MessageFile[]>;
}

export const MessageMediaRenderer = ({
  files,
  placement,
}: MessageMediaRendererProps) => {
  const groupedFiles = useMemo(() => groupBy(files, 'fileType'), [files]);

  const mediaElements = useMemo(() => {
    const renderMediaElements = ({
      extensions,
      Component,
      groupedFiles,
    }: MediaProps) =>
      extensions.reduce((acc: ReactElement[], ext) => {
        const groupFiles = groupedFiles[ext];
        if (groupFiles) {
          acc.push(<Component key={ext} files={groupFiles} />);
        }

        return acc;
      }, []);

    return [
      ...renderMediaElements({
        Component: MessageImage,
        extensions: FileTypes.imageExtensions,
        groupedFiles,
      }),
      ...renderMediaElements({
        Component: MessageVideo,
        extensions: FileTypes.videoExtensions,
        groupedFiles,
      }),
      ...renderMediaElements({
        Component: MessageDocument,
        extensions: [
          ...FileTypes.documentExtensions,
          ...FileTypes.compressedExtensions,
          ...FileTypes.audioExtensions,
        ],
        groupedFiles,
      }),
    ];
  }, [groupedFiles]);

  return (
    <Flex
      flexDir="column"
      gap="1"
      alignItems={placement === 'left' ? 'flex-start' : 'flex-end'}
    >
      {mediaElements}
    </Flex>
  );
};
