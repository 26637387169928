import { useState } from 'react';

import {
  Drawer,
  DrawerContent,
  DrawerContentProps,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerProps,
  Flex,
  ModalBodyProps,
} from '@chakra-ui/react';

import { useView } from '../../hooks';
import { DisclosureContainerProps } from '../../types';
import { CloseIconButton, SuspendedDrawerBody } from '../atoms';
import { ContainerContext, HideContainerContentHandler } from '../functional';

export interface DefaultDrawerProps
  extends DisclosureContainerProps,
    Omit<DrawerProps, keyof DisclosureContainerProps> {
  withCloseButton?: boolean;
  bodyProps?: ModalBodyProps;
  drawerContentProps?: DrawerContentProps;
}

export const DefaultDrawer = ({
  children,
  placement: placementProp,
  withCloseButton = true,
  bodyProps,
  drawerContentProps,
  ...props
}: DefaultDrawerProps) => {
  const { isAboveDesktop } = useView();

  const [headerContainer, setHeaderContainer] = useState<HTMLElement | null>(
    null,
  );
  const [footerContainer, setFooterContainer] = useState<HTMLElement | null>(
    null,
  );

  const placement = placementProp || (isAboveDesktop ? 'right' : 'bottom');

  return (
    <ContainerContext
      value={{
        headerElement: headerContainer,
        footerElement: footerContainer,
      }}
    >
      <Drawer placement={placement} size="sm" {...props}>
        <HideContainerContentHandler>
          <DrawerOverlay />

          <DrawerContent
            {...(placement === 'bottom' && { borderTopRadius: 'xl' })}
            {...drawerContentProps}
          >
            <DrawerHeader p="0" m="0" border="none" ref={setHeaderContainer}>
              {withCloseButton && (
                <Flex
                  pt="6"
                  px="4"
                  pb="0"
                  display="flex"
                  justifyContent="flex-end"
                >
                  <CloseIconButton onClick={props.onClose} />
                </Flex>
              )}
            </DrawerHeader>

            <SuspendedDrawerBody px="6" pt="6" pb="0" {...bodyProps}>
              {children}
            </SuspendedDrawerBody>

            <DrawerFooter p="0" m="0" border="none" ref={setFooterContainer} />
          </DrawerContent>
        </HideContainerContentHandler>
      </Drawer>
    </ContainerContext>
  );
};
