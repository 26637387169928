import { Flex, forwardRef, Skeleton } from '@chakra-ui/react';

import { MessagePlacement } from '../../chat.types';

export const MessageAttachmentsSkeleton = forwardRef(
  (props: { placement: MessagePlacement }, ref) => {
    return (
      <Flex flexDir="column" gap="1" flex="1" ref={ref} {...props}>
        <Flex gap="1" w="full" minW="30px">
          <Skeleton flex="1" h="138px" rounded="md" />
          <Skeleton flex="1" h="138px" rounded="md" />
        </Flex>

        <Skeleton
          w="70%"
          h="14"
          rounded="md"
          alignSelf={props.placement === 'left' ? 'flex-start' : 'flex-end'}
        />
      </Flex>
    );
  },
);
