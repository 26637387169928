import type { ComponentStyleConfig } from '@chakra-ui/theme';

const Popover: ComponentStyleConfig = {
  baseStyle: {
    popper: {
      zIndex: 'popover',
    },
    content: {
      shadow: 'popover',
    },
    header: {
      py: 4,
      px: 6,
    },
    footer: {
      py: 4,
      px: 6,
    },
  },
};

export default Popover;
