import { ChangeEventHandler, PropsWithChildren } from 'react';

import { VisuallyHiddenInput } from '@chakra-ui/react';

import { MAX_UPLOAD_FILES_COUNT } from '@proptly/shared';
import {
  checkFilesEqual,
  createStoreContext,
  getFileId,
  useSubmitErrorHandler,
} from '@proptly/ui';

import { uploadFiles } from '../../api/project-feedback';

export interface ProjectFeedbackMessagesStore {
  localFiles: File[];
  addLocalFiles: (files: File[]) => void;
  removeFile: (fileId: string) => void;
  clearFiles: () => void;
  onOpen: () => void;
  text: string;
  setText: (text: string) => void;
  inputElement: HTMLInputElement | null;
  setInputElement: (inputElement: HTMLInputElement | null) => void;
}

const [StoreProvider, useStoreContext] =
  createStoreContext<ProjectFeedbackMessagesStore>((set, get) => ({
    localFiles: [],
    projectFiles: [],
    removeFile: (fileId) => {
      set((store) => {
        return {
          localFiles: store.localFiles.filter(
            (file) => getFileId(file) !== fileId,
          ),
        };
      });
    },
    addLocalFiles: (newFiles) => {
      set((store) => {
        const prevFiles = store.localFiles;

        const uniqueNewFiles = newFiles.filter(
          (newFile) =>
            !prevFiles.some((existingFile) =>
              checkFilesEqual(existingFile, newFile),
            ),
        );

        return {
          localFiles: [...prevFiles, ...uniqueNewFiles],
        };
      });
    },
    clearFiles: () => set({ localFiles: [] }),
    text: '',
    setText: (text) => set({ text }),
    inputElement: null,
    setInputElement: (inputElement) => set({ inputElement }),
    onOpen: () => get().inputElement?.click(),
  }));

export const useMessagesStore = useStoreContext;

const MessagesFiles = () => {
  const addLocalFiles = useStoreContext((store) => store.addLocalFiles);
  const setInputElement = useStoreContext((store) => store.setInputElement);
  const submitErrorHandler = useSubmitErrorHandler();

  const handleFileAdded: ChangeEventHandler<HTMLInputElement> = (e) => {
    const fileList = e.target.files;
    if (!fileList) {
      return;
    }

    const newFiles = Array.from(fileList);

    addLocalFiles(newFiles);
    e.target.value = '';

    if (newFiles.length <= MAX_UPLOAD_FILES_COUNT) {
      try {
        uploadFiles(newFiles);
      } catch (error) {
        submitErrorHandler(error);
      }
    }
  };

  return (
    <VisuallyHiddenInput
      ref={setInputElement}
      multiple
      type="file"
      onChange={handleFileAdded}
    />
  );
};

export const MessagesStoreProvider = ({ children }: PropsWithChildren) => (
  <StoreProvider>
    {children}
    <MessagesFiles />
  </StoreProvider>
);
