import { SystemStyleObject } from '@chakra-ui/react';

export const filledInputOverride: SystemStyleObject = {
  bg: 'background',
  color: 'primary.default',
  _invalid: {
    borderColor: 'red.default',
  },
  _hover: {
    bg: 'background',
    borderColor: 'primary.default',
  },
  _focusVisible: {
    bg: 'background',
    borderColor: 'primary.default',
  },
  _readOnly: {
    color: 'text.grey',
    pointerEvents: 'none',
    _hover: {
      borderColor: 'transparent',
    },
  },
};

export const outlineInputOverride: SystemStyleObject = {
  bg: 'white',
  borderColor: 'grey.shade',
  _focusVisible: {
    borderColor: 'primary.default',
    shadow: '0px 0px 0px 1px var(--chakra-colors-primary-default)',
  },
};

export const checkboxAndRadioCommonControl: SystemStyleObject = {
  border: '1px',
  borderColor: 'grey.default',
  color: 'transparent',

  _hover: {
    bg: 'inherit',
  },
  _active: {
    borderColor: 'primary.default',
  },

  _invalid: {
    borderColor: 'red.default',
    _hover: {
      borderColor: 'red.default',
    },
  },

  _disabled: {
    bg: 'grey.shade',
    borderColor: 'grey.default',
    _hover: {
      bg: 'grey.shade',
      borderColor: 'grey.default',
    },
  },

  _indeterminate: {
    bg: 'inherit',
    borderColor: 'primary.default',
    color: 'primary.default',
    _hover: {
      bg: 'inherit',
      borderColor: 'primary.default',
    },
    _active: {
      borderColor: 'grey.default',
    },
    _invalid: {
      color: 'red.default',
    },
    _disabled: {
      borderColor: 'grey.default',
      color: 'grey.default',
    },
  },

  _checked: {
    borderColor: 'primary.default',
    color: 'primary.default',
    bg: 'inherit',
    _hover: {
      bg: 'inherit',
      borderColor: 'primary.default',
    },
    _active: {
      borderColor: 'grey.default',
    },
    _invalid: {
      color: 'red.default',
    },
    _disabled: {
      borderColor: 'grey.default',
      color: 'grey.default',
    },
  },
};

export const checkboxAndRadioCommonLabel: SystemStyleObject = {
  ml: 0,
  _disabled: {
    color: 'text.grey',
  },
  _checked: {
    color: 'primary.default',
    _disabled: {
      color: 'text.grey',
    },
  },
  _invalid: {
    color: 'red.default',
  },
};
