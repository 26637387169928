import { isToday, isTomorrow, isYesterday } from 'date-fns';

import { DateLike } from '@proptly/shared';

import { omitDatestring } from '../formatters';

export const getDateMeta = (date: DateLike) => {
  const _date = omitDatestring(date);

  return {
    isToday: isToday(_date),
    isYesterday: isYesterday(_date),
    isTomorrow: isTomorrow(_date),
  };
};
