import { useEffect } from 'react';

import * as Sentry from '@sentry/react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import { ENVIRONMENT } from '../config/constants';

export function initSentryReact(app: string) {
  const environment = ENVIRONMENT;
  Sentry.init({
    dsn: 'https://182cd06b7a5cb492008123fd081610f6@o4508205168525312.ingest.de.sentry.io/4508205178421328',
    enabled:
      environment === 'production' ||
      environment === 'beta' ||
      environment === 'staging',
    // release: hash TO BE ADDED,
    environment,
    initialScope: {
      tags: {
        app,
      },
    },
    integrations: [
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    // tracePropagationTargets: [/^\//, /^https:\/\/yourserver\.io\/api/],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}
