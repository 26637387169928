import { chakra } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

export const StyledLink = chakra(Link, {
  baseStyle: {
    color: 'primary.default',
    textDecor: 'underline',
    _hover: {
      color: 'primary.dark',
    },
  },
});
