import { DownloadUrlResponse } from '@proptly/file-shared';
import { ApiResponse, fileClient } from '@proptly/ui';

export function getPublicFileUrl(fileId: string, token: string) {
  return fileClient
    .get<ApiResponse<DownloadUrlResponse>>(
      `/public/file/${fileId}/download-url/${token}`,
    )
    .then((resp) => {
      return resp.data.data.url;
    });
}
