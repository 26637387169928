import { PropsWithChildren } from 'react';

import { useSharedEffect } from '../../hooks';
import { createStoreContext } from '../../utils';
import { FileToPreview, FilesPreviewModal } from './files-preview-modal';

interface FilePreviewContext {
  files: FileToPreview[] | null;
  openPreview: (files: FileToPreview[], index?: number) => void;
  closePreview: () => void;
  fileIndex: number;
  setFileIndex: (index: number) => void;
}

const [ContextProvider, useContext] = createStoreContext<FilePreviewContext>(
  (set) => ({
    files: null,
    fileIndex: 0,
    openPreview: (files, index = 0) =>
      files.length > 0 && set({ files, fileIndex: index }),
    closePreview: () => set({ files: null }),
    setFileIndex: (index) => set({ fileIndex: index }),
  }),
);

const GlobalFilesPreviewModal = () => {
  const closePreview = useContext((store) => store.closePreview);
  const files = useContext((store) => store.files);
  const fileIndex = useContext((store) => store.fileIndex);
  const setFileIndex = useContext((store) => store.setFileIndex);

  return (
    <FilesPreviewModal
      isOpen={!!files}
      files={files || []}
      onClose={closePreview}
      fileIndex={fileIndex}
      onFileIndexChange={setFileIndex}
    />
  );
};

export const FilesPreviewProvider = ({ children }: PropsWithChildren) => {
  return (
    <ContextProvider>
      {children}
      <GlobalFilesPreviewModal />
    </ContextProvider>
  );
};

export const useFilesPreview = () => {
  const closePreview = useContext((store) => store.closePreview);
  useSharedEffect('global-file-preview-cleanup', () => undefined, closePreview);

  return useContext((store) => store.openPreview);
};
