import { AxiosError } from 'axios';

import { ApiError } from '../errors';

export const getErrorId = (error: unknown) => {
  if (error instanceof AxiosError) {
    const data = error.response?.data as ApiError | undefined;

    return data?.message;
  }

  return;
};
