import { useQueryClient, useSuspenseQuery } from '@tanstack/react-query';

import {
  GetUserDetailsRequest,
  GetUserDetailsResponse,
} from '@proptly/chat-shared';
import { ApiResponse, chatClient } from '@proptly/ui';

export const getPublicChatUsers = (
  token: string,
  chatId: string,
  params: GetUserDetailsRequest,
) => {
  return chatClient
    .get<ApiResponse<GetUserDetailsResponse[]>>(
      `/public/chat/${chatId}/participant/${token}`,
      { params },
    )
    .then((resp) => resp.data.data);
};

interface QueryOptions {
  enabled?: boolean;
}

export const useGetPublicChatUsersQuery = (
  token: string,
  chatId: string,
  params: GetUserDetailsRequest,
  options?: QueryOptions,
) => {
  const queryClient = useQueryClient();

  return useSuspenseQuery({
    queryKey: ['public', 'chat', chatId, 'participant', token, params],
    queryFn: async () => {
      if (options?.enabled === false) {
        return null;
      }
      const existingUsers: GetUserDetailsResponse[] = [];
      const usersToFetch: string[] = [];
      params.chatUserIds.forEach((chatUserId) => {
        const user = queryClient.getQueryData<GetUserDetailsResponse>([
          'chat',
          'participant',
          chatUserId,
        ]);

        if (user) {
          existingUsers.push(user);
        } else {
          usersToFetch.push(chatUserId);
        }
      });

      if (usersToFetch.length === 0) {
        return existingUsers;
      }

      const fetchedUsers = await getPublicChatUsers(token, chatId, {
        chatUserIds: usersToFetch,
      });

      fetchedUsers.forEach((user) => {
        queryClient.fetchQuery({
          queryKey: ['chat', 'participant', user.id],
          queryFn: () => user,
          staleTime: Infinity,
        });
      });

      return [existingUsers, fetchedUsers].flat();
    },
  });
};
