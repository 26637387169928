import { PropsWithChildren } from 'react';

import { Link } from '@chakra-ui/react';

const emailRegex = /([a-zA-Z0-9+._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/g;
const phoneRegex =
  /\(?\+[0-9]{1,3}\)? ?-?[0-9]{1,3} ?-?[0-9]{3,5} ?-?[0-9]{4}( ?-?[0-9]{3})? ?(\w{1,10}\s?\d{1,6})?/g;
const linkRegex = /https?:\/\/\S+/gi;

type SmartLinkType = 'email' | 'phone' | 'link';

interface LinkIndex {
  start: number;
  end: number;
  match: string;
  type: SmartLinkType;
}

export const SmartLinks = ({ children }: PropsWithChildren) => {
  if (typeof children !== 'string') {
    // eslint-disable-next-line no-console
    console.warn('SmartLinks only accepts children as string');

    return children;
  }

  const matches = matchElements(children);
  const linkIndices = createIndexMap(matches, children);

  return getChildrenWithLinks(linkIndices, children);
};

const createIndexMap = (
  matches: {
    emails: RegExpMatchArray | null;
    phones: RegExpMatchArray | null;
    links: RegExpMatchArray | null;
  },
  children: string,
) => {
  const matchesWithTypes = [
    ...mapMatchesWithType(matches.emails, 'email'),
    ...mapMatchesWithType(matches.phones, 'phone'),
    ...mapMatchesWithType(matches.links, 'link'),
  ];

  const sortedMatches = [...matchesWithTypes].sort(
    (a, b) => children.indexOf(a.match) - children.indexOf(b.match),
  );

  return sortedMatches.reduce((acc, { match, type }) => {
    const start = children.indexOf(match, acc[acc.length - 1]?.end ?? 0);
    if (start !== -1) {
      const end = start + match.length;

      return [...acc, { start, end, match, type }];
    }

    return acc;
  }, [] as { start: number; end: number; match: string; type: SmartLinkType }[]);
};

const matchElements = (children: string) => {
  const emails = children.match(emailRegex);
  const phones = children.match(phoneRegex);
  const links = children.match(linkRegex);

  return {
    emails,
    phones,
    links,
  };
};

const getChildrenWithLinks = (linkIndices: LinkIndex[], children: string) => {
  const linkIndicesWithEndOfString = [
    ...linkIndices,
    {
      start: children.length,
      end: children.length,
    },
  ];

  return linkIndicesWithEndOfString.flatMap(({ start, end }, index, arr) => {
    const prevEnd = arr[index - 1]?.end ?? 0;
    const prevText = children.slice(prevEnd, start);

    if (end === start) {
      return prevText;
    }

    const link = children.slice(start, end);
    const element = getElement(linkIndices[index].type, link, index);

    return [prevText, element];
  });
};

const getElement = (type: SmartLinkType, link: string, index: number) => {
  switch (type) {
    case 'email':
      return (
        <Link key={index} textDecoration="underline" href={`mailto:${link}`}>
          {link}
        </Link>
      );
    case 'phone':
      return (
        <Link key={index} textDecoration="underline" href={`tel:${link}`}>
          {link}
        </Link>
      );
    case 'link':
      return (
        <Link
          key={index}
          textDecoration="underline"
          href={link}
          rel="noreferrer noopener"
          target="_blank"
        >
          {link}
        </Link>
      );
    default:
      return link;
  }
};

const mapMatchesWithType = (
  matches: RegExpMatchArray | null,
  type: SmartLinkType,
) => (matches ?? []).map((match) => ({ match, type }));
