import { ReactNode } from 'react';

import {
  Divider,
  Flex,
  Grid,
  GridProps,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { FEEDBACK_PAGE_TAB_PARAM_NAME } from '@proptly/contractor-contract-enums';
import {
  AboveDesktopView,
  BelowDesktopView,
  useSearchParamState,
} from '@proptly/ui';

import { ProjectFeedbackMessages } from './project-feedback-messages';
import { MessagesStoreProvider } from './project-feedback-messages-store';

export interface FeedbackLayoutProps extends GridProps {
  children: ReactNode;
  messagesEnabled: boolean;
}

const FeedbackMobileLayout = ({ children }: FeedbackLayoutProps) => {
  const [t] = useTranslation();

  const [_tabIndex, _setTabIndex] = useSearchParamState(
    FEEDBACK_PAGE_TAB_PARAM_NAME,
  );

  const tabIndex = _tabIndex ? parseInt(_tabIndex) : 0;
  const setTabIndex = (nextIndex: number) => {
    _setTabIndex(nextIndex + '');
  };

  return (
    <Tabs
      index={tabIndex}
      onChange={setTabIndex}
      variant="navbar"
      isManual
      isLazy
    >
      <TabList h="14">
        <Tab h="full" flex="1" py="0">
          {t('details')}
        </Tab>
        <Tab h="full" flex="1" py="0">
          {t('comments')}
        </Tab>
      </TabList>
      <Flex bg="white" flexDir="column">
        <TabPanels>
          <TabPanel p="0">
            <Flex flexDir="column" flex="1" pt="6">
              {children}
            </Flex>
          </TabPanel>
          <TabPanel
            p="0"
            sx={{
              '--chakra-space-mobileNavbar': '0',
            }}
          >
            <Flex
              flexDir="column"
              h="calc(var(--chakra-vh) - 318px)"
              minH="400px"
            >
              <ProjectFeedbackMessages />
            </Flex>
          </TabPanel>
        </TabPanels>
      </Flex>
    </Tabs>
  );
};

const FeedbackDesktopLayout = ({
  children,
  messagesEnabled,
  ...props
}: FeedbackLayoutProps) => {
  const [t] = useTranslation();

  return (
    <Grid
      bg="white"
      gridTemplateColumns={{ desktop: '1fr auto 2fr' }}
      {...(messagesEnabled && { minH: '600px' })}
      {...props}
    >
      <Flex flexDir="column" minH="full" h="fit-content">
        <Text textStyle="titleLarge" p="6">
          {t('details')}
        </Text>

        {children}
      </Flex>

      {messagesEnabled && (
        <>
          <div>
            <Divider orientation="vertical" />
          </div>

          <Flex flexDir="column" minH="full" h="0" maxH="full">
            <Text textStyle="titleLarge" p="6">
              {t('comments')}
            </Text>

            <ProjectFeedbackMessages />
          </Flex>
        </>
      )}
    </Grid>
  );
};

export const ProjectFeedbackLayout = (props: FeedbackLayoutProps) => {
  if (!props.messagesEnabled) {
    return <FeedbackDesktopLayout {...props} />;
  }

  return (
    <MessagesStoreProvider>
      <AboveDesktopView>
        <FeedbackDesktopLayout {...props} />
      </AboveDesktopView>
      <BelowDesktopView>
        <FeedbackMobileLayout {...props} />
      </BelowDesktopView>
    </MessagesStoreProvider>
  );
};
