const fileSrcMemo = new Map<File, string>();

export const getFileSrc = (file: File) => {
  const fileSrc = fileSrcMemo.get(file);

  if (fileSrc) {
    return fileSrc;
  }
  const newFileSrc = URL.createObjectURL(file);

  fileSrcMemo.set(file, newFileSrc);

  return newFileSrc;
};
