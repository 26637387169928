import { BookingType } from '@proptly/contractor-contract-enums';
import { SaleType } from '@proptly/shared';
import { useFeedbackName as useFeedbackNameUtil } from '@proptly/ui';

import { useProjectFeedback } from './use-project-feedback';

export const useProjectFeedbackName = () => {
  const feedback = useProjectFeedback();

  const feedbackSource = feedback.source;
  const type = feedback.params.find((param) => param.key === 'type')?.value as
    | BookingType
    | SaleType
    | undefined;

  return useFeedbackNameUtil(feedbackSource, type);
};
