import { Divider, Flex, FlexProps, Text } from '@chakra-ui/react';

export const MessageDivider = ({ children, ...props }: FlexProps) => {
  return (
    <Flex align="center" borderColor="grey.shade" {...props}>
      <Divider orientation="horizontal" flex="1" minW="4" />
      <Text
        textStyle="labelSmall"
        color="grey.dark"
        px="4"
        py="1"
        textAlign="center"
      >
        {children}
      </Text>
      <Divider orientation="horizontal" flex="1" minW="4" />
    </Flex>
  );
};
