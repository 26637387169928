import { KeyboardEventHandler, useEffect, useRef, useState } from 'react';

import { Box, Flex, FlexProps, IconButton, Text } from '@chakra-ui/react';
import { PaperPlane, WarningCircle } from '@phosphor-icons/react';
import { useMutation } from '@tanstack/react-query';
import { maxLength } from 'class-validator';
import { useLiveQuery } from 'dexie-react-hooks';
import { useTranslation } from 'react-i18next';

import { ns } from '@proptly/locale';
import { StringLengthsEnum } from '@proptly/shared';

import { useSubmitErrorHandler } from '../../../hooks';
import { chatDb } from '../../../utils/chat';
import { AutoresizeableTextarea } from '../../atoms';
import { PendingFile, useFilesPreview } from '../../files';

export interface ChatInputProps extends FlexProps {
  onMessageAdded: (text: string) => Promise<void>;
  chatId: string;
  userId: string;
  defaultText?: string;
  files?: {
    id: string;
    fileName: string;
    src: string;
    size: number;
  }[];
  onFileRemove?: (fileId: string) => void;
}

export const ChatInput = ({
  chatId,
  userId,
  onMessageAdded,
  defaultText,
  files,
  onFileRemove,
  ...props
}: ChatInputProps) => {
  const [t] = useTranslation();
  const [errt] = useTranslation(ns.Errors);

  const [hasFocus, setHasFocus] = useState(false);
  const previewFile = useFilesPreview();

  const chatDbId = `${chatId}-${userId}`;

  const dbText =
    useLiveQuery(
      () =>
        chatDb.messages
          .where('id')
          .equals(chatDbId)
          .first()
          .then((message) => message?.text),
      [chatDbId],
    ) ??
    defaultText ??
    '';

  const [text, setText] = useState<string>('');

  useEffect(() => {
    setText(dbText ?? '');
  }, [dbText]);

  const hasSomeFiles = !!files && files.length !== 0;
  const textInputEmpty = !text || text.trim().length === 0;
  const isInputError = !maxLength(text, StringLengthsEnum.CharsLength5000);
  const textTooLongByCount = isInputError
    ? text.length - StringLengthsEnum.CharsLength5000
    : 0;
  const inputErrorMessage = `${errt('chatInputError.tooLongBy', {
    count: textTooLongByCount,
  })}. ${errt('chatInputError.maxLength', {
    count: StringLengthsEnum.CharsLength5000,
  })}`;

  const isButtonDisabled = (textInputEmpty && !hasSomeFiles) || isInputError;

  const inputRef = useRef<HTMLTextAreaElement>(null);

  const { mutateAsync: addMessage, isPending: isLoading } = useMutation({
    mutationFn: onMessageAdded,
  });
  const handleSubmitError = useSubmitErrorHandler();

  const submitMessage = async () => {
    try {
      await addMessage(text).then(() => {
        chatDb.messages.delete(chatDbId);
      });
    } catch (error) {
      handleSubmitError(error);

      return;
    }

    inputRef.current?.focus();
  };

  const submitOnCtrlEnter: KeyboardEventHandler<HTMLTextAreaElement> = (e) => {
    if (e.key === 'Enter' && (e.ctrlKey || e.metaKey)) {
      e.preventDefault();
      submitMessage();
    }
  };

  return (
    <Flex minW="0" flex="1" flexDir="column" gap="2.5">
      {isInputError && (
        <Flex gap="2" align="center" color="red.default">
          <WarningCircle size="20" />
          <Text textStyle="labelSmall">{inputErrorMessage}</Text>
        </Flex>
      )}
      <Box
        flex="1"
        minW="0"
        bg="background"
        border="1px solid"
        borderColor={!isInputError ? 'grey.shade' : 'red.default'}
        rounded="md"
        transition="0.2s border-color ease-in-out"
        _hover={{
          borderColor: !isInputError ? 'primary.default' : 'red.default',
        }}
        _focus={{
          borderColor: !isInputError ? 'primary.default' : 'red.default',
        }}
        {...(hasFocus && {
          'data-focus': true,
        })}
        onFocus={(e) => {
          setHasFocus(true);
          props.onFocus?.(e);
        }}
        onBlur={(e) => {
          setHasFocus(false);
          props.onBlur?.(e);
        }}
        {...props}
      >
        {files && files.length > 0 && (
          <Flex
            w="full"
            gap="4"
            px={{
              mobile: '4',
              desktop: '8',
            }}
            pt="3"
            pb="2"
            overflow="auto"
          >
            {files.map((file, index) => (
              <PendingFile
                key={file.id}
                isLoading={isLoading}
                onRemove={onFileRemove && (() => onFileRemove(file.id))}
                onClick={() => previewFile(files, index)}
                file={file}
              />
            ))}
          </Flex>
        )}
        <Flex
          pl={{
            mobile: '4',
            desktop: '8',
          }}
          pr={{
            desktop: '15px',
          }}
        >
          <Flex
            overflow="auto"
            flex="1"
            h="full"
            my={{ mobile: '11px', desktop: '15px' }}
            maxH={{ mobile: '104px', desktop: '144px' }}
          >
            <AutoresizeableTextarea
              ref={inputRef}
              h="full"
              py="0"
              variant="unstyled"
              borderRadius="none"
              fontSize="sm"
              lineHeight="24px"
              value={text}
              onChange={(e) => {
                setText(e.target.value);
                try {
                  chatDb.messages.put({
                    id: chatDbId,
                    chatId,
                    text: e.target.value,
                  });
                } catch (error) {
                  // eslint-disable-next-line no-console
                  console.error(error);
                  throw new Error(errt('failedToSaveMessage'));
                }
              }}
              onKeyDown={submitOnCtrlEnter}
              placeholder={t('writeMessage')}
              isReadOnly={isLoading}
            />
          </Flex>

          <IconButton
            isDisabled={isButtonDisabled}
            onClick={submitMessage}
            variant="link"
            flexShrink="0"
            w={{ mobile: '46px', desktop: '54px' }}
            h={{ mobile: '46px', desktop: '54px' }}
            mt="-1px"
            rounded="mb"
            color={hasFocus ? 'primary.default' : 'grey.dark'}
            aria-label={t('send')}
            icon={<PaperPlane size="20" />}
            alignSelf="flex-end"
            isLoading={isLoading}
            _hover={{
              color: 'primary.default',
            }}
          />
        </Flex>
      </Box>
    </Flex>
  );
};
