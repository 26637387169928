import {
  cloneElement,
  ComponentPropsWithRef,
  ReactElement,
  ReactNode,
  useRef,
} from 'react';

import {
  Button,
  chakra,
  ChakraProps,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverProps,
  PopoverTrigger,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { useElementIsClamped } from '../../hooks';
import { DefaultDrawer } from '../form-wizard';
import { ContainerFooter } from '../functional';

const likeTooltipBodyStyles: ChakraProps = {
  bg: 'grey.dark',
  color: 'background',
  rounded: 'md',
  p: '4',
};

const TextTruncateCard = ({
  children: _children,
  label,
}: {
  children: ReactElement;
  label: ReactNode;
}) => {
  const [t] = useTranslation();

  const { isOpen, onClose, onOpen } = useDisclosure();
  const children = cloneElement(_children, { onClick: onOpen });

  return (
    <>
      {children}

      <DefaultDrawer placement="bottom" isOpen={isOpen} onClose={onClose}>
        {label}

        <ContainerFooter>
          <Button
            colorScheme="primary"
            fontWeight="medium"
            fontSize="sm"
            borderRadius="full"
            w="full"
            size="lg"
            maxW="355px"
            mx="auto"
            onClick={onClose}
          >
            {t('cancel')}
          </Button>
        </ContainerFooter>
      </DefaultDrawer>
    </>
  );
};

const TextTruncatePopover = ({
  children,
  label,
  popoverProps,
}: {
  children: ReactElement;
  label: ReactNode;
  popoverProps?: PopoverProps;
}) => {
  return (
    <Popover matchWidth {...popoverProps}>
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent minW="250px" w="full">
        <PopoverArrow bg="grey.dark" />
        <PopoverBody {...likeTooltipBodyStyles}>{label}</PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

interface TextTruncateProps {
  label: ReactNode;
  children: ReactElement<ComponentPropsWithRef<typeof Text>>;
  variant?: 'tooltip' | 'card' | 'popover-click' | 'popover-hover';
  leftIcon?: ReactElement;
  rightIcon?: ReactElement;
}

export const TextTruncate = ({
  variant = 'tooltip',
  children: _children,
  label,
  leftIcon,
  rightIcon,
}: TextTruncateProps) => {
  const textRef = useRef<HTMLParagraphElement>(null);
  const childrenWithRef = cloneElement(_children, {
    ref: textRef,
  });

  const isClamped = useElementIsClamped(textRef);

  if (!isClamped) {
    return childrenWithRef;
  }

  const children = (
    <Flex gap="1" align="center">
      {leftIcon && <chakra.span flexShrink="0">{leftIcon}</chakra.span>}
      {childrenWithRef}
      {rightIcon && <chakra.span flexShrink="0">{rightIcon}</chakra.span>}
    </Flex>
  );

  if (variant === 'card') {
    return <TextTruncateCard label={label}>{children}</TextTruncateCard>;
  }

  if (variant === 'popover-click') {
    return <TextTruncatePopover label={label}>{children}</TextTruncatePopover>;
  }

  if (variant === 'popover-hover') {
    return (
      <TextTruncatePopover
        label={label}
        popoverProps={{
          trigger: 'hover',
        }}
      >
        {children}
      </TextTruncatePopover>
    );
  }

  return (
    <Tooltip
      {...likeTooltipBodyStyles}
      hasArrow
      maxW="430px"
      closeDelay={300}
      label={label}
    >
      {children}
    </Tooltip>
  );
};
