import { Container, Text } from '@chakra-ui/react';
import { FallbackProps } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';

import { ns } from '@proptly/locale';
import { getErrorMessage } from '@proptly/ui';

export function ExpiredLinkFallback({ error }: FallbackProps) {
  if (getErrorMessage(error) !== 'FEEDBACK_ERROR.EXPIRED') {
    throw error;
  }
  const [t] = useTranslation(ns.Errors);

  const message = t('linkHasExpired');

  return (
    <Container py="10">
      <Text mb="2" textStyle="bodyLarge" color="primary.default">
        {message}
      </Text>
      <Text textStyle="body">{t('linkHasExpiredDescription')}</Text>
    </Container>
  );
}
