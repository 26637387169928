import { Box, Flex, Text } from '@chakra-ui/react';
import { ArrowRight } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';

import { getFullName } from '@proptly/shared';
import {
  AboveDesktopView,
  BelowDesktopView,
  EMPTY_PLACEHOLDER,
  ProptlyLogo,
  TextTruncate,
} from '@proptly/ui';

import { useGetPublicProjectAssigneeQuery } from '../../api/project';
import { useProject, useProjectToken, usePublicContractor } from '../../hooks';
import { PublicContractorLogo } from '../public-contractor-logo';

export const ProjectChatDetails = () => {
  const [t] = useTranslation();

  const token = useProjectToken();
  const project = useProject();

  const contractor = usePublicContractor(project.contractorId);
  const { data: projectAssignee } = useGetPublicProjectAssigneeQuery(token);

  return (
    <Flex
      flexDir="column"
      gap={{ mobile: '6', desktop: '20' }}
      p={{ mobile: '6', desktop: '10' }}
      bg="background"
      borderRightWidth={{
        mobile: '0',
        desktop: '1px',
      }}
      borderColor="grey.shade"
      maxW={{ md: '360px', lg: '460px', xl: '560px' }}
    >
      <PublicContractorLogo
        contractorId={project.contractorId}
        notFoundFallback={<ProptlyLogo />}
        maxW={{
          mobile: '100px',
          desktop: '200px',
        }}
        maxH={{
          mobile: '40px',
          desktop: '60px',
        }}
      />

      <BelowDesktopView>
        <Box>
          <TextTruncate
            variant="card"
            rightIcon={<ArrowRight size="20" />}
            label={project.projectName}
          >
            <Text textStyle="bodyLarge" noOfLines={2}>
              {project.projectName}
            </Text>
          </TextTruncate>
          <Text fontSize="12px" lineHeight="1.7" color="grey.dark">
            {t('welcomeToCustomerPortalShort')}
          </Text>
        </Box>
      </BelowDesktopView>

      <AboveDesktopView>
        <Box>
          <TextTruncate label={project.projectName}>
            <Text textStyle="h2" mb="2" noOfLines={2}>
              {project.projectName}
            </Text>
          </TextTruncate>
          <Text fontSize="14px" lineHeight="1.7" color="grey.dark">
            {t('welcomeToCustomerPortalLong')}
          </Text>
        </Box>

        <Flex
          gap={{ base: '8', lg: '4' }}
          flexDir={{ base: 'column', lg: 'row' }}
        >
          <Box flex="1">
            <Text textStyle="bodyLarge" mb="5">
              {contractor?.name}
            </Text>

            <Text textStyle="labelSmall" color="grey.dark" mb="1">
              {t('contactPerson')}
            </Text>

            <Text textStyle="labelMedium">
              {projectAssignee
                ? getFullName(projectAssignee)
                : EMPTY_PLACEHOLDER}
            </Text>
          </Box>
        </Flex>
      </AboveDesktopView>
    </Flex>
  );
};
