import { Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import {
  ConfirmModalCancelButton,
  ConfirmModalConfirmButton,
  useConfirm,
} from '@proptly/ui';

export const useConfirmProjectRating = () => {
  const [t] = useTranslation();

  return useConfirm(() => (
    <>
      <Text textStyle="bodyLarge" textAlign="center">
        {t('confirmSubmitProjectRating')}
      </Text>
      <ConfirmModalConfirmButton />
      <ConfirmModalCancelButton />
    </>
  ));
};
