export const getPaddings = (element: HTMLElement) => {
  const style = window.getComputedStyle(element);

  const left = parseFloat(style.paddingLeft);
  const right = parseFloat(style.paddingRight);
  const top = parseFloat(style.paddingTop);
  const bottom = parseFloat(style.paddingBottom);

  return {
    left,
    right,
    top,
    bottom,
    x: left + right,
    y: top + bottom,
  };
};
