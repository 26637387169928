import {
  Flex,
  forwardRef,
  SkeletonCircle,
  SkeletonText,
} from '@chakra-ui/react';

export const MessageAuthorSkeleton = forwardRef((props, ref) => {
  return (
    <Flex gap="2" align="center" w="50%" ref={ref} {...props}>
      <SkeletonCircle flexShrink="0" w="10" h="10" />
      <SkeletonText w="100%" skeletonHeight="5" noOfLines={1} />
    </Flex>
  );
});
