import { avatarAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

import { getAvatarColor, getTypography } from '../utils';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(avatarAnatomy.keys);

const baseStyle = definePartsStyle(({ name }) => {
  const colorScheme = getAvatarColor(name);

  return {
    container: {
      ...(colorScheme && {
        bg: `${colorScheme}.shade`,
        color: `${colorScheme}.default`,
      }),
    },
  };
});

const sizes = {
  md: definePartsStyle(({ theme }) => ({
    container: {
      w: '10',
      h: '10',
    },
    label: {
      ...getTypography('bodyMedium', theme),
    },
  })),
  '3xl': definePartsStyle(({ theme }) => ({
    container: {
      w: '154px',
      h: '154px',
    },
    label: {
      ...getTypography('bodyLarge', theme),
    },
  })),
};

const variants = {
  user: definePartsStyle(({ theme }) => ({
    container: {
      bg: 'primary.shade',
      color: 'primary.default',
    },
  })),
};

export const avatarTheme = defineMultiStyleConfig({
  baseStyle,
  sizes,
  variants,
});
