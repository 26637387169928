import { Box, Grid, GridProps, Image } from '@chakra-ui/react';

import { useFilesPreview } from '../../files';
import { MessageFile } from '../chat.types';

interface MessageImageProps extends GridProps {
  files: MessageFile[];
}

const MAX_IMAGES_IN_ROW = 3;

export const MessageImage = ({ files, ...props }: MessageImageProps) => {
  const previewFile = useFilesPreview();
  const filesCount = files.length;
  const imagesInRow = Math.min(filesCount, MAX_IMAGES_IN_ROW);

  return (
    <Grid
      gridTemplateColumns={`repeat(${imagesInRow}, 1fr)`}
      gap="1"
      {...props}
    >
      {files.map((file, index) => (
        <Box
          key={file.id}
          as={Image}
          src={file.src}
          alt={file.fileName}
          onClick={() => previewFile(files, index)}
          rounded="md"
          cursor="pointer"
        />
      ))}
    </Grid>
  );
};
