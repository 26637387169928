import { ReactElement, useLayoutEffect } from 'react';

import {
  chakra,
  ChakraProps,
  forwardRef,
  useCallbackRef,
} from '@chakra-ui/react';
import { useSuspenseQuery } from '@tanstack/react-query';

export interface ImageProps extends ChakraProps {
  fallback?: ReactElement;
  src: string;
  onError?: (error?: Error) => void;
}

const preloadImage = (src: string) => {
  return new Promise<null>((res, rej) => {
    const image = new window.Image();
    image.onerror = () => {
      clearInterval(interval);
      rej(new Error());
    };
    image.src = src;
    if (image.width) {
      res(null);

      return;
    }
    const interval = setInterval(() => {
      if (image.width) {
        res(null);
        clearInterval(interval);
      }
    }, 10);
  });
};

export const PreviewImage = forwardRef<ImageProps, 'img'>(
  ({ fallback, src, onError, ...props }, ref) => {
    const { data } = useSuspenseQuery({
      queryKey: [src],
      queryFn: () => preloadImage(src).catch(() => new Error()),
    });

    const onErrorRef = useCallbackRef(onError);

    useLayoutEffect(() => {
      if (data instanceof Error) {
        onErrorRef(new Error());
      }
    }, [data, onErrorRef]);

    return <chakra.img src={src} {...props} ref={ref} />;
  },
);
