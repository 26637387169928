import Session from 'supertokens-auth-react/recipe/session';
import { UserRoleClaim } from 'supertokens-auth-react/recipe/userroles';

import { SupertokensRole } from '@proptly/shared';

import {
  IS_AFTER_LOGIN_PARAM_KEY,
  IS_AFTER_LOGIN_PARAM_VALUE,
} from '../../hooks/use-is-after-login';
import { MobilePushNotifications } from '../../services';
import {
  ADMIN_APP_URL,
  AUTH_APP_URL,
  CONTRACTOR_EMPLOYEE_APP_URL,
  ROUTING_APP_URL,
} from '../constants';

export function deleteAllCookies() {
  document.cookie
    .split(';')
    .map((cookie) => cookie.trim())
    .forEach((cookie) => {
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
      document.cookie = `${name}=;path=/;expires=${new Date(0).toUTCString()}`;
    });
}

const authPageUrl = `${AUTH_APP_URL}/auth`;

export function redirectToAuth() {
  const redirectToPath = window.location.href;
  const searchParams = new URLSearchParams();
  searchParams.set('redirectToPath', redirectToPath);

  window.location.assign(`${authPageUrl}?${searchParams.toString()}`);
}

export const logout = async () => {
  if (MobilePushNotifications.isRegistered) {
    await MobilePushNotifications.unregister();
  }
  await Session.signOut();
  deleteAllCookies();
  redirectToAuth();
};

const addIsAfterLoginParam = (redirectToPath: string) => {
  const redirectToPathUrl = new URL(redirectToPath);
  redirectToPathUrl.searchParams.set(
    IS_AFTER_LOGIN_PARAM_KEY,
    IS_AFTER_LOGIN_PARAM_VALUE,
  );

  return redirectToPathUrl.toString();
};

export const getRedirectionURL = async () => {
  const params = new URLSearchParams(document.location.search);
  const redirectToPath = params.get('redirectToPath');

  if (redirectToPath) {
    return addIsAfterLoginParam(redirectToPath);
  }

  const userRoles = (await Session.getClaimValue({
    claim: UserRoleClaim,
  })) as SupertokensRole[];

  if (userRoles.length >= 1) {
    return addIsAfterLoginParam(getURLforRole(userRoles[0]));
  }

  return undefined;
};

const getURLforRole = (role: SupertokensRole) => {
  switch (role) {
    case SupertokensRole.ContractorUser:
      return CONTRACTOR_EMPLOYEE_APP_URL;
    case SupertokensRole.RoutingUser:
      return ROUTING_APP_URL;
    case SupertokensRole.SuperUser:
      return ADMIN_APP_URL;
  }
};
