import { Divider, Flex, Image, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { useWeekdayWithTimeFormatTimestamp } from '../../../hooks';
import { ImageCircleBox } from '../../molecules';

interface MessageListFinalStatusProps {
  iconSrc: string;
  title: string;
  timestamp: Date;
}

export const MessageListFinalStatus = ({
  timestamp,
  title,
  iconSrc,
}: MessageListFinalStatusProps) => {
  const [t] = useTranslation();

  const formatTimestamp = useWeekdayWithTimeFormatTimestamp();

  return (
    <Flex flexDir="column" align="center">
      <Flex
        align="center"
        borderColor="grey.shade"
        w="full"
        mb={{ mobile: '6', desktop: '10' }}
      >
        <Divider orientation="horizontal" flex="1" minW="4" />
        <Text textStyle="labelSmall" color="primary.default" textAlign="center">
          {t('systemNotificationWithDate', {
            date: formatTimestamp(timestamp),
          })}
        </Text>
        <Divider orientation="horizontal" flex="1" minW="4" />
      </Flex>

      <ImageCircleBox mb="4">
        <Image src={iconSrc} />
      </ImageCircleBox>

      <Flex flexDir="column" align="center" textAlign="center">
        <Text textStyle="label">{title}</Text>

        <Text textStyle="labelSmall" color="grey.dark">
          {t('chatIsInactive')}
        </Text>
      </Flex>
    </Flex>
  );
};
