/* eslint-disable react/jsx-no-useless-fragment */

import { useProjectFeedbackMessageAuthor } from '../../hooks';

export interface MessageAuthorProps {
  createdBy: string;
  contractorName: string;
}

export const ProjectFeedbackMessageAuthor = ({
  createdBy,
  contractorName,
}: MessageAuthorProps) => {
  const { author } = useProjectFeedbackMessageAuthor(createdBy, contractorName);

  return <>{author}</>;
};
