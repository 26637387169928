import { Flex, Text } from '@chakra-ui/layout';
import { ChakraProps } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { ProptlyLogo } from '@proptly/ui';

import { PublicContractorLogo } from './public-contractor-logo';
import { PublicTenantLogo } from './public-tenant-logo';

const logoSizeStyle: ChakraProps = {
  maxW: {
    mobile: '100px',
    desktop: '200px',
  },
  maxH: {
    mobile: '40px',
    desktop: '60px',
  },
};

interface ContractorAndTenantLogoProps {
  tenantId?: string | null;
  contractorId?: string | null;
}

export const ContractorAndTenantLogo = ({
  tenantId,
  contractorId,
}: ContractorAndTenantLogoProps) => {
  const [t] = useTranslation();

  return (
    <Flex
      flexDir={{ mobile: 'column', desktop: 'row' }}
      gap="4"
      align="baseline"
      justify="center"
    >
      <PublicContractorLogo
        contractorId={contractorId}
        notFoundFallback={<ProptlyLogo />}
        {...logoSizeStyle}
      />

      <Flex gap="4" align="baseline">
        <PublicTenantLogo
          tenantId={tenantId}
          contentBeforeLogo={
            contractorId && (
              <Text
                textStyle={{
                  mobile: 'bodyMedium',
                  desktop: 'labelMedium',
                }}
              >
                {t('for')}
              </Text>
            )
          }
          {...logoSizeStyle}
        />
      </Flex>
    </Flex>
  );
};
