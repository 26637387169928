import { useSearchParams } from 'react-router-dom';

import { NotFoundError } from '@proptly/ui';

export const useSearchParamsToken = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  if (!token) {
    throw new NotFoundError();
  }

  return token;
};
