import { Box, Flex, Text } from '@chakra-ui/react';
import { ArrowRight } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';

import { OrderStatus } from '@proptly/routing-contract-enums';
import { getFullName, ProjectStatus } from '@proptly/shared';
import {
  AboveDesktopView,
  BelowDesktopView,
  EMPTY_PLACEHOLDER,
  TextTruncate,
} from '@proptly/ui';

import { useGetPublicContractorOptionalQuery } from '../../api/contractor';
import { useGetPublicProjectAssigneeOptionalQuery } from '../../api/project';
import { useGetPublicTenantQuery } from '../../api/tenant';
import { useOrderChatPermissions, usePublicOrder } from '../../hooks';
import { OrderLogos } from './order-logos';

export const OrderChatDetails = () => {
  const [t] = useTranslation();

  const order = usePublicOrder();

  const isContractorCancelledOrder =
    order.projectStatus === ProjectStatus.Cancelled;
  const isOrderPending = order.status === OrderStatus.Pending;

  const tenantName = useGetPublicTenantQuery(order.tenantId).data?.name;
  const { data: contractor } = useGetPublicContractorOptionalQuery(
    !isContractorCancelledOrder ? order.contractorApiId : undefined,
  );

  const isRoutedToBoundContractor = !!order.isRoutedToBoundContractor;

  const { data: projectAssignee } = useGetPublicProjectAssigneeOptionalQuery(
    isRoutedToBoundContractor ? order.token : undefined,
  );

  const { isChatWithContractorAvailable, isChatWithOperatorAvailable } =
    useOrderChatPermissions();

  const isContractorAssigned =
    isRoutedToBoundContractor && isChatWithContractorAvailable;

  const isContractorNeedToBeReassigned =
    isContractorAssigned && isContractorCancelledOrder && !isOrderPending;

  const showContractorDetails =
    isContractorAssigned && !isContractorNeedToBeReassigned && !isOrderPending;

  return (
    <Flex
      flexDir="column"
      gap={{ mobile: '6', desktop: '20' }}
      p={{ mobile: '6', desktop: '10' }}
      bg="background"
      borderRightWidth={{
        mobile: '0',
        desktop: '1px',
      }}
      borderColor="grey.shade"
      maxW={{ md: '360px', lg: '460px', xl: '560px' }}
    >
      <OrderLogos
        contractorId={
          showContractorDetails ? order.contractorApiId ?? undefined : undefined
        }
        tenantId={order.tenantId}
      />

      <BelowDesktopView>
        <Box>
          <TextTruncate
            variant="card"
            rightIcon={<ArrowRight size="20" />}
            label={order.name}
          >
            <Text textStyle="bodyLarge" noOfLines={2}>
              {order.name}
            </Text>
          </TextTruncate>
          <Text fontSize="12px" lineHeight="1.7" color="grey.dark">
            {t('welcomeToCustomerPortalShort')}
          </Text>
        </Box>
      </BelowDesktopView>

      <AboveDesktopView>
        <Box>
          <TextTruncate label={order.name}>
            <Text textStyle="h2" mb="2" noOfLines={2}>
              {order.name}
            </Text>
          </TextTruncate>
          <Text fontSize="14px" lineHeight="1.7" color="grey.dark">
            {t('welcomeToCustomerPortalLong')}
          </Text>
        </Box>

        <Flex
          gap={{ base: '8', lg: '4' }}
          flexDir={{ base: 'column', lg: 'row' }}
        >
          {isRoutedToBoundContractor && (
            <Box flex="1">
              <Text textStyle="bodyLarge" mb="5">
                {showContractorDetails && contractor?.name
                  ? contractor.name
                  : t('contractor')}
              </Text>

              {isContractorNeedToBeReassigned && (
                <Text textStyle="labelSmall" color="grey.dark" mb="4">
                  {t('contractorHasCancelledOrder')}
                </Text>
              )}

              <Text textStyle="labelSmall" color="grey.dark" mb="1">
                {showContractorDetails && contractor?.name
                  ? t('contactPerson')
                  : isContractorNeedToBeReassigned
                  ? t('tenantReassigningContractorForOrder', {
                      tenantName,
                    })
                  : t('tenantAssigningContractorForOrder', {
                      tenantName,
                    })}
              </Text>

              {showContractorDetails && contractor?.name && (
                <Text textStyle="labelMedium">
                  {projectAssignee
                    ? getFullName(projectAssignee)
                    : EMPTY_PLACEHOLDER}
                </Text>
              )}
            </Box>
          )}

          {isChatWithOperatorAvailable && (
            <Box flex="1">
              <Text textStyle="bodyLarge" mb="5">
                {tenantName}
              </Text>

              <Text textStyle="labelSmall" color="grey.dark" mb="1">
                {t('contactPerson')}
              </Text>

              <Text textStyle="labelMedium">
                {getFullName(order.routingOperator)}
              </Text>
            </Box>
          )}
        </Flex>
      </AboveDesktopView>
    </Flex>
  );
};
