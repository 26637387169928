import { ChakraProps, EffectProps, Flex, Image } from '@chakra-ui/react';
import {
  File,
  FileDoc,
  FileImage,
  FilePdf,
  FilePpt,
  FileVideo,
  FileXls,
  FileZip,
} from '@phosphor-icons/react';

import { getFileExt, isImageFile } from '../../utils';
import { FileIconVideo } from './file-icon-video';
import { isVideoFile } from './utils';

export const fileIconsMap = new Map([
  ['doc', <FileDoc />],
  ['docx', <FileDoc />],
  ['xls', <FileXls />],
  ['xlsx', <FileXls />],
  ['ppt', <FilePpt />],
  ['pptx', <FilePpt />],
  ['pdf', <FilePdf />],
  ['zip', <FileZip />],
  ['mov', <FileVideo />],
]);

export const defaultFileIcon = <File />;

interface FileIconProps {
  file: { fileName: string; src?: string };
  size?: number;
  opacity?: EffectProps['opacity'];
  color?: ChakraProps['color'];
  bg?: ChakraProps['bg'];
  border?: ChakraProps['border'];
  borderColor?: ChakraProps['borderColor'];
  onClick?: () => void;
}

export const FileIcon = ({ file, size = 48, ...props }: FileIconProps) => {
  const fileViewer = createFileViewer(file, size, props);

  return (
    <Flex
      w={`${size}px`}
      h={`${size}px`}
      color="primary.default"
      align="center"
      justify="center"
      bg="background"
      {...props}
    >
      {fileViewer}
    </Flex>
  );
};

const editUrlParams = (src: string, size: number) => {
  if (!src.startsWith('blob:')) {
    const url = new URL(src);
    url.searchParams.set('size', size + '');

    return url.toString();
  }

  return src;
};

const createFileViewer = (
  file: FileIconProps['file'],
  size: NonNullable<FileIconProps['size']>,
  props: Omit<FileIconProps, 'file' | 'size'>,
) => {
  const ext = getFileExt(file.fileName);

  const fileTypesStrategies = {
    isImage: () => {
      const src = file.src ? editUrlParams(file.src, size) : undefined;

      return src ? (
        <Image
          w={`${size}px`}
          h={`${size}px`}
          minW={`${size}px`}
          objectFit="cover"
          borderRadius="md"
          src={src}
          {...props}
        />
      ) : (
        <FileImage />
      );
    },
    isVideo: () => {
      const src = file.src ? editUrlParams(file.src, size) : undefined;

      return src ? (
        <FileIconVideo size={size} src={src} {...props} />
      ) : (
        <FileVideo />
      );
    },
    isDefault: () => defaultFileIcon,
  };

  if (isImageFile(file)) {
    return fileTypesStrategies.isImage();
  }

  if (isVideoFile(file)) {
    return fileTypesStrategies.isVideo();
  }

  if (ext && fileIconsMap.has(ext)) {
    return fileIconsMap.get(ext);
  }

  return fileTypesStrategies.isDefault();
};
