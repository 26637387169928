import { ReactNode } from 'react';

import { ImageProps, Text } from '@chakra-ui/react';

import {
  ErrorBoundary,
  getContractorLogoUrl,
  TryCompanyLogo,
} from '@proptly/ui';

import { useGetPublicContractorOptionalQuery } from '../api/contractor';

interface PublicContractorLogoProps extends Omit<ImageProps, 'src'> {
  contractorId?: string | null;
  notFoundFallback?: ReactNode;
}

export const PublicContractorLogo = ({
  contractorId,
  notFoundFallback,
  ...props
}: PublicContractorLogoProps) => {
  const { data: contractor } =
    useGetPublicContractorOptionalQuery(contractorId);

  if (!contractorId || !contractor) {
    return notFoundFallback || null;
  }

  const contractorLogoSrc = getContractorLogoUrl(contractorId);

  return (
    <ErrorBoundary
      fallbackRender={() => (
        <Text textStyle="titleLarge">{contractor.name}</Text>
      )}
    >
      <TryCompanyLogo src={contractorLogoSrc} {...props} />
    </ErrorBoundary>
  );
};
