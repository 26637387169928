import { ReactNode, useMemo } from 'react';

import { Box, Flex, FlexProps, SkeletonText, Text } from '@chakra-ui/react';
import groupBy from 'lodash/groupBy';

import { DateLike } from '@proptly/shared';

import { useFormatterWithAlt, useMediumFormatDate } from '../../../hooks';
import { MaintainScrollPosition, VisibilityObserver } from '../../functional';
import { MessageBubble } from './message-bubble';

export type UserMessage = {
  __type: 'UserMessage';
  id: string;
  createdAt: DateLike;
  author: ReactNode;
  text: ReactNode;
  isMyMessage: boolean;
  threadId: string;
  noteUrl?: string;
  files?: { id: string; src: string; fileName: string }[];
};

export type SystemMessage = {
  __type: 'SystemMessage';
  id: string;
  createdAt: DateLike;
  text: ReactNode;
};

export type Message = UserMessage | SystemMessage;

export interface MessagesBodyProps extends FlexProps {
  messages: Message[];
  selectedMessage?: Message;
  onLastMessageRead?: () => void;
  emptyMessagesFallback?: ReactNode;
  hasMore?: boolean;
  onLoadMore?: () => void;
  messageIdToFocus?: string;
  openMessageOptions?: (message: Message) => void;
}

export const MessagesBody = ({
  messages,
  emptyMessagesFallback,
  hasMore,
  onLastMessageRead,
  onLoadMore,
  messageIdToFocus,
  openMessageOptions,
  selectedMessage,
  ...props
}: MessagesBodyProps) => {
  const mediumFormatDate = useMediumFormatDate();
  const formatDateAlt = useFormatterWithAlt(mediumFormatDate);

  const groupedMessages = useMemo(
    () => groupBy(messages, (message) => formatDateAlt(message.createdAt)),
    [messages, formatDateAlt],
  );

  const isMessagesEmpty = messages.length === 0;
  const lastMessageId = messages.at(-1)?.id;
  const firsMessageId = messages.at(0)?.id;

  return (
    <MaintainScrollPosition
      // adding key here is necessary to update ref of element below
      key={lastMessageId}
    >
      <Flex
        flex="1"
        overflow="auto"
        flexDir="column-reverse"
        // adding key will rerender container so it will scroll to bottom when new message appears
        key={lastMessageId}
        {...props}
      >
        <Flex
          flex="1"
          flexDir="column"
          gap="6"
          justifyContent={isMessagesEmpty ? 'center' : 'flex-end'}
        >
          {hasMore && (
            <VisibilityObserver
              key={firsMessageId}
              onFirstTimeVisible={onLoadMore}
            >
              <div>
                <SkeletonText minW="250px" w="50%" px="5" py="4" />
                <SkeletonText
                  minW="250px"
                  w="50%"
                  px="5"
                  py="4"
                  transform="scaleX(-1)"
                  ml="auto"
                />
                <SkeletonText minW="250px" w="50%" px="5" py="4" />
              </div>
            </VisibilityObserver>
          )}
          {isMessagesEmpty
            ? emptyMessagesFallback
            : Object.entries(groupedMessages).map(
                ([readableDate, messages]) => {
                  return (
                    <Box key={readableDate} _last={{ mb: '2' }}>
                      <Text
                        bg="background"
                        top="2"
                        textAlign="center"
                        borderRadius="full"
                        px="2"
                        py="1"
                        my="4"
                        mx="auto"
                        w="fit-content"
                        textStyle="labelSmall"
                        color="text.grey"
                      >
                        {readableDate}
                      </Text>
                      {messages.map((message) => {
                        const isLastMessage = message.id === lastMessageId;
                        const autoFocus = message.id === messageIdToFocus;

                        if (isLastMessage) {
                          return (
                            <VisibilityObserver
                              onFirstTimeVisible={onLastMessageRead}
                              key={message.id}
                            >
                              <MessageBubble
                                message={message}
                                autoFocus={autoFocus}
                                onOptions={openMessageOptions}
                                selectedMessage={selectedMessage}
                              />
                            </VisibilityObserver>
                          );
                        }

                        return (
                          <MessageBubble
                            key={message.id}
                            message={message}
                            autoFocus={autoFocus}
                            onOptions={openMessageOptions}
                            selectedMessage={selectedMessage}
                          />
                        );
                      })}
                    </Box>
                  );
                },
              )}
        </Flex>
      </Flex>
    </MaintainScrollPosition>
  );
};
