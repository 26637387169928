import { FILE_MAX_UPLOAD_SIZE, FileTypes } from '@proptly/shared';
import {
  ApiResponse,
  FileTooLargeError,
  UnsupportedFileTypeError,
  UploadFileFnAxiosConfig,
  createUploadFileFnV2,
  fileClient,
  getFileExt,
} from '@proptly/ui';

export interface UploadFilePayload {
  file: File;
  token: string;
}

export const uploadFileV2 = (
  { file, token }: UploadFilePayload,
  axiosConfig?: UploadFileFnAxiosConfig,
) => {
  if (file.size > FILE_MAX_UPLOAD_SIZE) {
    throw new FileTooLargeError(FILE_MAX_UPLOAD_SIZE, file);
  }

  const fileExt = getFileExt(file.name);

  const allowedExtensions = FileTypes.allExtensions;

  if (!fileExt || !allowedExtensions.includes(fileExt)) {
    throw new UnsupportedFileTypeError(allowedExtensions, file);
  }

  const formData = new FormData();
  formData.set('file', file);

  return fileClient
    .post<ApiResponse<string>>(
      `public/file/upload/${token}`,
      formData,
      axiosConfig,
    )
    .then((resp) => resp.data.data);
};

export const uploadFileAndTrackProgress = createUploadFileFnV2(
  (payload: UploadFilePayload, axiosConfig) => {
    return uploadFileV2(payload, axiosConfig).then((fileId) => ({
      value: fileId,
    }));
  },
);
