import { useTranslation } from 'react-i18next';

import { ns } from '@proptly/locale';
import { isEmptyOrWhitespace, MAX_UPLOAD_FILES_COUNT } from '@proptly/shared';
import {
  MessagesBody,
  MessagesEmpty,
  MessagesInput,
  useMapFilesToPreview,
} from '@proptly/ui';

import {
  useCreateFeedbackMessageMutation,
  useUploadFilesMutation,
} from '../../api/project-feedback';
import {
  useProjectFeedbackMessages,
  useProjectFeedbackName,
  useProjectFeedbackParsedMessages,
  useProjectFeedbackProjectDetails,
  useSearchParamsToken,
} from '../../hooks';
import { useMessagesStore } from './project-feedback-messages-store';

export const ProjectFeedbackMessages = () => {
  const [errt] = useTranslation(ns.Errors);
  const { mutateAsync } = useCreateFeedbackMessageMutation();
  const token = useSearchParamsToken();
  const feedbackMessages = useProjectFeedbackMessages();
  const project = useProjectFeedbackProjectDetails();
  const files = useMessagesStore((store) => store.localFiles);
  const onOpen = useMessagesStore((store) => store.onOpen);
  const removeFile = useMessagesStore((store) => store.removeFile);
  const clearFiles = useMessagesStore((store) => store.clearFiles);
  const text = useMessagesStore((store) => store.text);
  const setText = useMessagesStore((store) => store.setText);
  const { mutateAsync: uploadFiles } = useUploadFilesMutation();
  const feedbackName = useProjectFeedbackName();

  const contractorName = project.contractorName;

  const handleMessageAdded = async (text: string) => {
    if (files.length === 0 && isEmptyOrWhitespace(text)) {
      return;
    }

    if (files.length > MAX_UPLOAD_FILES_COUNT) {
      const error = new Error(
        errt('maxFilesCount', {
          maxFilesCount: MAX_UPLOAD_FILES_COUNT,
        }),
      );
      error.name = errt('tooMuchFiles');
      throw error;
    }

    const uploadedFiles =
      files.length > 0 ? await uploadFiles(files) : undefined;

    await mutateAsync({
      token,
      text,
      fileIds: uploadedFiles?.map((file) => file.value),
    });
    clearFiles();
  };

  const messages = useProjectFeedbackParsedMessages(
    feedbackMessages,
    contractorName,
    feedbackName,
  );

  const filesPreview = useMapFilesToPreview(files);

  return (
    <>
      <MessagesBody
        messages={messages}
        emptyMessagesFallback={<MessagesEmpty />}
        px={{ mobile: '4', desktop: '6' }}
      />

      <MessagesInput
        onMessageAdded={handleMessageAdded}
        files={filesPreview}
        onFileRemove={removeFile}
        onAddFileClick={onOpen}
        text={text}
        onTextChange={setText}
      />
    </>
  );
};
