import { Box, Flex, FlexProps, Text } from '@chakra-ui/react';

import { formatSize, getFileExt } from '../../../utils';
import { defaultFileIcon, fileIconsMap } from '../../files';
import { MessageFile } from '../chat.types';

interface MessageFileProps extends FlexProps {
  file: MessageFile;
  onClick: () => void;
}

export const MessageFileItem = ({
  file,
  onClick,
  ...props
}: MessageFileProps) => {
  const ext = getFileExt(file.fileName);
  const fileExtensionIcon = (ext && fileIconsMap.get(ext)) || defaultFileIcon;

  return (
    <Flex
      onClick={onClick}
      rounded="md"
      cursor="pointer"
      align="center"
      justify="center"
      bg="background"
      border="1px solid"
      borderColor="grey.shade"
      py="2"
      px="4"
      gap="2"
      {...props}
    >
      <Box color="grey.dark">{fileExtensionIcon}</Box>

      <Flex flexDir="column">
        <Text textStyle="label" noOfLines={2}>
          {file.fileName}
        </Text>
        <Text textStyle="labelXS">
          {file.fileSize && formatSize(file.fileSize)}
        </Text>
      </Flex>
    </Flex>
  );
};
