import { IconButton, IconButtonProps } from '@chakra-ui/react';
import { X } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';

export const CloseIconButton = (props: Omit<IconButtonProps, 'aria-label'>) => {
  const [t] = useTranslation();

  return (
    <IconButton
      size="xs"
      w="6"
      h="6"
      bg="grey.shade"
      icon={<X size="60%" weight="bold" />}
      aria-label={t('close')}
      {...props}
    />
  );
};
