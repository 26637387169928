import { useSuspenseQuery } from '@tanstack/react-query';

import { GetOrderSummaryByTokenResponse } from '@proptly/routing-contract-classes';
import { ApiResponse, routingClient } from '@proptly/ui';

export const getPublicOrder = (token: string) => {
  return routingClient
    .get<ApiResponse<GetOrderSummaryByTokenResponse>>(`/public/order/${token}`)
    .then((resp) => resp.data.data);
};

export const useGetPublicOrderQuery = (token: string) => {
  return useSuspenseQuery({
    queryKey: ['public', 'order', token],
    queryFn: () => getPublicOrder(token),
  });
};
