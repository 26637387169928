import { Box, BoxProps, ChakraProps } from '@chakra-ui/react';

export interface DotProps extends BoxProps {
  size?: ChakraProps['h'];
  color?: ChakraProps['bg'];
}

export const Dot = ({
  size = 1,
  color = 'text.default',
  ...props
}: DotProps) => {
  return (
    <Box
      h={size}
      aspectRatio="1/1"
      bg={color}
      borderRadius="full"
      flexShrink="0"
      {...props}
    />
  );
};
