import { Grid, GridProps, Image, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { chat } from '../../../images';
import { ImageCircleBox } from '../../molecules';

export const MessagesEmpty = (props: GridProps) => {
  const [t] = useTranslation();

  return (
    <Grid textAlign="center" justifyItems="center" {...props}>
      <ImageCircleBox w="96px" h="96px" mb="4" bg="green.shade">
        <Image w="66%" src={chat} />
      </ImageCircleBox>
      <Text textStyle="bodyLarge">{t('thereIsNothingToDisplay')}</Text>
      <Text textStyle="labelSmall" color="grey.dark">
        {t('startByAddingComments')}
      </Text>
    </Grid>
  );
};
