import { useMemo } from 'react';

import { Actor } from '@proptly/contractor-contract-enums';
import { MessageDto, MessageReason } from '@proptly/shared';
import { SystemMessage, UserMessage } from '@proptly/ui';

import { getFileUrl } from '../../api/project-feedback';
import {
  ProjectFeedbackEventText,
  ProjectFeedbackMessageAuthor,
} from '../../components';

export const useProjectFeedbackParsedMessages = (
  messages: MessageDto[],
  contractorName: string,
  feedbackName?: string,
) => {
  return useMemo(() => {
    return [...messages].reverse().map((feedbackMessage) => {
      const contactPersonName =
        feedbackMessage.createdByFirstName && feedbackMessage.createdByLastName
          ? `${feedbackMessage.createdByFirstName} ${feedbackMessage.createdByLastName}`
          : contractorName;

      if (feedbackMessage.reason !== MessageReason.None) {
        return {
          __type: 'SystemMessage',
          id: feedbackMessage.id,

          text: (
            <ProjectFeedbackEventText
              contractorName={contactPersonName}
              createdBy={feedbackMessage.createdBy}
              reason={feedbackMessage.reason}
              feedbackName={feedbackName ?? ''}
            />
          ),
          createdAt: feedbackMessage.createdAt,
        } as SystemMessage;
      }

      return {
        __type: 'UserMessage',
        id: feedbackMessage.id,
        author: (
          <ProjectFeedbackMessageAuthor
            createdBy={feedbackMessage.createdBy}
            contractorName={contactPersonName}
          />
        ),
        isMyMessage: feedbackMessage.createdBy === Actor.Client,
        text: feedbackMessage.text,
        createdAt: feedbackMessage.createdAt,
        files: feedbackMessage.files.map((file) => ({
          id: file.fileId,
          fileName: file.fileName,
          src: getFileUrl(file.fileToken),
        })),
      } as UserMessage;
    });
  }, [messages, contractorName, feedbackName]);
};
