import { useSuspenseQuery } from '@tanstack/react-query';
import { None } from 'typescript-functional-extensions';

import { TenantDto } from '@proptly/shared';
import { ApiResponse, routingClient } from '@proptly/ui';

export const getPublicTenant = (tenantId: string) => {
  return routingClient
    .get<ApiResponse<TenantDto>>(`/tenant/${tenantId}`)
    .then((resp) => resp.data.data);
};

export const useGetPublicTenantQuery = (tenantId: string) => {
  return useSuspenseQuery({
    queryKey: ['public', 'tenant', tenantId],
    queryFn: () => getPublicTenant(tenantId).catch(() => null),
    retry: false,
  });
};

export const useGetPublicTenantOptionalQuery = (tenantId: string | None) => {
  return useSuspenseQuery({
    queryKey: ['public', 'tenant', tenantId],
    queryFn: () => {
      if (!tenantId) {
        return null;
      }

      return getPublicTenant(tenantId).catch(() => null);
    },
    retry: false,
  });
};
