import { useSuspenseQueries, useSuspenseQuery } from '@tanstack/react-query';
import { None } from 'typescript-functional-extensions';

import { GetChatsPermissionsResponse } from '@proptly/chat-shared';
import { ApiResponse, chatClient } from '@proptly/ui';

export const getPublicChatPermissions = (token: string, chatId: string) => {
  return chatClient
    .get<ApiResponse<GetChatsPermissionsResponse[]>>(
      `/public/chat/${chatId}/permissions/${token}`,
    )
    .then((resp) => resp.data.data);
};

export const chatPublicChatPermissionsQuery = {
  getQueryKey: (token: string, chatId: string | None) => [
    'public',
    'chat',
    'permissions',
    chatId,
    token,
  ],
  queryFn: getPublicChatPermissions,
};

export const useGetPublicChatPermissionsOptionalQuery = (
  token: string,
  chatId: string | None,
) => {
  return useSuspenseQuery({
    queryKey: chatPublicChatPermissionsQuery.getQueryKey(token, chatId),
    queryFn: () => {
      if (!chatId) {
        return null;
      }

      return chatPublicChatPermissionsQuery.queryFn(token, chatId);
    },
  });
};

export const useGetPublicChatPermissionsQueries = (
  token: string,
  chatIds: string[],
) => {
  return useSuspenseQueries({
    queries: chatIds.map((chatId) => ({
      queryKey: chatPublicChatPermissionsQuery.getQueryKey(token, chatId),
      queryFn: () => {
        if (!chatId) {
          return null;
        }

        return chatPublicChatPermissionsQuery.queryFn(token, chatId);
      },
    })),
  });
};
