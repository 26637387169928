import { ReactElement, useState } from 'react';

import { chakra, ChakraProps, forwardRef } from '@chakra-ui/react';

export interface VideoProps extends ChakraProps {
  fallback?: ReactElement;
  src: string;
}

export const PreviewVideo = forwardRef<VideoProps, 'video'>(
  ({ fallback, src, onError, ...props }, ref) => {
    const [isLoading, setIsLoading] = useState(true);

    return (
      <>
        {isLoading && fallback}
        <chakra.video
          {...(isLoading && {
            position: 'fixed',
            visibility: 'hidden',
          })}
          mx="auto"
          preload="metadata"
          controls
          onLoadedData={() => {
            setIsLoading(false);
          }}
          onError={() => {
            setIsLoading(false);
          }}
          ref={ref}
          src={src}
          {...props}
        />
      </>
    );
  },
);
