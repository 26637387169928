import { ThemeTypings, useMediaQuery, useTheme } from '@chakra-ui/react';

export const useBreakpointValue = <T = unknown>(
  values: Partial<Record<ThemeTypings['breakpoints'], T>>,
) => {
  const usedBreakpoints = Object.keys(values);
  const theme = useTheme();

  const __breakpoints = theme.__breakpoints;

  const breakpoints =
    __breakpoints?.details
      .flatMap(({ minWQuery, breakpoint }) => {
        if (!usedBreakpoints.includes(breakpoint)) {
          return [];
        }

        return {
          breakpoint,
          query: minWQuery.replace('@media screen and ', ''),
        };
      })
      .reverse() || [];

  const results = useMediaQuery(
    breakpoints.map((bp) => bp.query),
    { ssr: false },
  );

  const index = results.findIndex((v) => v === true);
  const breakpoint = breakpoints[index]?.breakpoint;

  return values[breakpoint];
};
