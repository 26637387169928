import { ReactNode, Suspense } from 'react';

import { DrawerBody, Flex, forwardRef, ModalBodyProps } from '@chakra-ui/react';

import { Spinner } from '../atoms';
import { ErrorBoundary, ErrorBoundaryProps } from '../functional';

export interface SuspendedDrawerBodyProps extends ModalBodyProps {
  resetKeys?: ErrorBoundaryProps['resetKeys'];
  fallback?: ReactNode;
}

export const SuspendedDrawerBody = forwardRef<SuspendedDrawerBodyProps, 'div'>(
  ({ children, resetKeys, fallback, ...props }, ref) => {
    return (
      <DrawerBody {...props} ref={ref}>
        <ErrorBoundary resetKeys={resetKeys}>
          <Suspense
            fallback={
              fallback || (
                <Flex
                  w="full"
                  h={{ mobile: '50vh', desktop: '300px' }}
                  justify="center"
                  align="center"
                >
                  <Spinner />
                </Flex>
              )
            }
          >
            {children}
          </Suspense>
        </ErrorBoundary>
      </DrawerBody>
    );
  },
);
