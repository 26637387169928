import { ReactElement } from 'react';

import { Box, Flex, FlexProps, Text } from '@chakra-ui/react';

interface ChatEmptyProps extends FlexProps {
  avatar: ReactElement;
  title: string;
  subtitle?: string;
}

export const ChatEmpty = ({
  avatar,
  title,
  subtitle,
  ...props
}: ChatEmptyProps) => {
  return (
    <Flex
      mt={{ mobile: '6', desktop: '10' }}
      flexDir="column"
      align="center"
      justify="center"
      gap="6"
      bg="white"
      flex="1"
      {...props}
    >
      {avatar}
      <Box maxW="314px" textAlign="center">
        <Text textStyle="bodySmall" lineHeight="1.7" color="grey.dark">
          {title}
        </Text>
        <Text textStyle="bodySmall" lineHeight="1.7" color="grey.dark">
          {subtitle}
        </Text>
      </Box>
    </Flex>
  );
};
