import Dexie, { Table } from 'dexie';

export interface MessageText {
  id: string;
  chatId: string;
  text: string;
}

export interface MessageFiles {
  id: string;
  chatId: string;
  files: File[];
}

export class ChatDexie extends Dexie {
  messages: Table<MessageText>;
  files: Table<MessageFiles>;

  constructor() {
    super('ChatApp');
    this.version(1).stores({
      messages: 'id, chatId, text',
      files: 'id, chatId, files',
    });
  }
}

export const chatDb = new ChatDexie();
