import { Check } from '@phosphor-icons/react';

export const CheckboxIcon = ({
  isIndeterminate,
}: {
  isIndeterminate?: boolean;
}) => {
  return isIndeterminate ? (
    <svg
      width="1.2em"
      viewBox="0 0 24 24"
      style={{ stroke: 'currentColor', strokeWidth: 4 }}
    >
      <line x1="21" x2="3" y1="12" y2="12" />
    </svg>
  ) : (
    <Check />
  );
};
