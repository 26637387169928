import { AxiosInstance } from 'axios';

export interface RegisterDeviceForPushNotificationsPayload {
  deviceId: string;
  deviceType: 'APPLE' | 'ANDROID';
}

export const registerDeviceForPushNotifications = (
  apiClient: AxiosInstance,
  payload: RegisterDeviceForPushNotificationsPayload,
) =>
  apiClient
    .post('mobile-push-registration/register', payload)
    .then((resp) => resp.data);
