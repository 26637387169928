import { ComponentStyleConfig } from '@chakra-ui/react';

import { filledInputOverride, outlineInputOverride } from './common-input';

const Textarea: ComponentStyleConfig = {
  baseStyle: {
    overflowX: 'hidden',
    resize: 'unset',
  },
  variants: {
    outline: {
      ...outlineInputOverride,
    },
    filled: {
      ...filledInputOverride,
    },
    readOnly: {
      color: 'grey.default',
      borderColor: 'grey.default',
      border: '1px dashed',
    },
  },
  defaultProps: {
    variant: 'filled',
  },
};

export default Textarea;
