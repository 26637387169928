import { useTranslation } from 'react-i18next';

import { ProjectStatus } from '@proptly/shared';
import { checkMark, MessageListFinalStatus, xBadge } from '@proptly/ui';

import { useProject } from './use-project';

export const useProjectChatMessageEndOfList = () => {
  const [t] = useTranslation();

  const project = useProject();

  const projectCompletedTimestamp =
    project.status === ProjectStatus.Completed ? project.completedAt : null;
  const projectCancelledTimestamp =
    project.status === ProjectStatus.Cancelled ? project.cancelledAt : null;

  const timestamp = projectCompletedTimestamp || projectCancelledTimestamp;

  const title = projectCompletedTimestamp
    ? t('orderHasBeenCompleted')
    : t('orderHasBeenCancelled');
  const iconSrc = projectCompletedTimestamp ? checkMark : xBadge;

  return {
    shouldRender: !!timestamp,
    Component: (
      <MessageListFinalStatus
        iconSrc={iconSrc}
        timestamp={timestamp as Date}
        title={title}
      />
    ),
  };
};
