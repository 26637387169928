import { Fragment, PropsWithChildren } from 'react';

import { PopoverTrigger } from '@chakra-ui/react';

export type PopoverConditionalTriggerProps = PropsWithChildren<{
  isEnabled: boolean;
}>;

export const PopoverConditionalTrigger = ({
  children,
  isEnabled,
}: PopoverConditionalTriggerProps) => {
  const Wrapper = isEnabled ? PopoverTrigger : Fragment;

  return <Wrapper>{children}</Wrapper>;
};
