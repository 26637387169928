import { useSuspenseQueries } from '@tanstack/react-query';

import { FileMetadataResponse } from '@proptly/file-shared';
import { ApiResponse, fileClient } from '@proptly/ui';

import { getPublicFileUrl } from './get-public-file';

export const getFileMetadata = (fileId: string, token: string) => {
  return fileClient
    .get<ApiResponse<FileMetadataResponse>>(
      `/public/file/${fileId}/metadata/${token}`,
    )
    .then((resp) => resp.data.data);
};

export interface FileMetadataWithSrcResponse extends FileMetadataResponse {
  src: string;
}

export const useGetFilesMetadataQueries = (
  filesIds: string[],
  token: string,
) => {
  return useSuspenseQueries({
    queries: filesIds.map((fileId) => ({
      queryKey: ['file', fileId, 'metadata'],
      queryFn: () => getFileMetadataWithFileSrc(fileId, token),
    })),
  });
};

export const getFileMetadataWithFileSrc = (fileId: string, token: string) => {
  return Promise.all([
    getFileMetadata(fileId, token),
    getPublicFileUrl(fileId, token),
  ]).then(
    ([metadata, src]): FileMetadataWithSrcResponse => ({
      ...metadata,
      src,
    }),
  );
};
