import { useSuspenseQuery } from '@tanstack/react-query';

import { ProjectPublicDetailsDto } from '@proptly/contractor-contract-classes';
import { ApiResponse, contractorClient } from '@proptly/ui';

export const getProject = (token: string) => {
  return contractorClient
    .get<ApiResponse<ProjectPublicDetailsDto>>(`/project/${token}/public`)
    .then((resp) => resp.data.data);
};

export const useGetPublicProjectQuery = (token: string) => {
  return useSuspenseQuery({
    queryKey: ['public', 'project', token],
    queryFn: () => getProject(token),
  });
};
