import { Box, BoxProps, forwardRef } from '@chakra-ui/react';
import { motion, MotionProps } from 'framer-motion';

export const ImageCircleBox = forwardRef(
  ({ children, ...props }: BoxProps, ref) => {
    return (
      <Box
        ref={ref}
        bg="primary.shade"
        borderRadius="full"
        w="154px"
        h="154px"
        overflow="hidden"
        display="grid"
        placeItems="center"
        {...props}
      >
        {children}
      </Box>
    );
  },
);

const MotionImageCircleBox = motion(ImageCircleBox);

interface AnimateImageCircleBoxProps
  extends Omit<MotionProps, keyof BoxProps>,
    BoxProps {}

export const AnimateImageCircleBox = ({
  children,
  ...props
}: AnimateImageCircleBoxProps) => {
  return (
    <MotionImageCircleBox
      animate={{ scale: [0.5, 1], opacity: [0, 1] }}
      {...props}
    >
      {children}
    </MotionImageCircleBox>
  );
};
