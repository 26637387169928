import { AxiosError } from 'axios';

import { ApiError } from '../errors';

export const getErrorMessage = (errorLike: unknown) => {
  let message = 'Unknown Error';

  if (typeof errorLike === 'string') {
    message = errorLike;
  }
  if (errorLike instanceof Error) {
    message = errorLike.message;
  }
  if (errorLike instanceof AxiosError) {
    const data = errorLike.response?.data as ApiError;
    if (data) {
      message = data.message;
    }
  }

  return message;
};
