import { useSuspenseQuery } from '@tanstack/react-query';

import { FeedbackDto } from '@proptly/contractor-contract-classes';
import { ApiResponse, contractorClient } from '@proptly/ui';

export function getFeedback(token: string) {
  return contractorClient
    .get<ApiResponse<FeedbackDto>>(`/feedback/${token}`)
    .then((resp) => resp.data.data);
}

export function useGetFeedbackQuery(token: string) {
  return useSuspenseQuery({
    queryKey: ['feedback', token],
    queryFn: () => getFeedback(token),
  });
}
