import { ComponentStyleConfig } from '@chakra-ui/react';

import { getTypography } from '../utils/typography';

const FormError: ComponentStyleConfig = {
  baseStyle(props) {
    return {
      text: {
        ...getTypography('labelMedium', props.theme),
        color: 'red.default',
        my: 2,
        gap: 1,
      },
    };
  },
};

export default FormError;
