import { useMemo } from 'react';

import { DateLike } from '@proptly/shared';

import { ChatMessage, ChatSystemMessage, ChatUserMessage } from '../chat.types';

export const useMergePagedMessages = ({
  userMessages,
  systemMessages,
  hasMoreUserMessages,
}: {
  userMessages: ChatUserMessage[][];
  systemMessages: ChatSystemMessage[];
  hasMoreUserMessages: boolean;
}) => {
  return useMemo(
    () =>
      mergePagedMessages({
        userMessages,
        systemMessages,
        hasMoreUserMessages,
      }),
    [userMessages, hasMoreUserMessages, systemMessages],
  );
};

export const mergePagedMessages = ({
  userMessages,
  systemMessages,
  hasMoreUserMessages,
}: {
  userMessages: ChatUserMessage[][];
  systemMessages: ChatSystemMessage[];
  hasMoreUserMessages: boolean;
}) => {
  if (userMessages.length === 0) {
    return [Array.from(systemMessages).reverse()];
  }
  if (systemMessages.length === 0) {
    return userMessages;
  }
  const oldestUserMessage = userMessages.flat().at(0);

  const visibleSystemMessages =
    hasMoreUserMessages && oldestUserMessage
      ? systemMessages.filter(
          (sm) =>
            new Date(sm.createdAt) > new Date(oldestUserMessage.createdAt),
        )
      : [...systemMessages];

  if (visibleSystemMessages.length === 0) {
    return userMessages;
  }

  return Array.from(userMessages)
    .reverse()
    .map((page, index, array) => {
      if (visibleSystemMessages.length === 0) {
        return page;
      }

      const olderPage = array[index + 1];
      const newestMessageOnThisPage = page.at(0);

      if (!olderPage) {
        return mergeAndSort<ChatMessage>(page, visibleSystemMessages);
      }

      if (!newestMessageOnThisPage) {
        return page;
      }

      let count = 0;
      visibleSystemMessages.every((sm) => {
        const shouldBeOnNextPage =
          new Date(sm.createdAt) < new Date(newestMessageOnThisPage.createdAt);

        if (shouldBeOnNextPage) {
          return false;
        }
        count++;

        return true;
      });

      if (count === 0) {
        return page;
      }
      const systemMessagesSlice = visibleSystemMessages.splice(0, count);

      return mergeAndSort<ChatMessage>(page, systemMessagesSlice);
    })
    .reverse();
};

const mergeAndSort = <T extends { createdAt: DateLike }>(a: T[], b: T[]) => {
  return [a, b]
    .flat()
    .sort(
      (a, b) =>
        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
    );
};
