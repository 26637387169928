const space = {
  4.5: '1.125rem',
  7: '1.75rem',
  11: '2.75rem',
  13: '3.25rem',
  15: '3.75rem',
  mobileNavbar: '5rem',
  mobileNavbarWithSpace: '6rem',
  desktopNavbar: '72px',
};

export default space;
