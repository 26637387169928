import { useState } from 'react';

import { Box, BoxProps, chakra, Flex, Image } from '@chakra-ui/react';
import { FileVideo } from '@phosphor-icons/react';

import { videoPlay } from '../../images';

interface FileIconVideoProps extends BoxProps {
  src: string;
  size: number;
}

export const FileIconVideo = ({ src, size, ...props }: FileIconVideoProps) => {
  const [isError, setIsError] = useState(false);

  if (isError) {
    return (
      <Flex
        w={`${size}px`}
        h={`${size}px`}
        color="primary.default"
        align="center"
        justify="center"
        bg="white"
        rounded="md"
        {...props}
      >
        <FileVideo />
      </Flex>
    );
  }

  return (
    <Box position="relative" {...props}>
      <chakra.video
        w={`${size}px`}
        h={`${size}px`}
        minW={`${size}px`}
        objectFit="cover"
        borderRadius="6px"
        src={src}
        onError={() => {
          setIsError(true);
        }}
      />
      <Box
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        pointerEvents="none"
        color="white"
      >
        <Image src={videoPlay} />
      </Box>
    </Box>
  );
};
