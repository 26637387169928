import { FileTypes } from '@proptly/shared';

import { getFileExt } from './get-file-ext';

export const isImageFilename = (fileName: string) => {
  const ext = getFileExt(fileName);

  return !!ext && FileTypes.imageExtensions.includes(ext);
};

export const isImageFile = (file: { fileName: string }) => {
  return isImageFilename(file.fileName);
};
