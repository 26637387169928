import { v4 as uuid } from 'uuid';
const fileSrcMemo = new Map<File, string>();

export const getFileId = (file: File) => {
  const fileId = fileSrcMemo.get(file);

  if (fileId) {
    return fileId;
  }
  const newFileId = uuid();

  fileSrcMemo.set(file, newFileId);

  return newFileId;
};
