import { useParams } from 'react-router-dom';

import { NotFoundError } from '@proptly/ui';

export const useOrderToken = () => {
  const { token } = useParams<{ token: string }>();

  if (!token) {
    throw new NotFoundError();
  }

  return token;
};
