import { useEffect } from 'react';

import { Flex, Text } from '@chakra-ui/react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface ProjectRatingFormScoreAlertProps {
  isVisible: boolean;
  setHide: () => void;
}

export const ProjectRatingFormScoreAlert = ({
  isVisible,
  setHide,
}: ProjectRatingFormScoreAlertProps) => {
  const [t] = useTranslation();

  const selectedScore = useWatch<{
    overallScore: string;
  }>({
    name: 'overallScore',
  });

  useEffect(() => {
    if (selectedScore) {
      setHide();
    }
  }, [selectedScore, setHide]);

  return (
    isVisible && (
      <Flex
        columnGap="2"
        rowGap="5"
        wrap="wrap"
        w="full"
        maxW={{ desktop: '532px' }}
        mx="auto"
        rounded="lg"
        borderWidth="1px"
        borderStyle="dashed"
        borderColor="grey.dark"
        p="4"
        my={{ mobile: '1', desktop: '4' }}
      >
        <Text textStyle="labelXS" color="green.default">
          {t('projectRatingFormScoreWarning')}
        </Text>
      </Flex>
    )
  );
};
