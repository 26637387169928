import { Children } from 'react';

import { chakra, Text } from '@chakra-ui/react';

import { Accent } from '../components/atoms/accent';
import { StyledLink } from '../components/atoms/link';
import { useFormatTimestamp } from '../hooks';

const refresh = () => {
  window.location.reload();
};

const Timestamp = ({ children }: { children?: string }) => {
  const child = Children.toArray(children)[0] as string;

  const formatTimestamp = useFormatTimestamp();

  const timestamp = Date.parse(child);

  if (isNaN(timestamp)) {
    return <>Invalid Date</>;
  }

  return <>{formatTimestamp(timestamp).replace(/\s/g, '\u00a0')}</>;
};

export const i18nInterpolationVariables = {
  accent: <Accent />,
  linkToHomepage: <StyledLink to="/" />,
  danger: <chakra.span color="red.default" />,
  timestamp: <Timestamp />,
  refresh: (
    <Text
      as="span"
      color="primary.default"
      textDecor="underline"
      onClick={refresh}
      cursor="pointer"
    />
  ),
  strong: (
    <chakra.strong
      fontWeight="bold"
      color="primary.default"
      overflowWrap="anywhere"
    />
  ),
  bold: (
    <chakra.b textStyle="bodyMedium" fontWeight="bold" color="text.default" />
  ),
};
