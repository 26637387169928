import { AxiosInstance } from 'axios';

export interface UnregisterDeviceFromPushNotificationsPayload {
  deviceId: string;
}

export const unregisterDeviceFromPushNotifications = (
  apiClient: AxiosInstance,
  payload: UnregisterDeviceFromPushNotificationsPayload,
) =>
  apiClient
    .post('mobile-push-registration/unregister', payload)
    .then((resp) => resp.data);
