import { PropsWithChildren } from 'react';

import {
  Hide,
  HideProps,
  Show,
  ShowProps,
  ThemeTypings,
} from '@chakra-ui/react';

export const AboveDesktopView = (props: ShowProps) => (
  <Show above="desktop" ssr={false} {...props} />
);

export const BelowDesktopView = (props: HideProps) => (
  <Hide above="desktop" ssr={false} {...props} />
);

export interface ViewProps extends PropsWithChildren {
  breakpoint: ThemeTypings['breakpoints'];
}

export const ShowAbove = ({ breakpoint, children }: ViewProps) => (
  <Show above={breakpoint} ssr={false}>
    {children}
  </Show>
);

export const ShowBelow = ({ breakpoint, children }: ViewProps) => (
  <Hide above={breakpoint} ssr={false}>
    {children}
  </Hide>
);

export const HideAbove = ({ breakpoint, children }: ViewProps) => (
  <Hide above={breakpoint} ssr={false}>
    {children}
  </Hide>
);

export const HideBelow = ({ breakpoint, children }: ViewProps) => (
  <Show above={breakpoint} ssr={false}>
    {children}
  </Show>
);
